import {useState, useEffect} from "react"
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Checkbox from '@mui/material/Checkbox'
import {Api, Session, Constants} from 'scg.common-library'
import {Loading} from "../../general/form/Loading"
import {useTranslation} from 'react-i18next'
import './mcqanswer.css'

async function getAnswer(questionId, callback, callbackOnError) {
    if (questionId !== null) {
        const response = await Api.mcqAnswer.getMcqAnswersForQuestion(questionId,
            Session.getJwtAndRefreshToken())
        if (response?.status === Constants.HTTP_OK || response.status === Constants.HTTP_CREATED) {
            callback(response.data)
        } else {
            callbackOnError()
        }
    }
}

function Mcqanswer({
    questionId,
    initMcsanswer,
    callbackUpdateListe,
    refreshCountListe,
    callbackOtherRefresh
}) {
    const {t} = useTranslation()
    const [listAnswer, setListAnswer] = useState([])
    const [imageOnly, setImageOnly] = useState(false)
    const [countAnswer, setCountAnswer] = useState(0)
    const [loading, setLoading] = useState(false)
    const disableLoading = () => {
        setLoading(false)
    }
    const handleChangeImageOnly = (e) => {
        setImageOnly(e.target.checked)
        setListAnswer((prevList) => prevList.map(element => {
            return {
                ...element,
                text: "",
                imageOnly: e.target.checked,
                imageLink: null,
                uniqId: element.uniqId + "$"
            }
        }))
    }
    const handleAddAnswer = () => {
        setCountAnswer(countAnswer + 1)
        setListAnswer((prevList) => {
            prevList.push({
                id: null,
                text: "",
                imageLink: null,
                correct: false,
                imageOnly: imageOnly,
                question: null,
                new: true,
                uniqId: countAnswer,
                active: true,
                file: null
            })
            return prevList
        })
    }
    const handleGetAnswer = (data) => {
        const list = []
        let index = 0
        let tempImageOnly = false
        for (let mcqanswer of data) {
            if (mcqanswer.imageOnly === true) {
                tempImageOnly = true
            }
            list.push({
                id: mcqanswer.id,
                text: mcqanswer.text,
                imageLink: mcqanswer.imageLink,
                correct: mcqanswer.correct,
                imageOnly: mcqanswer.imageOnly,
                question: mcqanswer.question,
                new: false,
                uniqId: index + mcqanswer.id,
                active: true,
                file: null
            })
            index++
        }
        setImageOnly(tempImageOnly)
        setListAnswer(list)
        setCountAnswer(list.length)
        setLoading(false)
    }

    const callbackChangeValue = (mcqAnswer) => {
        setListAnswer(prevList => prevList.map(el => el.uniqId === mcqAnswer.uniqId ? mcqAnswer : el))
    }

    useEffect(() => {
        if (refreshCountListe > 0) {
            setLoading(true)
            if (refreshCountListe === listAnswer.length) {
                getAnswer(questionId, handleGetAnswer, disableLoading)
                callbackOtherRefresh()
            }
        }
    }, [refreshCountListe])

    useEffect(() => {
        if (questionId !== null) {
            setLoading(true)
            getAnswer(questionId, handleGetAnswer, disableLoading)
        }
    }, [initMcsanswer])

    useEffect(() => {callbackUpdateListe(listAnswer)}, [listAnswer])

    return <div className='container-mcqanswer'>
        {loading && <Loading />}
        <Grid container>
            <Grid item xs={8}>
                <span>{t('quiz.pages.questions.mcqanswer.instruction')}</span>
            </Grid>
            <Grid item xs={4}>
                <FormGroup className='container-switch'>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={imageOnly}
                                onChange={handleChangeImageOnly}
                            />
                        }
                        label={t('quiz.pages.questions.mcqanswer.switchImage')}
                    />
                </FormGroup>
            </Grid>
        </Grid>
        <Grid container>
            {listAnswer.map(el => <BlockResponse
                mcqAnswer={el} key={el.uniqId}
                callbackChangeValue={callbackChangeValue}
                imageOnly={imageOnly}
            />)}
            {countAnswer < 8 && <Grid item xs={6} className='text-align-center'>
                <Button variant='text' onClick={handleAddAnswer}>
                    <span className='underline'>{t('quiz.pages.questions.mcqanswer.add')}</span>
                </Button>
            </Grid>}
        </Grid>
    </div>
}

function BlockResponse({mcqAnswer, callbackChangeValue, imageOnly}) {
    const {t} = useTranslation()
    const [active, setActive] = useState(mcqAnswer.active)
    const [isTrue, setIsTrue] = useState(mcqAnswer.correct)
    const [value, setValue] = useState(mcqAnswer.text)
    const [file, setFile] = useState(null)
    const [imagePreview, setImagePreview] = useState("")
    const [changeImage, setChangeImage] = useState(false)
    useEffect(() => {
        callbackChangeValue({
            id: mcqAnswer.id,
            text: value,
            imageLink: mcqAnswer.imageLink,
            correct: isTrue,
            imageOnly: mcqAnswer.imageOnly,
            question: mcqAnswer.question,
            new: mcqAnswer.new,
            uniqId: mcqAnswer.uniqId,
            active: active,
            file: file
        })
    }, [active, isTrue, value, file])
    const handleActiveAnswer = () => setActive(active => !active)
    const handleIsTrueAnswer = () => setIsTrue(isTrue => !isTrue)
    const handleValueAnswer = e => {
        if (imageOnly) {
            if (e.target.files && e.target.files[0]) {
                setValue(e.target.files[0].name)
                setFile(e.target.files[0])
                setChangeImage(true)
                const reader = new FileReader()
                reader.onload = e => setImagePreview(e.target.result)
                reader.readAsDataURL(e.target.files[0])
            }
        } else {
            setValue(e.target.value)
        }
    }

    return <Grid item xs={6} className='container-block-response'>
        <Paper component='form' variant="outlined"
               sx={{display: 'flex', alignItems: 'center'}}>
            <Checkbox disabled={!active} checked={isTrue} onChange={handleIsTrueAnswer} />
            {imageOnly ? <InputBase
                    disabled={!active}
                    sx={{ml: 1, flex: 1}}
                    onChange={handleValueAnswer}
                    placeholder={t('quiz.pages.questions.mcqanswer.answer.placeholder')}
                    type='file'
                    inputProps={active ? {"accept": "image/*"} : {
                        "accept": "image/*",
                        style: {textDecoration: "line-through"}
                    }}
                />
                : <InputBase
                    disabled={!active}
                    inputProps={active ? {} : {style: {textDecoration: "line-through"}}}
                    sx={{ml: 1, flex: 1}}
                    value={value}
                    onChange={handleValueAnswer}
                    placeholder={t('quiz.pages.questions.mcqanswer.answer.placeholder')}
                    type='text'
                />
            }
            {imageOnly && <div style={{height: 50}}>
                {!changeImage ?
                    <img style={{height: "100%", width: "auto", objectFit: "contain"}}
                         src={mcqAnswer.imageLink} alt={mcqAnswer.text} title={mcqAnswer.text}
                         loading='lazy' />
                    :
                    <img style={{height: "100%", width: "auto", objectFit: "contain"}} src={imagePreview}
                         alt='new-image' loading='lazy' />
                }
            </div>}
            <Divider sx={{height: 28, m: 0.5}} orientation='vertical' />
            <IconButton color={active ? "default" : "error"} onClick={handleActiveAnswer}
                        title={active ? t('quiz.pages.questions.mcqanswer.answer.active') : t(
                            'quiz.pages.questions.mcqanswer.answer.delete')}>
                <DeleteIcon />
            </IconButton>
        </Paper>
    </Grid>
}

export default Mcqanswer