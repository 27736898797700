import React, {Component, useState, useCallback} from 'react'
import {
    ClearAll as ClearAllIcon,
    DeleteOutline as DeleteOutlineIcon,
    Person as PersonIcon,
    Logout as LogoutIcon,
    Notifications as NotificationsIcon
} from '@mui/icons-material';
import {
    Tooltip,
    Badge,
    IconButton,
    Paper,
    Divider,
    Avatar,
    Button,
    Select,
    MenuItem,
    CircularProgress
} from '@mui/material'
import {Api, Session} from 'scg.common-library'
import {Link} from 'react-router-dom'
import Helper from '../../../services/Helper'
import moment from 'moment'
import {routes as Routing} from '../../../services/RoutesHelper'
import i18next from 'i18next'
import {withTranslation, useTranslation} from 'react-i18next'
import {Loading} from '../../general/form/Loading'
import {COOKIE_NOTIFICATION, COOKIE_NOTIFICATION_TOOLTIP} from '../../../services/Constants'
import {HTTP_OK} from 'scg.common-library/lib/env/Constants'

import './userMenu.css';

// Constants
const NUMBER_NOTIF_TO_SHOW = 5;

export const NotificationItem = ({ notif, onDelete }) => {

    // states
    const [ isDeleting, setIsDeleting ] = useState(false);
    
    // hooks
    const { t } = useTranslation();
    
    // callback
    const makeNotificationAsRead = useCallback( () => {
        if( notif ){
            Api.userNotification.markAsReadUserNotification(notif.id)
                .then( () => {
                    Session.setCookie(COOKIE_NOTIFICATION, {id: notif.id}, 365)
                } )
        }
    }, [notif] );

    const makeNotificationAsDeleted = useCallback( () => {
        if( notif ){
            setIsDeleting(true);
            Api.userNotification.deleteUserNotification(notif.id)
                .then( () => {
                    setIsDeleting(false);
                    if( typeof(onDelete) === 'function' ){
                        onDelete(notif.id);
                    }
                } )
        }
    }, [notif] );

    return (
        <div className={`notification-item w-100 ${!notif.seen && 'unread'}`}>
            <Link to={notif.path}
                onClick={makeNotificationAsRead}
                title={t('dashboard.userMenu.notifications.item.linkTitle')}
            >
                <div className='notification-item-text'>
                    <span className='notification-item-content'>
                        {notif.content}
                    </span>
                    <span className='notification-item-date'>
                        {moment(notif.createdAt).format('DD-MM-YYYY HH:mm')}
                    </span>
                </div>
            </Link>
            <div className='notification-item-icon'
                title={t('dashboard.userMenu.notifications.item.deleteButtonTitle')}>
                { isDeleting ? <CircularProgress size={18} /> : <DeleteOutlineIcon onClick={makeNotificationAsDeleted} /> }
            </div>
        </div>
    )
}

const UserMenu = withTranslation()(
    class extends Component {
        static defaultProps = {}

        constructor(props) {
            super(props)

            const user = Session.getSessionUser()

            this.state = {
                isAuthenticated: Session.isLoggedIn(),
                userNotification: [],
                userNotificationCount: 0,
                showNotifications: false,
                notifications : [],
                user: user,
                displayTooltip: false,
                expandAll : false,
                isReadAll : false,
                language: Session.getBOLanguageFromTheLocalStorage() || user.language.code,
                languages: []
            }
            this._handleNotificationsClick =
                this._handleNotificationsClick.bind(this)
            this._handleExpandClick = this._handleExpandClick.bind(this)
            this._getNotificationsList = this._getNotificationsList.bind(this)
            this._handleNotificationDelete = this._handleNotificationDelete.bind(this)
            this._handleNotificationReadAll = this._handleNotificationReadAll.bind(this);
        }

        /*
         * TODO: Sauvegarder en session le dernier id vu
         *  - Ajouter un bouton "marquer tout comme lu"
         *  - Ajouter un bouton "tout supprimer"
         *  - Les notifications lues sont supprimées tous les soirs à minuit
         *  - Par défaut on affiche uniquement les 10 dernières notifications
         *  - Ajouter un bouton "voir tout" ?
         */

        componentDidMount() {
            Promise.all([
                Api.language.getLanguages(),
                Api.userNotification.getNotificationOfUser(this.state.user.id)
            ]).then(([langResponse, notifResponse]) => {
                const langObject = Helper.isValidResponse(langResponse)
                const notifObject = Helper.isValidResponse(notifResponse)

                let displayTooltip = false, userNotification = [], userNotificationCount = 0;

                if (notifResponse?.status !== HTTP_OK) {
                    Helper.displayMessage(this.props.t('dashboard.userMenu.notifications.error'), 'warning')
                }

                //  --- Notifications ---
                if (notifObject) {
                    let lastSeenNotification = Session.getCookie(COOKIE_NOTIFICATION)?.id
                    const lastNotificationGet = notifObject[0]?.id

                    if (lastSeenNotification === null || lastSeenNotification === undefined) {
                        lastSeenNotification = 0
                        Session.setCookie(COOKIE_NOTIFICATION, {id: 0}, 365)
                    }
                    if (lastSeenNotification < lastNotificationGet &&
                        Session.getCookie(COOKIE_NOTIFICATION_TOOLTIP)?.id !== lastNotificationGet) {
                        displayTooltip = true
                        Session.setCookie(COOKIE_NOTIFICATION_TOOLTIP, {id: lastNotificationGet}, 365)
                    }
                    userNotification = notifObject.slice(0, NUMBER_NOTIF_TO_SHOW);
                    userNotificationCount = notifObject.length;
                }

                if (!Session.getBOLanguageFromTheLocalStorage()) {
                    Session.setBOLanguageToTheLocalStorage(this.state.language)
                }

                this.setState({
                    userNotification: userNotification,
                    userNotificationCount: userNotificationCount,
                    displayTooltip: displayTooltip,
                    languages: langObject,
                    notifications : notifObject
                })
            })
        }

        _handleNotificationReadAll(){
            let readAllPromise = [];
            const { notifications } = this.state;
            if( notifications.length ){
                this.setState({ isReadAll : true });
                notifications.forEach( notif => {
                    const notifPromise = Api.userNotification.markAsReadUserNotification(notif.id).then( () => {
                        const newNotifications = notifications.filter( notification => notification.id !== notif.id );
                        this.setState({
                            notifications : newNotifications,
                            userNotificationCount : newNotifications.length
                        })        
                    } )
                    readAllPromise.push(notifPromise);      
                } )
                Promise.all(readAllPromise)
                    .then( () => {
                        this.setState({
                            isReadAll : false,
                            notifications: [],
                            userNotification : [],
                            userNotificationCount : 0,
                            showNotifications : false,
                        });
                    } )
            }
        }

        _handleNotificationDelete(deleteId){
            if( deleteId ){
                const { notifications } = this.state;
                const newNotifications = notifications.filter( notification => notification.id !== deleteId );
                const newUserNotification = newNotifications.slice(0, NUMBER_NOTIF_TO_SHOW);
                this.setState({
                    notifications : newNotifications,
                    userNotification : newUserNotification,
                    userNotificationCount : newNotifications.length
                })
            }
        }

        _handleNotificationsClick() {
            this.setState((prevState) => {
                return {showNotifications: !prevState.showNotifications}
            })
        }

        _handleExpandClick() {
            const { userNotification, notifications } =  this.state;
            this.setState({
                expandAll : !this.state.expandAll
            }, () => {
                if( this.state.expandAll ){
                    const newNotification = notifications.slice(NUMBER_NOTIF_TO_SHOW, notifications.length);
                    if( newNotification.length ){
                        this.setState({
                            userNotification : [...userNotification, ...newNotification]
                        });
                    }
                }else{
                    this.setState({
                        userNotification : notifications.slice(0, NUMBER_NOTIF_TO_SHOW)
                    });
                }
            })
        }

        _getNotificationsList() {
            const { userNotification } =  this.state;
            if(userNotification.length){
                return userNotification.map( notification => <NotificationItem
                        key={notification.id} 
                        notif={notification} 
                        onDelete={this._handleNotificationDelete}
                    />
                 )
            }else{
                return <p className='notificationEmpty'>{this.props.t('dashboard.userMenu.notifications.noContent')}</p>
            }
            
        }

        // eslint-disable-next-line
        componentDidUpdate(prevProps, prevState, snapshot) {
            Session.setBOLanguageToTheLocalStorage(this.state.language)
        }

        onChangeLanguage = (e) => {
            i18next.changeLanguage(e.target.value).then(() => this.setState({language: e.target.value}))
        }

        render() {
            const {t} = this.props
            const showReadAll = this.state.notifications.length > 0;
            const showExpand = this.state.notifications.length > NUMBER_NOTIF_TO_SHOW;
            return (
                <aside className='userMenu'>
                    <Link to={Routing.bo_profil} title='Profile'>
                        <Avatar alt={this.state.user.name} src='/avatar.png' variant='rounded'
                                title={t('dashboard.userMenu.avatarTitle')}>
                            <PersonIcon />
                        </Avatar>
                    </Link>
                    <div className='userMenu-account'>
                        <span>{t('dashboard.userMenu.greeting')}, </span>
                        <span>{this.state.user.pseudo}</span>
                    </div>

                    <div className='userMenu-lang'>
                        {this.state.languages.length === 0 && <Loading />}
                        {this.state.languages.length > 0 &&
                            <Select id='demo-select-small' title={t('dashboard.userMenu.languagesSelect')}
                                    value={this.state.language} onChange={this.onChangeLanguage}>
                                {this.state.languages.map(({id, code, name}) => (
                                    <MenuItem value={code} key={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    </div>

                    <div className='userMenu-notification'
                         title={t('dashboard.userMenu.notifications.buttonTitle')}>
                        <Badge
                            className={`notification-badge ${this.state.userNotificationCount > 0 &&
                            'hasNotif'}`}
                            color='warning'
                            badgeContent={this.state.userNotificationCount}
                            max={10}
                            onClick={this._handleNotificationsClick}>
                            <Tooltip title={t('dashboard.userMenu.notifications.tooltipTitle') + '!'} arrow
                                     open={this.state.displayTooltip}>
                                <NotificationsIcon />
                            </Tooltip>
                        </Badge>
                        {this.state.showNotifications && (
                            <Paper elevation={1} className='notification-list'>
                                <div className='notification-list-header flex-sb'>
                                    <span>
                                        {t('dashboard.userMenu.notifications.popover.title')}
                                    </span>
                                    {
                                        showReadAll && 
                                        <IconButton
                                            onClick={this._handleNotificationReadAll}
                                            aria-label={t('dashboard.userMenu.notifications.popover.buttonTitle')}
                                            title={t('dashboard.userMenu.notifications.popover.buttonTitle')}
                                        >
                                            { this.state.isReadAll ? <CircularProgress size={18} /> : <ClearAllIcon /> } 
                                        </IconButton>
                                    }
                                </div>
                                <Divider />
                                <div className='notification-list-content'>
                                    {this._getNotificationsList()}
                                </div>
                                {
                                    showExpand &&
                                    <React.Fragment>
                                        <Divider />
                                        <div className='notification-list-footer flex w-100'>
                                            <div
                                                onClick={this._handleExpandClick}
                                                aria-label={t(
                                                    'dashboard.userMenu.notifications.popover.seeAll.title')}
                                                title={ this.state.expandAll ? t('dashboard.userMenu.notifications.popover.seeLess.title') : t('dashboard.userMenu.notifications.popover.seeAll.title')}
                                            >
                                                { this.state.expandAll ? t('dashboard.userMenu.notifications.popover.seeLess.name')  : t('dashboard.userMenu.notifications.popover.seeAll.name') }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </Paper>
                        )}
                    </div>
                    <Button
                        href={Routing.app_logout}
                        title={t('session.disconnect')}
                        className='userMenu-logout'
                        color='error'
                        variant='contained'
                        size='large'
                        disableElevation>
                        <LogoutIcon />
                    </Button>
                </aside>
            )
        }
    }
)

export default UserMenu
