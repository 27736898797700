import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import './loading.css';

export function LoadingOverlay() {
  const { t } = useTranslation();

  return (
    <div className="loading-overlay">
      <div className="overlay-content">
        <FontAwesomeIcon icon="fas fa-gear loading-icon" spin />
        <div>{t('general.form.loading.text')}</div>
      </div>

      <div className="overlay-brand">
        <img src="/img/Nextaura2.png" alt="Nextaura brand logo" />
      </div>
    </div>
  );
}

export function Loading({ icon = 'fa-gear', text = '' }) {
  return (
    <div className="loading">
      <div className="loading-content">
        <FontAwesomeIcon icon={`fas ${icon} loading-icon`} spin />
        {text}
      </div>
    </div>
  );
}
