import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import './input.css';

class InputField extends Component {
  static defaultProps = {
    onChange: null,
    onFocus: null,
    onBlur: null,
    onKeyDown: null,
    required: false,
    disabled: false,
    visible: true,
    readOnly: '',
    placeholder: '',
    step: 1,
    center: false,
    isFocused: false,
    pattern: null,
    value: '',
    forwardedRef: null,
    type: 'text',
    title: '',
    name: '',
    maxLength: '255',
    inputStyle: 'classic', //or "futurist"
    className: '',
    autocomplete: '',
    size: 'normal' //or "tiny"
  };

  handleKeyDown() {
    //TODO: change code here if needed
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  getFocus(e) {
    e.target.classList.add('formField--focused');
  }

  looseFocus(e) {
    const element = e.target;
    if (element.value === '') {
      element.classList.remove('formField--focused');
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  render() {
    const currentContext = this.props.context ?? this;
    let attributes = {
      id: this.props.name,
      name: this.props.name,
      title: this.props.title,
      className: `${this.props.isFocused ? 'formField--focused' : ''} ${
        this.props.disabled ? 'disabled' : ''
      } ${this.props.center ? 'center' : ''}`,

      type: this.props.type,
      ref: this.props.forwardedRef,
      value: this.props.value,
      onChange: this.props.onChange ?? this.handleChange.bind(currentContext),
      onFocus: this.getFocus.bind(currentContext),
      onBlur: this.looseFocus.bind(currentContext),
      onKeyDown:
        this.props.onKeyDown ?? this.handleKeyDown.bind(currentContext),
      required: this.props.required,
      disabled: this.props.disabled,
      readOnly: this.props.readOnly,
      placeholder: this.props.placeholder,
      step: this.props.step,
      autoComplete: this.props.autocomplete,
      maxLength: this.props.maxLength
    };
    if (this.props.pattern !== null) {
      attributes.pattern = this.props.pattern;
    }

    return (
      <>
        {this.props.visible && (
          <div
            className={`formField formField-input formField-style-${
              this.props.inputStyle
            } ${this.props.className} ${
              this.props.size === 'tiny' ? 'input-tiny' : ''
            }`}
          >
            <input {...attributes}></input>
            <label htmlFor={this.props.name}>
              {this.props.children} {this.props.required && <Requiredstar />}
            </label>
            <span className="formField-underline"></span>
          </div>
        )}
      </>
    );
  }
}

class TextareaField extends Component {
  static defaultProps = {
    onChange: null,
    onFocus: null,
    onBlur: null,
    onKeyDown: null,
    required: false,
    disabled: '',
    readOnly: '',
    placeholder: '',
    center: false,
    value: '',
    ref: null,
    title: '',
    name: '',
    inputStyle: 'classic', //or "futurist"
    className: '',
    rows: 1,
    cols: 1
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleKeyDown() {
    //TODO: change code here if needed
  }

  getFocus(e) {
    e.target.classList.add('formField--focused');
  }

  looseFocus(e) {
    const element = e.target;
    if (element.value === '') {
      element.classList.remove('formField--focused');
    }
  }

  render() {
    const currentContext = this.props.context ?? this;
    let attributes = {
      id: this.props.name,
      name: this.props.name,
      title: this.props.title,
      className: `${this.props.isFocused ? 'formField--focused' : ''} ${
        this.props.disabled ? 'disabled' : ''
      } ${this.props.center ? 'center' : ''}`,
      type: this.props.type,
      ref: this.props.forwardedRef,
      value: this.props.value,
      onChange: this.props.onChange ?? this.handleChange.bind(currentContext),
      onFocus: this.getFocus.bind(currentContext),
      onBlur: this.looseFocus.bind(currentContext),
      onKeyDown:
        this.props.onKeyDown ?? this.handleKeyDown.bind(currentContext),
      required: this.props.required,
      disabled: this.props.disabled,
      readOnly: this.props.readOnly,
      placeholder: this.props.placeHolder,
      rows: this.props.rows,
      cols: this.props.cols
    };
    return (
      <div
        className={`formField formField-textarea formField-style-${this.props.inputStyle} ${this.props.className}`}
      >
        <textarea {...attributes}></textarea>
        <label htmlFor={this.props.name}>
          {this.props.children} {this.props.required && <Requiredstar />}
        </label>
        <span className="formField-underline"></span>
      </div>
    );
  }
}

const Requiredstar = ({ style }) => {
  return (
    <div className="required-star" style={style}>
      <FontAwesomeIcon icon="fas fa-star-of-life"></FontAwesomeIcon>
    </div>
  );
};

const RequiredText = () => {
  const { t } = useTranslation();

  return (
    <div className="required-text">
      <FontAwesomeIcon icon="fas fa-star-of-life"></FontAwesomeIcon>{' '}
      {t('general.form.input.requiredText')}
    </div>
  );
};

export { InputField, TextareaField, Requiredstar, RequiredText };
