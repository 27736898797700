import {Component, useState} from 'react'
import {Api} from 'scg.common-library'
import {TableSkeleton} from '../../../services/LoadingHelper'
import DataTable from 'react-data-table-component'
import ReactSwitch from 'react-switch'
import Helper from '../../../services/Helper'
import {withTranslation} from 'react-i18next'

import './categoryList.css'

const CategoryList = withTranslation()(
    class extends Component {
        static defaultProps = {}

        constructor(props) {
            super(props)

            this.state = {
                loading: true,
                categories: [],
                totalRows: 0,
                perPage: Helper.getTablePerPageNumber()
            }

            this.handlePerRowsChange = this.handlePerRowsChange.bind(this)
            this.handlePageChange = this.handlePageChange.bind(this)
        }

        componentDidMount() {
            (async function() {
                Api.category.getCategoryCount().then((response) => {
                    this.setState({totalRows: response.count})
                })
                getCategoryLines.bind(this)(1)
            }).bind(this)()
        }

        handlePerRowsChange(newPerPage, page) {
            (async function() {
                this.setState({perPage: newPerPage, loading: true}, () => {
                    Helper.setTablePerPageNumber(newPerPage)
                    getCategoryLines.bind(this)(page)
                })
            }).bind(this)()
        }

        handlePageChange(page) {
            (async function() {
                this.setState({loading: true}, () => {
                    getCategoryLines.bind(this)(page)
                })
            }).bind(this)()
        }

        render() {
            const {t} = this.props

            const columns = [
                {
                    name: t('tableColumnNames.id'),
                    selector: (row) => row.id,
                    sortable: true,
                    width: '5vw'
                },
                {
                    name: t('tableColumnNames.name'),
                    selector: (row) => row.name,
                    sortable: true,
                    width: '75vw'
                },
                {
                    name: t('tableColumnNames.active'),
                    selector: (row) => row.active,
                    width: '5vw'
                }
            ]

            return (
                <article className='categories'>
                    <h3>{t('quiz.pages.categories.title')}</h3>
                    <section className='categories-table card'>
                        {this.state.loading
                            ? <TableSkeleton linesCount={22} size='large' />
                            : <div className='categories-table-content'>
                                <DataTable
                                    dense={true}
                                    columns={columns}
                                    data={this.state.categories}
                                    fixedHeader
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={this.state.totalRows}
                                    paginationPerPage={this.state.perPage}
                                    onChangeRowsPerPage={this.handlePerRowsChange}
                                    onChangePage={this.handlePageChange}
                                    paginationComponentOptions={{
                                        rowsPerPageText: t('general.utils.rows_per_page')
                                    }}
                                    responsive
                                    className='categoriesTable'
                                    subHeaderAlign='center'
                                    subHeaderWrap
                                />
                            </div>}
                    </section>
                </article>
            )
        }
    }
)

/* ================================== GLOBAL FUNCTIONS ================================== */

/**
 * Call categories Api and update table state with the result of the api call
 *
 * @param page {int}
 * @param filters {object|null}
 * @param callback {Function|null}
 * @return {Promise<void>}
 */
async function getCategoryLines(page = 1, filters = null, callback = null) {
    Helper.getDataTableLines.bind(this)(
        Api.category.getCategories,
        {'order[id]': 'asc', per_page: this.state.perPage},
        'categories',
        FormatRow,
        page,
        filters,
        callback
    )
}

/**
 * Create a jsx object that will be interpreted by the DataTable
 *
 * @param category
 * @return {Promise<{name: JSX.Element, active: JSX.Element, id, shortName: JSX.Element}>}
 */
async function FormatRow(category) {
    return {
        id: category.id,
        name: formatText(category.name),
        active: <ActiveButton category={category} />
    }
}

/**
 * Return a switch component that update the category active state (category object passed in props)
 *
 * @param props {object}
 * @return {JSX.Element}
 */
function ActiveButton(props) {
    const [active, setActive] = useState(props.category.active)
    const handleActiveSwitchChange = (activeState) => {
        Api.category.updateCategory(props.category.id, {
            active: activeState,
            name: props.category.name
        }).then(() => {
            setActive(activeState)
        })
    }

    return (
        <ReactSwitch
            onChange={handleActiveSwitchChange}
            checked={active}
            className='categories-active-switch'
            id={`category-${props.category.id}`}
            onColor='#99e7fc'
            offColor='#fc9999'
        />
    )
}

/**
 * Format a text to an html div used for dataTable display
 *
 * @param text {string}
 * @return {JSX.Element}
 */
function formatText(text) {
    return (
        <div className='text-display' title={text}>
            <span>{text}</span>
        </div>
    )
}

export default CategoryList
