import React from "react"
import {Api, Session, Constants} from "scg.common-library"
import {Loading} from "../../general/form/Loading"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Helper from "../../../services/Helper"
import Grid from '@mui/material/Grid'
import moment from "moment"
import {InputField} from "../../general/form/Input"
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import {styled} from "@mui/material/styles"
import {Chip, Divider, List, ListItemButton, ListItemText, TablePagination} from "@mui/material"
import SelectWithData from "../../general/form/SelectWithData"
import {withTranslation, useTranslation} from 'react-i18next'
import _ from 'lodash'

import "./informationSheetList.css"
import Modal from "../../general/form/Modal"

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E0E3E7'
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#61dafb'
        }
    }
})

const CssOutlinedInput = styled(OutlinedInput)({
    '& label.Mui-focused': {
        color: '#A0AAB4'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E0E3E7'
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#61dafb'
        },
        '&.Mui-focused': {
            borderColor: '#61dafb'
        }
    }
})

const InformationSheetList = withTranslation()(
    class extends React.Component {
        static defaultProps = {}

        constructor(props) {
            super(props)

            this.state = {
                loading: true,
                informationSheets: [],
                totalRows: 0,
                perPage: Helper.getTablePerPageNumber(),
                idSelected: null,
                selectedRow: false,
                paginationPage: 0,
                informationSheetUse: null,
                informationSheetChange: null,
                activeSave: false,
                informationSheetStockId: null,
                showModal: false
            }

            this.handlePerRowsChange = this.handlePerRowsChange.bind(this)
            this.handlePageChange = this.handlePageChange.bind(this)
            this.handleFilter = this.handleFilter.bind(this)
            this.handleSelectedRow = this.handleSelectedRow.bind(this)
            this.handleNewInformationSheet = this.handleNewInformationSheet.bind(this)
            this.callbackAfterCreate = this.callbackAfterCreate.bind(this)
            this.callbackAfterValidateChange = this.callbackAfterValidateChange.bind(this)
            this.callbackAfterGet = this.callbackAfterGet.bind(this)
            this.callbackAfterChange = this.callbackAfterChange.bind(this)
            this.handleModalHidding = this.handleModalHidding.bind(this)
            this.handleRefreshList = this.handleRefreshList.bind(this)
        }

        componentDidMount() {
            Api.informationSheet.getInformationSheetCount().then(response => {
                const resultObject = Helper.isValidResponse(response)
                if (resultObject) {
                    this.setState({totalRows: resultObject.count})
                }
            })

            getInformationSheetLines.bind(this)(1)
        }

        handleRefreshList() {
            const actuelSave = this.state.informationSheetChange
            this.setState({
                informationSheetUse: actuelSave
            })
            getInformationSheetLines.bind(this)(1, this.getFilters())
        }

        callbackAfterCreate(id) {
            this.setState(prevState => {
                const theId = (prevState.activeSave) ? prevState.informationSheetStockId : id
                return {
                    idSelected: theId,
                    selectedRow: true,
                    loading: true,
                    paginationPage: 0,
                    activeSave: false
                }
            })
            getInformationSheetLines.bind(this)(1)
        }

        callbackAfterValidateChange() {
            this.setState(prevState => {
                return {idSelected: prevState.informationSheetStockId, 
                    selectedRow: true, 
                    activeSave: false}
            })
        }

        callbackAfterGet(informationSheet) {
            this.setState({
                informationSheetUse: informationSheet,
                informationSheetChange: informationSheet
            })
        }

        callbackAfterChange(cleValue) {
            const copy = {...this.state.informationSheetChange}
            copy[cleValue.index] = cleValue.value
            this.setState({
                informationSheetChange: copy
            })
        }

        handleNewInformationSheet() {
            this.setState({
                idSelected: null,
                selectedRow: true
            })
        }

        handleModalHidding(type) {
            switch (type) {
                case 1:
                    this.setState({showModal: false})
                    break
                case 2:
                    this.setState(prevState => {
                        return {idSelected: prevState.informationSheetStockId, selectedRow: true, showModal: false}
                    })
                    break
                case 3:
                    this.setState({activeSave: true, showModal: false})
                    break
                default:
                    break
            }
        }

        handleSelectedRow(id) {
            if (this.state.informationSheetChange !== null && this.state.informationSheetUse !== null) {
                if (objCompare(this.state.informationSheetChange, this.state.informationSheetUse)) {
                    this.setState({
                        idSelected: id,
                        selectedRow: true
                    })
                } else {
                    this.setState({
                        informationSheetStockId: id,
                        showModal: true
                    })
                }
            } else {
                this.setState({
                    idSelected: id,
                    selectedRow: true
                })
            }
        }

        getFilters() {
            console.debug("getFilters not initialized !")
            return []
        }

        handlePerRowsChange(event) {
            this.setState({perPage: event.target.value, loading: true, paginationPage: 0}, () => {
                Helper.setTablePerPageNumber(event.target.value)
                getInformationSheetLines.bind(this)(1, this.getFilters())
            })
        }

        handlePageChange(event, page) {
            this.setState({loading: true, paginationPage: page}, () => {
                getInformationSheetLines.bind(this)((page + 1), this.getFilters())
            })
        }

        handleFilter(filterFunctionFromChild, updateButtonStateFunction) {
            this.setState({loading: true, paginationPage: 0})
            updateButtonStateFunction(true)

            this.getFilters = filterFunctionFromChild
            const filters = this.getFilters()

            Api.informationSheet.getInformationSheetCount(Helper.getFiltersUrlParams(filters)).
                then(response => {
                    const resultObject = Helper.isValidResponse(response)
                    if (resultObject) {
                        this.setState({totalRows: resultObject.count})
                    }
                })

            getInformationSheetLines.bind(this)(1, filters, updateButtonStateFunction)
        }

        render() {
            const {t} = this.props
            return <article className='information-sheets'>
                <section className='information-sheets-title'>
                    {/*show modal dialog when unsave job*/}
                    <Modal
                        isShowing={this.state.showModal}
                        title={t('quiz.pages.informationSheet.modal.title')}
                        children={
                            <ModalContentUnsafeRelease
                                onClose={() => this.handleModalHidding(1)}
                                onNo={() => this.handleModalHidding(2)}
                                onYes={() => this.handleModalHidding(3)}                                
                            />
                        }
                        hide={() => this.handleModalHidding(1)}
                    />
                    <h3>{t('quiz.pages.informationSheet.title')}</h3>
                    <button className={`btn default addButton ${this.state.loading ? "disabled" : ""}`}
                            title={t('quiz.pages.informationSheet.addPaceholder')}
                            onClick={this.handleNewInformationSheet}>
                        <FontAwesomeIcon icon='fas fa-add' /> {t('quiz.pages.informationSheet.add')}
                    </button>
                </section>
                <section className='bo-data-table'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Paper className='paper-container'>
                                <Grid item xs={12} md={12} style={{marginBottom: 20}}>
                                    <InformationSheetFilters onFilter={this.handleFilter} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='bo-data-table-content' style={{height: '71vh'}}>
                                        {this.state.loading && <Loading />}
                                        <div style={{overflowY: 'scroll', height: '70vh'}}>
                                            <InitList
                                                informationSheetsFormRow={this.state.informationSheets}
                                                idSelected={this.state.idSelected}
                                                onClick={this.handleSelectedRow}
                                            />
                                        </div>
                                        <div>
                                            <TablePagination
                                                component='div'
                                                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                                count={this.state.totalRows}
                                                page={this.state.paginationPage}
                                                rowsPerPage={this.state.perPage}
                                                labelRowsPerPage={t('general.utils.rows_per_page')}
                                                showFirstButton
                                                showLastButton
                                                onPageChange={this.handlePageChange}
                                                onRowsPerPageChange={this.handlePerRowsChange}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Paper className='paper-form-container'>
                                {this.state.selectedRow &&
                                    <InformationSheetForm 
                                        callbackAfterCreate={this.callbackAfterCreate}
                                        selectedRow={this.state.selectedRow}
                                        idSelected={this.state.idSelected} 
                                        callbackAfterGet={this.callbackAfterGet}
                                        callbackAfterChange={this.callbackAfterChange}
                                        activeSave={this.state.activeSave}
                                        callbackAfterValidateChange={this.callbackAfterValidateChange}
                                        handleRefreshList={this.handleRefreshList}
                                        />}
                            </Paper>
                        </Grid>
                    </Grid>
                </section>
            </article>
        }
    }
)

function ModalContentUnsafeRelease ({onClose, onNo, onYes}) {
    const {t} = useTranslation()

    return <div className='layout'>
                <div style={{textAlign: "center", padding: 10}}>
                    {t('quiz.pages.informationSheet.modal.message')}
                </div>
                <div className='layout-buttons'>
                    <input
                        type='button'
                        className='btn success'
                        onClick={() => onYes()}
                        value={t('quiz.pages.informationSheet.modal.btnYes')}
                    />
                    <input
                        type='button'
                        className='btn alert'
                        onClick={() => onNo()}
                        value={t('quiz.pages.informationSheet.modal.btnNo')}
                    />
                    <input
                        type='button'
                        className='btn error'
                        value={t('quiz.pages.informationSheet.modal.btnCancel')}
                        onClick={() => onClose()}
                    />
                </div>
            </div>
}

function objCompare(obj1, obj2) {
    const Obj1_keys = Object.keys(obj1);
    const Obj2_keys = Object.keys(obj2);
    if (Obj1_keys.length !== Obj2_keys.length) {
      return false;
    }
    for (let k of Obj1_keys) {
      if (obj1[k] !== obj2[k]) {
        return false;
      }
    }
    return true;
}

function InitList({informationSheetsFormRow, onClick, idSelected}) {
    return <List>
        {
            informationSheetsFormRow.map(informationSheetData => {
                return <ListItemButton
                    selected={informationSheetData.id === idSelected}
                    onClick={() => onClick(informationSheetData.id)}
                    id={informationSheetData.id}
                    key={informationSheetData.id}
                >
                    <ListItemText
                        primary={<PrimaryListItemText
                            informationSheet={informationSheetData.informationSheet} />}
                        secondary={<SecondaryListItemText
                            informationSheet={informationSheetData.informationSheet} />}
                        secondaryTypographyProps={{component: "div"}}
                    />
                </ListItemButton>
            })
        }
    </List>
}

function PrimaryListItemText({informationSheet}) {
    const idForTitle = "title-is-" + informationSheet.id
    return <>
        <div style={{display: 'flex'}}>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: "95%"
            }}>
                <label><b id={idForTitle}>{informationSheet.title}</b></label>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <div className="container-flag" style={{paddingRight: 5}}>{Helper.FormatFlag(informationSheet.language)}</div><Chip size='small' label={informationSheet.numberPlayerUnlocked} />
            </div>
        </div>
    </>
}

function SecondaryListItemText({informationSheet}) {
    const {t} = useTranslation()
    const updateDate = (typeof informationSheet.updateDate !== 'undefined') ? moment(
        informationSheet.updateDate).format("DD/MM/YYYY") : "-"
    const creationDate = (typeof informationSheet.creationDate !== 'undefined') ? moment(
        informationSheet.creationDate).format("DD/MM/YYYY") : "-"
    const idForUpdateDate = "update-date-is-" + informationSheet.id
    const idForUpdateBy = "update-by-is-" + informationSheet.id
    return <div>
        <div style={{width: '50%', display: 'inline-block'}}>
            <div>{t('quiz.pages.informationSheet.listItem.createAt')} <span>{creationDate}</span></div>
            <div>{t('quiz.pages.informationSheet.listItem.by')} <span>{informationSheet.createBy !== null
                ? informationSheet.createBy.name
                : "-"}</span></div>
        </div>
        <div style={{width: '50%', display: 'inline-block', textAlign: 'right'}}>
            <div>{t('quiz.pages.informationSheet.listItem.updateAt')} <span
                id={idForUpdateDate}>{updateDate}</span></div>
            <div>{t('quiz.pages.informationSheet.listItem.by')} <span
                id={idForUpdateBy}>{informationSheet.updateBy !== null
                ? informationSheet.updateBy.name
                : "-"}</span></div>
        </div>
    </div>

}

const InformationSheetForm = withTranslation()(
    class extends React.Component {
        constructor(props) {
            super(props)

            this.sessionUser = Session.getSessionUser()

            this.state = {
                loading: props.selectedRow,
                informationSheet: getEmptyQuestionObject(),
                informationSheetId: props.idSelected,
                informationSheetTitle: "",
                informationSheetContent: "",
                informationSheetAttachment: "",
                informationSheetNumberPlayerUnlocked: null,
                informationSheetCreateBy: null,
                informationSheetUpdateBy: null,
                informationSheetCreationDate: null,
                informationSheetUpdateDate: null,
                informationSheetLanguage: null,
                user: this.sessionUser
            }
            this.handleTitleChange = this.handleTitleChange.bind(this)
            this.handleContentChange = this.handleContentChange.bind(this)
            this.handleAttachmentChange = this.handleAttachmentChange.bind(this)
            this.handleLanguageSelectChange = this.handleLanguageSelectChange.bind(this)
            this.handleSave = this.handleSave.bind(this)
            this.initStateObjects = this.initStateObjects.bind(this)
            this.initNewObject = this.initNewObject.bind(this)
            this.callbackAfterCreate = props.callbackAfterCreate
            this.callbackAfterGet = props.callbackAfterGet
            this.callbackAfterChange = props.callbackAfterChange
            this.callbackAfterValidateChange = props.callbackAfterValidateChange

            this.languageOptions = [{label: this.props.t('quiz.pages.informationSheet.form.language'), icon: "fa-language", options: []}]
        }

        componentDidMount() {
            (async function() {
                if (this.props.idSelected !== null) {
                    this.setState({loading: true})
                    const response = await Api.informationSheet.getInformationSheet(this.props.idSelected)
                    const resultObject = Helper.isValidResponse(response)
                    if (resultObject) {
                        this.initStateObjects(resultObject)
                        this.callbackAfterGet(cleanupQuestionObject(resultObject))
                    }
                } else {
                    this.setState({informationSheet: getEmptyQuestionObject(), loading: false})
                    this.callbackAfterGet(getEmptyQuestionObject())
                }
            }).bind(this)()
        }

        componentDidUpdate(prevProps) {
            if (this.props.idSelected !== prevProps.idSelected) {
                (async function() {
                    if (this.props.idSelected !== null) {
                        this.setState({loading: true})
                        const response = await Api.informationSheet.getInformationSheet(this.props.idSelected)
                        const resultObject = Helper.isValidResponse(response)
                        if (resultObject) {
                            this.initStateObjects(resultObject)
                            this.callbackAfterGet(cleanupQuestionObject(resultObject))
                        }
                    } else {
                        this.initNewObject()
                        this.callbackAfterGet(getEmptyQuestionObject())
                    }
                }).bind(this)()
            }

            if (this.props.activeSave) {
                this.handleSave()
                if (this.props.activeSave) {
                    this.callbackAfterValidateChange()
                }
            }
        }

        initStateObjects(resultObject) {
            const tmpAttachement = resultObject.attachment === null ? "" : resultObject.attachment
            const creationDate = (typeof resultObject.creationDate !== 'undefined')
                ? resultObject.creationDate
                : null
            const updateDate = (typeof resultObject.updateDate !== 'undefined')
                ? resultObject.updateDate
                : null
            const createby = resultObject.createBy !== null ? {
                id: resultObject.createBy.id,
                name: resultObject.createBy.name
            } : null
            const updateBy = resultObject.updateBy !== null ? {
                id: resultObject.updateBy.id,
                name: resultObject.updateBy.name
            } : null
            this.setState({
                informationSheet: cleanupQuestionObject(resultObject),
                informationSheetId: resultObject.id,
                informationSheetTitle: resultObject.title,
                informationSheetContent: resultObject.content,
                informationSheetAttachment: tmpAttachement,
                informationSheetNumberPlayerUnlocked: resultObject.numberPlayerUnlocked,
                informationSheetCreationDate: creationDate,
                informationSheetUpdateDate: updateDate,
                informationSheetCreateBy: createby,
                informationSheetUpdateBy: updateBy,
                informationSheetLanguage: resultObject.language?.id,
                loading: false
            })
        }

        initNewObject() {
            this.setState({
                informationSheet: getEmptyQuestionObject(),
                informationSheetId: null,
                informationSheetTitle: "",
                informationSheetContent: "",
                informationSheetAttachment: "",
                informationSheetNumberPlayerUnlocked: null,
                informationSheetCreationDate: null,
                informationSheetUpdateDate: null,
                informationSheetCreateBy: null,
                informationSheetUpdateBy: null,
                informationSheetLanguage: null,
                loading: false
            })
        }

        handleTitleChange(e) {
            this.callbackAfterChange({
                index: "title",
                value: e.target.value
            })
            const title = e.target.value
            this.setState(prevState => {
                let informationSheet = prevState.informationSheet
                informationSheet.title = title
                return {informationSheet: informationSheet, informationSheetTitle: title}
            })
        }

        handleContentChange(e) {
            this.callbackAfterChange({
                index: "content",
                value: e.target.value
            })
            const content = e.target.value
            this.setState(prevState => {
                let informationSheet = prevState.informationSheet
                informationSheet.content = content
                return {informationSheet: informationSheet, informationSheetContent: content}
            })
        }

        handleAttachmentChange(e) {
            this.callbackAfterChange({
                index: "attachment",
                value: e.target.value
            })
            const attachment = e.target.value
            this.setState(prevState => {
                let informationSheet = prevState.informationSheet
                informationSheet.attachment = attachment
                return {informationSheet: informationSheet, informationSheetAttachment: attachment}
            })
        }

        handleLanguageSelectChange(e) {
            this.callbackAfterChange({
                index: "language",
                value: e?.value ?? null
            })
            const id = e?.value ?? null
            this.setState(prevState => {
                let informationSheet = prevState.informationSheet
                informationSheet.language = id === null ? null : `/api/language/${e.value}`
                return {informationSheet: informationSheet, informationSheetLanguage: id}
            })
        }

        handleSave() {
            (async function() {
                if (!this.checkForm()) {
                    Helper.displayMessage(this.props.t('quiz.pages.informationSheet.form.message.required'), 'error')
                    return
                }
                this.setState({loading: true})
                let response = null
                let message = ""
                if (this.state.informationSheetId === null) {
                    const existInformationSheet = await Api.informationSheet.getInformationSheets(1,
                        {"information_sheet_text": this.state.informationSheetTitle, "per_page": 1000})
                    let isExist = false
                    for (let item of existInformationSheet.data) {
                        if (item.title === this.state.informationSheetTitle) {
                            isExist = true
                        }
                    }
                    if (isExist) {
                        message = this.props.t('quiz.pages.informationSheet.form.message.duplicate')
                        Helper.displayMessage(message, 'error')
                    } else {
                        response = await Api.informationSheet.createInformationSheet(
                            this.state.informationSheetTitle,
                            this.state.informationSheetContent,
                            this.state.informationSheetLanguage,
                            null,
                            0,
                            this.state.informationSheetAttachment
                        )
                        message = this.props.t('quiz.pages.informationSheet.form.message.create')
                        if (response?.status === Constants.HTTP_OK || response?.status ===
                            Constants.HTTP_CREATED) {
                            this.callbackAfterCreate(response.data.id)
                            Helper.displayMessage(message)
                        } else {
                            message = this.props.t('quiz.pages.informationSheet.form.message.error')
                            if (response["@type"] === "ConstraintViolationList") {
                                message = response.violations[0].message
                            }
                            Helper.displayMessage(message, 'error')
                        }
                    }
                } else {
                    response = await Api.informationSheet.updateInformationSheet(
                        this.state.informationSheetId, this.state.informationSheet)
                    message = this.props.t('quiz.pages.informationSheet.form.message.update')
                    if (response?.status === Constants.HTTP_OK || response?.status ===
                        Constants.HTTP_CREATED) {
                        this.setState(prevState => {
                            const tmpUpdateDate = response.data.updateDate ??
                                prevState.informationSheetUpdateDate
                            return {
                                informationSheetUpdateDate: tmpUpdateDate,
                                informationSheetUpdateBy: {
                                    id: this.state.user?.id,
                                    name: this.state.user?.name
                                }
                            }
                        })
                        this.props.handleRefreshList()
                        Helper.displayMessage(message)
                    } else {
                        message = this.props.t('quiz.pages.informationSheet.form.message.error')
                        if (response["@type"] === "ConstraintViolationList") {
                            message = response.violations[0].message
                        }
                        Helper.displayMessage(message, 'error')
                    }
                }
                this.setState({loading: false})
            }).bind(this)()
        }

        checkForm() {
            return this.state.informationSheetTitle !== ""
                && this.state.informationSheetContent !== ""
                && this.state.informationSheetLanguage !== null
        }

        render() {
            const {t} = this.props
            const requiredTitle = <Requiredstar title={t('quiz.pages.informationSheet.form.title')} />
            const requiredTContent = <Requiredstar title={t('quiz.pages.informationSheet.form.content')} />
            return <article className='information-sheet-form'>
                <div className='information-sheet-form-content' style={{height: "100%"}}>
                    {this.state.loading && <Loading />}
                    <div style={{padding: "18px"}}>
                        <CssTextField fullWidth
                                      type='text' value={this.state.informationSheetTitle}
                                      name='informationSheetTitle'
                                      title={t('quiz.pages.informationSheet.form.titleHover')}
                                      variant='outlined'
                                      label={requiredTitle}
                                      onChange={this.handleTitleChange}
                        />
                    </div>
                    <div style={{padding: "15px 35px 35px 35px"}}>
                        <div style={{paddingBottom: "22px"}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Grid container>
                                        <Grid item xs={6} md={6}>
                                            <div><b>{t('quiz.pages.informationSheet.form.createDate')} :</b>
                                            </div>
                                            <div><b>{t('quiz.pages.informationSheet.form.createBy')} :</b>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <div>{this.state.informationSheetCreationDate !== null ? moment(
                                                this.state.informationSheetCreationDate).
                                                format("DD/MM/YYYY HH:mm") : "-"}</div>
                                            <div>{this.state.informationSheetCreateBy !== null
                                                ? this.state.informationSheetCreateBy.name
                                                : "-"}</div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container>
                                        <Grid item xs={6} md={6}>
                                            <div><b>{t('quiz.pages.informationSheet.form.updateDate')} :</b>
                                            </div>
                                            <div><b>{t('quiz.pages.informationSheet.form.updateBy')} :</b>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <div>{this.state.informationSheetUpdateDate !== null ? moment(
                                                this.state.informationSheetUpdateDate).
                                                format("DD/MM/YYYY HH:mm") : "-"}</div>
                                            <div>{this.state.informationSheetUpdateBy !== null
                                                ? this.state.informationSheetUpdateBy.name
                                                : "-"}</div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div><b>{t(
                                        'quiz.pages.informationSheet.form.unlockBy')} {this.state.informationSheetNumberPlayerUnlocked !==
                                    null ? this.state.informationSheetNumberPlayerUnlocked : "-"} {t(
                                        'quiz.pages.informationSheet.form.player')}</b></div>
                                </Grid>
                            </Grid>
                        </div>
                        <Divider variant='middle' />
                        <div style={{padding: "25px 0"}}>
                            <CssTextField fullWidth
                                          type='text' value={this.state.informationSheetContent}
                                          name='informationSheetContenu'
                                          title={t('quiz.pages.informationSheet.form.contentHover')}
                                          variant='outlined'
                                          label={requiredTContent}
                                          onChange={this.handleContentChange}
                                          multiline
                                          rows={8}
                            />
                        </div>
                        <div>
                            <FormControl variant='outlined' className='formField formField-input'
                                         style={{width: "100%"}}>
                                <InputLabel htmlFor='outlined-attachement'>{t(
                                    'quiz.pages.informationSheet.form.link')}</InputLabel>
                                <CssOutlinedInput
                                    id='outlined-attachement' name='attachement'
                                    type='text'
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <a href={this.state.informationSheetAttachment}
                                               target='_blank'><OpenInNewIcon /></a>
                                        </InputAdornment>
                                    }
                                    label={t('quiz.pages.informationSheet.form.link')}
                                    title={t('quiz.pages.informationSheet.form.link')}
                                    value={this.state.informationSheetAttachment}
                                    onChange={this.handleAttachmentChange}
                                    size='small'
                                />
                            </FormControl>
                        </div>
                        <div style={{paddingTop: "15px"}}>
                            <span>{t('quiz.pages.informationSheet.form.language')} <span
                                style={{color: "red"}}>*</span></span>
                            <SelectWithData
                                ref={this.languageSelector}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                className='question-language-selector'
                                noPadding={true}
                                placeholder={t('quiz.pages.informationSheet.form.languagePlaceholder')}
                                options={this.languageOptions}
                                onChange={this.handleLanguageSelectChange}
                                apiFunction={Api.language.getLanguages}
                                selectType='Language'
                                context={this}
                                selected={this.state.informationSheetLanguage}
                            />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        margin: "0px 20px"
                    }}>
                        <button type='button' className='btn btn-lg success'
                                title={t('quiz.pages.informationSheet.form.buttonTitle')}
                                onClick={this.handleSave}>
                            {t('quiz.pages.informationSheet.form.buttonText')}
                        </button>
                    </div>
                </div>
            </article>
        }
    }
)

export const Requiredstar = ({style, title}) => {
    return (<>
            <span style={{paddingRight: "10px"}}>{title}</span>
            <div className='required-star' style={style}>
                <FontAwesomeIcon icon='fas fa-star-of-life'></FontAwesomeIcon>
            </div>
        </>
    )
}

const InformationSheetFilters = withTranslation()(
    class extends React.Component {
        static defaultProps = {
            onLoadingEnd: () => {}
        }

        constructor(props) {
            super(props)
            this.state = {
                informationSheetFilterText: "",
                optionsLanguages: [],
                hasFilters: false,
                loading: false,
                selectedLanguage: null
            }

            this.loadingComponents = {
                language: false
            }

            this.languageOptions = [
                {
                    label: props.t('tableColumnNames.language'),
                    icon: 'fa-language',
                    options: []
                }
            ]

            this.handleFilterSubmit = this.handleFilterSubmit.bind(this)
            this.handleClearFilter = this.handleClearFilter.bind(this)
            this.handleLanguageSelectChange = this.handleLanguageSelectChange.bind(this)
            this.updateButtonState = this.updateButtonState.bind(this)
            this.getFilters = this.getFilters.bind(this)
            this._handleInputKeyDown = this._handleInputKeyDown.bind(this)
        }

        getFilters() {
            let filters = {}

            // retrieves typed text
            if (this.state.informationSheetFilterText !== "") {
                filters.informationSheetFilterText = this.state.informationSheetFilterText
            }

            // retrieves selected languages
            if (this.state.optionsLanguages.length !== 0) {
                let languageArray = this.state.optionsLanguages
                languageArray = _.map(languageArray, (language) => {
                    return language.value
                })
                filters.languages = languageArray
            }

            this.setState(() => {
                return {hasFilters: Object.keys(filters).length !== 0}
            })

            return filters
        }

        handleFilterSubmit() {
            this.props.onFilter(this.getFilters, this.updateButtonState)
        }

        handleClearFilter() {
            this.setState({
                informationSheetFilterText: "",
                optionsLanguages: [],
                hasFilters: false,
                selectedLanguage: null
            }, () => {
                this.handleFilterSubmit()
            })
        }

        updateButtonState(isLoading = false) {
            this.setState({loading: isLoading})
        }

        handleLanguageSelectChange(selectedOptions) {
            const id = selectedOptions?.value ?? null
            const options = (selectedOptions === null) ? [] : [selectedOptions]
            this.setState({
                optionsLanguages: options,
                selectedLanguage: id
            })
        }

        _handleInputKeyDown(event){
            if (event.key === 'Enter') {
                this.handleFilterSubmit()
            }
        }

        _updateLoadingState(entry) {
            this.loadingComponents[entry] = true
            if (_.find(this.loadingComponents, o => o === false) === undefined) {
                this.props.onLoadingEnd()
                this.setState({loading: false})
            }
        }

        render() {
            const {t} = this.props
            return <div className='bo-data-filters input-text-filters'>
                <InputField className='informationSheets-text' type='text' name='informationSheetFilterText'
                            value={this.state.informationSheetFilterText} onKeyDown={this._handleInputKeyDown}
                            title={t('quiz.pages.informationSheet.filter.search')} context={this}>
                    {t('quiz.pages.informationSheet.filter.search')}
                </InputField>
                <SelectWithData
                    isMulti={false}
                    closeMenuOnSelect={true}
                    className='information-sheets-language-selector'
                    placeholder={t('quiz.pages.informationSheet.form.languagePlaceholder')}
                    options={this.languageOptions}
                    onChange={this.handleLanguageSelectChange}
                    apiFunction={Api.language.getLanguages}
                    onLoadingEnd={() => this._updateLoadingState('language')}
                    selectType='Language'
                    context={this}
                    selected={this.state.selectedLanguage}
                />
                <div>
                    <button type='button' className='btn default filterButton'
                            title={t('quiz.pages.informationSheet.filter.titleFilterButton')}
                            disabled={this.state.loading}
                            onClick={this.handleFilterSubmit}>
                        <FontAwesomeIcon icon='fas fa-filter' />
                    </button>
                    {this.state.hasFilters &&
                        <button type='button' className='btn warning resetButton'
                                title={t('quiz.pages.informationSheet.filter.titleResetButton')}
                                onClick={this.handleClearFilter}>
                            <FontAwesomeIcon icon='fas fa-filter-circle-xmark' />
                        </button>
                    }
                </div>
            </div>
        }
    }
)

/* ================================== EXPORTED COMPONENTS ================================== */
const exported = {
    InformationSheetList, InformationSheetFilters
}
export default exported

/* ================================== GLOBAL FUNCTIONS ================================== */

/**
 * Call information sheet Api and update table state with the result of the api call
 *
 * @param page {int}
 * @param filters {object|null}
 * @param callback {Function|null}
 * @return {Promise<void>}
 */
async function getInformationSheetLines(page = 1, filters = null, callback = null) {
    Helper.getDataTableLines.bind(this)(
        Api.informationSheet.getInformationSheets,
        {"order[id]": "desc", "per_page": this.state.perPage},
        "informationSheets",
        FormatRow,
        page,
        filters,
        callback
    )
}

/**
 * Create a jsx object that will be interpreted by the DataTable
 *
 * @param informationSheet {object}
 * @return {Promise<{id, title: *}>}
 */
async function FormatRow(informationSheet) {
    return {
        id: informationSheet.id,
        informationSheet: informationSheet
    }
}

function getEmptyQuestionObject() {
    return {
        id: null,
        title: "",
        content: "",
        attachment: "",
        language: null
    }
}

function cleanupQuestionObject(object) {
    const convertObject = {...object}
    const tmpAttachement = object.attachment === null ? "" : object.attachment
    convertObject.language = object.language["@id"]
    convertObject.attachment = tmpAttachement
    delete convertObject["@id"]
    delete convertObject["@type"]
    delete convertObject["@context"]
    delete convertObject["createBy"]
    delete convertObject["updateBy"]
    delete convertObject["unlockedSheets"]
    delete convertObject["creationDate"]
    delete convertObject["updateDate"]
    delete convertObject["numberPlayerUnlocked"]

    return convertObject
}