import { useEffect, useState } from 'react';
import { Api, Constants } from 'scg.common-library';
import Helper from '../../services/Helper';
import { toast } from 'react-toastify';
import SelectWithData from '../general/form/SelectWithData';
import { useForm } from 'react-hook-form';
import { Session } from 'scg.common-library';
import { SelectField } from '../general/form/Select';
import { Divider, Grid, TextField, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from "moment";

import './users.css';

function NewUser({ onClose, callbackSuccess, userId = 0, needLoadDataUser = false, needLoadDataUserId = 0 }) {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [pseudo, setPseudo] = useState('');
  const [societeId, setSocieteId] = useState(0);
  const [level, setLevel] = useState(0);
  const [email, setEmail] = useState('');
  const [langue, setLangue] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [societySelectable, setSocietySelectable] = useState(true);
  const [role, setRole] = useState({
    value: Constants.ROLE_PLAYER,
    label: t('users.roles.player')
  });
  const [rolesAvailable, setRolesAvailable] = useState([]);
  const [dataLoaded, setdataLoaded] = useState(false);
  const [initialLoadedEmail, setInitialLoadedEmail] = useState('');
  const [isBlocked, setIsBlocked] = useState(false);
  const [isBlockedTry, setIsBlockedTry] = useState(0);
  const [isBlockedAt, setIsBlockedAt] = useState("");

  if (needLoadDataUser && needLoadDataUserId > 0 && userId === 0) {
    userId = needLoadDataUserId
  }

  //for form validation
  const {
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  useEffect(() => {
    getDefaultlevel();
    setLangue(Session.getSessionUser().language.id);
    prepareSocietyField();
  }, []);

  //getting data if it is about modif
  useEffect(() => {
    if (!dataLoaded && rolesAvailable.length > 0 && userId > 0) {
      getData(userId);
    }
  });

  const handleClose = () => {
    onClose();
  };

  //getting and loading User details
  async function getData(id) {
    const response = await Api.user.getUser(id, true, Session.getJwtToken());
    if (response.status === 200) {
      setPseudo(response.data.user.pseudo);
      setName(response.data.user.name);
      setSurname(response.data.user.surname);
      setEmail(response.data.user.email);
      setSocieteId(response.data.user.society.id);
      const roleRead = rolesAvailable.find(
        (role) => role.value === response.data.user.roles[0]
      );
      setRole({ value: roleRead.value, label: roleRead.label });
      if (response.data.user.incorrectLogins >= 5) {
        setIsBlocked(true)
        setIsBlockedTry(response.data.user.incorrectLogins)
        setIsBlockedAt(moment(response.data.user.incorrectLoginAt). format("DD/MM/YYYY HH:mm"))
      }
      //register values
      setValue('nom', response.data.user.name);
      setValue('prenom', response.data.user.surname);
      setValue('pseudo', response.data.user.pseudo);
      setValue('email', response.data.user.email);
      setInitialLoadedEmail(response.data.user.email);
    } else {
      toast.error(t('users.addModal.errors.loading'), Helper.getToastOptions());
      setBtnDisabled(true);
    }
    setdataLoaded(true);
  }

  async function testEmail() {
    setBtnDisabled(true);
    let response = await Api.user.checkUserIdentifier(email);
    const resultObject = Helper.isValidResponse(response);
    if (resultObject?.id > 0) {
      toast.error(
        `${t('users.addModal.errors.email.before')} ${email} ${t(
          'users.addModal.errors.email.after'
        )}`,
        Helper.getToastOptions()
      );
      setBtnDisabled(false);
      return false;
    } else {
      return true;
    }
  }

  function verifyPromiseResponse(
    response,
    successStatus = 201,
    successMassage = ''
  ) {
    if (response?.status !== successStatus) {
      toast.error(t('users.addModal.errors.values'), Helper.getToastOptions());
    } else {
      toast.success(successMassage, Helper.getToastOptions());
      //when save is done successfully, close the modal
      handleClose();
      callbackSuccess();
    }
  }

  async function saveDetails() {
    setBtnDisabled(true);
    setdataLoaded(false);
    //process new entry
    if (userId < 1) {
      //verify email duplication before save
      if (!(await testEmail())) return;
      if (level < 1) {
        toast.error(t('users.addModal.errors.defaultLevel'));
        return null;
      }
      let response = await Api.user.createUser(
        name,
        surname,
        pseudo,
        email,
        societeId,
        langue,
        level,
        [role.value]
      );
      verifyPromiseResponse(response, 201, t('users.addModal.success.addUser'));
    } else {
      if (initialLoadedEmail !== email) {
        if (!(await testEmail())) return;
      }
      //process user update
      let response = await Api.user.updateUser(
        userId,
        {
          name: name,
          surname: surname,
          pseudo: pseudo,
          email: email,
          roles: [role.value],
          societyId: societeId
        },
        Session.getJwtToken()
      );
      verifyPromiseResponse(
        response,
        200,
        t('users.addModal.success.updateUser')
      );
      callbackSuccess();
    }
    setBtnDisabled(false);
    setdataLoaded(true);
  }

  function prepareSocietyField() {
    if (Session.getUser()?.roles[0] === Constants.ROLE_SUPER_ADMIN) {
      setSocietySelectable(true);
      setRolesAvailable([
        {
          value: Constants.ROLE_SUPER_ADMIN,
          label: t('users.roles.superAdmin')
        },
        {
          value: Constants.ROLE_ADMIN,
          label: t('users.roles.admin')
        },
        { value: Constants.ROLE_PLAYER, label: t('users.roles.player') }
      ]);
    } else {
      setSocietySelectable(false);
      const sessionUser = Session.getSessionUser();
      setSocieteId(sessionUser.society.id);
      setRolesAvailable([
        { value: Constants.ROLE_ADMIN, label: t('users.roles.admin') },
        {
          value: Constants.ROLE_PLAYER,
          label: t('users.roles.player')
        }
      ]);
    }
  }

  function handleSelectedSociety(e) {
    setSocieteId(e?.value);
  }

  function handleSelectedRole(e) {
    setRole(e);
  }

  async function getDefaultlevel() {
    const resultat = await Api.level.getIdByShortName('bronze');
    if (resultat.status === 200) {
      setLevel(resultat?.data.id);
    }
  }

  return (
    <form onSubmit={handleSubmit(() => saveDetails())} className="user-form">
      <div className="flex flex-column w-100">
        {isBlocked && 
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12}>
              <Alert severity="warning" icon={false}>
                <p style={{margin: 0}}>{t('users.addModal.warning.userBlock.message',{failed_count: isBlockedTry})}</p>
                <p style={{margin: 0}}>{t('users.addModal.warning.userBlock.labelDate')} {isBlockedAt}</p>
              </Alert>
            </Grid>
          </Grid>
        }
        <Grid container spacing={2} className="user-form-info">
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="nom"
              required
              fullWidth
              label={t('users.addModal.labels.name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
            {errors?.nom?.type === 'required' && (
              <p className="errorMessage">{t('users.addModal.errors.name')}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="family-name"
              name="prenom"
              required
              fullWidth
              label={t('users.addModal.labels.surname')}
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
            {errors?.prenom?.type === 'required' && (
              <p className="errorMessage">
                {t('users.addModal.errors.surname')}
              </p>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoComplete="nickname"
              name="pseudo"
              required
              fullWidth
              label={t('users.addModal.labels.nickname')}
              value={pseudo}
              onChange={(e) => setPseudo(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoComplete="email"
              name="email"
              required
              fullWidth
              label={t('users.addModal.labels.email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>

        <Divider variant="middle" />

        <Grid container spacing={2} className="user-form-profile">
          <Grid
            item
            xs={6}
            style={{ display: societySelectable ? 'block' : 'none' }}
          >
            <SelectWithData
              isMulti={false}
              closeMenuOnSelect={true}
              options={[
                {
                  label: t('users.addModal.labels.company'),
                  icon: 'fa-language',
                  options: []
                }
              ]}
              apiFunction={Api.society.getSocieties}
              onChange={handleSelectedSociety}
              value={societeId}
              placeholder={t('users.addModal.labels.company')}
              label={t('users.addModal.labels.company')}
              selected={societeId}
              selectType='Default'
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              options={rolesAvailable}
              classNamePrefix="reactSelect-custom"
              className="formField-style-classic"
              isMulti={false}
              placeholder={t('users.addModal.labels.role')}
              value={role}
              onChange={handleSelectedRole}
            />
          </Grid>
        </Grid>
      </div>
      <div className="flex-end user-form-footer">
        <input
          type="button"
          className="btn btn-lg gray closeButton"
          onClick={handleClose}
          value={t('users.addModal.buttons.cancel')}
        />
        <input
          type="submit"
          className="btn btn-lg green addButton"
          value={t('users.addModal.buttons.save')}
          disabled={btnDisabled}
        />
      </div>
    </form>
  );
}

export default NewUser;
