import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {routes as Routing} from '../../../services/RoutesHelper'
import {Api, Constants, Roles, Session} from 'scg.common-library'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Helper from '../../../services/Helper'
import {TableSkeleton} from '../../../services/LoadingHelper'
import {useTranslation} from 'react-i18next'

import './statistics.css'

export default function Statistics() {
    const [isLoading, setLoading] = useState(true)
    const [response, setResponse] = useState(null)
    const isSuperAdmin = Roles.isGranted(
        Session.getSessionUser()?.roles[0],
        Constants.ROLE_SUPER_ADMIN
    )

    const {t} = useTranslation()

    useEffect(() => {
        if (isSuperAdmin) {
            Api.statistics.getStatistics().then(r => {
                const result = Helper.isValidResponse(r)
                if (result) {
                    setResponse(result)
                    setLoading(false)
                }
            })
        } else {
            Api.user.getUser(Session.getSessionUser().id, true).then(r => {
                const result = Helper.isValidResponse(r)
                if (result) {
                    setResponse(result)
                    setLoading(false)
                }
            })
        }
    }, [isSuperAdmin])

    return (
        <article className='stats'>
            <h3>{t('dashboard.statistics.title')}</h3>
            <section className='stats-list card'>
                {isLoading ? <TableSkeleton linesCount={4}/>
                    : <>
                        {isSuperAdmin && <>
                            <div className='stats-item'>
                                <FontAwesomeIcon icon='fas fa-user' />
                                <Link to={Routing.bo_users}
                                      title={t('dashboard.statistics.usersItem.title')}>
                                    <span className='stats-value'>{response?.userCount}</span>
                                    {' ' + t('dashboard.statistics.usersItem.name')}
                                </Link>
                            </div>
                            <div className='stats-item'>
                                <FontAwesomeIcon icon='fas fa-building-user' />
                                <Link to={Routing.bo_society}
                                      title={t('dashboard.statistics.companiesItem.title')}>
                                    <span className='stats-value'>{response?.societyCount}</span>
                                    {' ' + t('dashboard.statistics.companiesItem.name')}
                                </Link>
                            </div>
                            <div className='stats-item'>
                                <FontAwesomeIcon icon='fas fa-clipboard-question' />
                                <Link to={Routing.bo_question}
                                      title={t('dashboard.statistics.questionsItem.title')}>
                                    <span className='stats-value'>{response?.questionCount}</span>
                                    {' ' + t('dashboard.statistics.questionsItem.name')}
                                </Link>
                            </div>
                            <div className='stats-item'>
                                <FontAwesomeIcon icon='fas fa-newspaper' />
                                <Link to={Routing.app_home}
                                      title={t('dashboard.statistics.informationItem.title')}>
                                    <span className='stats-value'>{response?.informationSheetCount}</span>
                                    {' ' + t('dashboard.statistics.informationItem.name')}
                                </Link>
                            </div>
                        </>}
                        {!isSuperAdmin && <>
                            <div className='stats-item'>
                                <FontAwesomeIcon icon='fas fa-users' />
                                <Link to={Routing.bo_users}
                                      title={t('dashboard.statistics.usersItem.title')}>
                                        <span
                                            className='stats-value'>{response?.society_granted_users_number}</span>
                                    {' ' + t('dashboard.statistics.usersItem.name')}
                                </Link>
                            </div>
                            <div className='stats-item'>
                                <FontAwesomeIcon icon='fas fa-user-check' />
                                <Link to={Routing.bo_users}
                                      title={t('dashboard.statistics.activeUsersItem.title')}>
                                        <span
                                            className='stats-value'>{response?.society_linked_users_number}</span>
                                    {' ' + t('dashboard.statistics.activeUsersItem.name')}
                                </Link>
                            </div>
                            <div className='stats-item'>
                                <FontAwesomeIcon icon='fas fa-clock' />
                                <Link to={Routing.bo_society_details}
                                      title={t('dashboard.statistics.subscriptionDateItem.title')}>
                                    <span className='stats-value'></span>
                                    {t('dashboard.statistics.subscriptionDateItem.name')}:
                                    {' ' + new Date(
                                        response?.user.society.subscriptionDate).toLocaleDateString()}
                                </Link>
                            </div>
                            <div className='stats-item'>
                                <FontAwesomeIcon icon='fas fa-receipt' />
                                <Link to={Routing.bo_society_details}
                                      title={t('dashboard.statistics.subscriptionExpiredItem.title')}>
                                    <span className='stats-value'></span>
                                    {response?.society_expired_subscription
                                        ? <span className='badge-status-warning'>
                                            {t('dashboard.statistics.subscriptionExpiredItem.status.expired')}
                                        </span>
                                        : <span className='badge-status-ok'>
                                            {t('dashboard.statistics.subscriptionExpiredItem.status.active')}
                                        </span>
                                    }
                                </Link>
                            </div>
                        </>}
                    </>}
            </section>
        </article>
    )
}
