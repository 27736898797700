import Menu from './Menu/Menu';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export default function HtmlStructure({
  children,
  menuName,
  sectionClassName
}) {
  return (
    <>
      <Menu nameMenu={menuName} />
      <section className={`content ${sectionClassName}`}>{children}</section>
      <ToastContainer />
    </>
  );
}
