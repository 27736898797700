import { Component } from 'react';
import ReactSelect from 'react-select';

import './select.css';

class SelectField extends Component {
  static defaultProps = {
    optionFocusedBackground: '#4EB0CC26',
    optionActiveBackground: '#4EB0CC33'
  };

  constructor(props) {
    super(props);
    this.formatGroupLabel = this.formatGroupLabel.bind(this);
  }

  formatGroupLabel(data) {
    let icon = data.icon ?? 'fa-user';
    return (
      <div className={'reactSelect-custom__groupe'}>
        <span>
          <i className={`fas ${icon}`} /> {data.label}
        </span>
        <span className={'reactSelect-custom__groupeBadge'}>
          {data.options.length}
        </span>
      </div>
    );
  }

  handleSelectChange(selectedOptions) {
    this.setState({
      data: selectedOptions
    });
  }

  render() {
    const {
      className,
      classNamePrefix,
      options,
      isMulti,
      isClearable,
      closeMenuOnSelect,
      placeholder,
      context,
      value
    } = this.props;
    const handleSelectChange =
      this.props.onChange ?? this.handleSelectChange.bind(context);
    const formatGroupLabel =
      this.props.formatGroupLabel ?? this.formatGroupLabel;
    const isInputClearable = isClearable ?? true;

    const selectStyle = {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? this.props.optionFocusedBackground
          : undefined,
        ':active': { backgroundColor: this.props.optionActiveBackground }
      })
    };

    return (
      <ReactSelect
        // key={`unique_select_key__${JSON.stringify(value)}`}
        className={className}
        classNamePrefix={classNamePrefix}
        onChange={handleSelectChange}
        options={options}
        value={value || ''}
        isMulti={isMulti}
        isClearable={isInputClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        formatGroupLabel={formatGroupLabel}
        placeholder={placeholder}
        styles={selectStyle}
        aria-label="Selector"
      />
    );
  }
}

export { SelectField };
