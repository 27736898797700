import React,{ useState, useEffect } from 'react'
import { Api, Session, Constants } from 'scg.common-library'
import { useTranslation } from 'react-i18next'
import HtmlStructure from '../general/HtmlStructure'
import { Loading } from '../general/form/Loading'
import Helper from '../../services/Helper'
import { SESSION_EXPIRATION_DAYS } from '../../services/Constants'
import {
    TextField,
    FormControl,
    CircularProgress
} from '@mui/material'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import HighlightOffOutlinedIcon from  '@mui/icons-material/HighlightOffOutlined'
import './profil.css'

export default function ProfilPage() {
    const { t } = useTranslation()
    document.title = `${t('profil.documentTitle')} - ` + Constants.DOCUMENT_TITLE_BACKOFFICE

    return (
        <HtmlStructure menuName={t('profil.documentTitle')} sectionClassName="profil">
            <Profil />
        </HtmlStructure>
    );
}
/**
 * Formulaire mise à jour information
 */
export const InformationForm = ({ user }) => {

    const { t } = useTranslation();
    const [nom, setNom] = useState('')
    const [email, setEmail] = useState('')
    const [prenom, setPrenom] = useState('')
    const [pseudo, setPseudo] = useState('')
    const [profil, setProfil] = useState('')
    const [errors, setErrors] = useState([])
    const [societe, setSociete] = useState('')
    const [isSaving, setIsSaving] = useState(false)

    useEffect( () => {
        if(user){
            const profil = user.roles.includes('ROLE_SUPER_ADMIN') ? t('profil.labels.superadmin') : t('profil.labels.admin')
            setProfil(profil)
            setEmail(user.email)
            setNom(user.name)
            setPrenom(user.surname)
            setPseudo(user.pseudo)
            setSociete(user.society.name)
        }
    }, [ user ] )

    const handleSubmit = (e) => {
        e.preventDefault();
        const userLogin = Session.getSessionUser();
        let errors = [];
        if( nom === '' || nom === null ) errors.push('nom')
        if( prenom === '' || prenom === null ) errors.push('prenom')
        if( pseudo === '' || pseudo === null ) errors.push('pseudo')
        if( errors.length ){
            setErrors(errors)
            Helper.displayMessage(t('profil.validation.required'), 'warning')
            return false
        }
        setErrors([]);
        if(userLogin){
            setIsSaving(true)
            const userData = {name : nom, surname : prenom, pseudo : pseudo}
            Api.user.updateUser( userLogin.id, {...userData, roles: user.roles } )
                .then( (response) => {
                    if( response.status === Constants.HTTP_OK ){
                        const userSession = { ...userLogin, ...userData }
                        Session.setCookie(`_session`, userSession, SESSION_EXPIRATION_DAYS)
                        Helper.displayMessage(t('profil.messages.success_profil'), 'success')
                    }else{
                        Helper.displayMessage(t('profil.messages.error_data'), 'error')
                    }
                })
                .finally( () => setIsSaving(false) )
                .catch( () => Helper.displayMessage(t('profil.messages.error_data'), 'error') )
        }
    }

    return (
        <div className='card bloc-informations'>
            <h3 className="form-title">{t('profil.forms.info_label')} :</h3>
            <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                    <FormControl focused fullWidth>
                        <TextField error={errors.includes('nom')} name='nom' label={t('profil.labels.nom')} value={nom} onChange={ evt => setNom(evt.target.value) }/>
                    </FormControl>
                </div>
                <div className='form-group'>
                    <FormControl focused fullWidth>
                        <TextField error={errors.includes('prenom')} name='prenom' label={t('profil.labels.prenom')} value={prenom} onChange={ evt => setPrenom(evt.target.value) }/>
                    </FormControl>
                </div>
                <div className='form-group'>
                    <FormControl focused fullWidth>
                        <TextField error={errors.includes('pseudo')} name='pseudo' label={t('profil.labels.pseudo')} value={pseudo} onChange={ evt => setPseudo(evt.target.value) }/>
                    </FormControl>
                </div>
                <div className='form-group'>
                    <FormControl focused fullWidth>
                        <TextField name='email' type='email' label={t('profil.labels.email')} value={email} disabled/>
                    </FormControl>
                </div>
                <div className='form-group'>
                    <FormControl focused fullWidth>
                        <TextField name='profil' label={t('profil.labels.profil')} value={profil} disabled />
                    </FormControl>
                </div>
                <div className='form-group'>
                    <FormControl focused fullWidth>
                        <TextField name='societe' label={t('profil.labels.societe')} value={societe} disabled />
                    </FormControl>
                </div>
                <button className={`btn default`}title={t('profil.forms.save')} disabled={isSaving}>
                    { isSaving ? <CircularProgress size={18} /> : t('profil.forms.save') }
                </button>
            </form>
        </div>
    )
}
/**
 * Formulaire changement de mot de passe
 */
export const PasswordForm = () => {

    const { t } = useTranslation();

    const [errors, setErrors] = useState([])
    const [ancienPwd, setAncienPwd] = useState('')
    const [nouveauPwd, setNouveauPwd] = useState('')
    const [confirmPwd, setConfirmPwd] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    // states pour la validation du mot de passe
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const [passwordLength, setPasswordLength] = useState(false)
    const [passwordLowerCase, setPasswordLowerCase] = useState(false)
    const [passwordNumber, setPasswordNumber] = useState(false)
    const [passwordUppercase, setPasswordUppercase] = useState(false)
    const [passwordSpecialChar, setPasswordSpecialChar] = useState(false)
    const [passwordsMatchs, setPasswordsMatchs] = useState(false)

    useEffect( () => {  
        checkPassowrdValidity();
        if( passwordLength && passwordLowerCase && passwordNumber && passwordUppercase && passwordSpecialChar && passwordsMatchs ){
            setIsPasswordValid(true);
        }else{
            setIsPasswordValid(false);
        }
    }, [nouveauPwd, confirmPwd, passwordLength, passwordLowerCase, passwordNumber, passwordUppercase, passwordSpecialChar, passwordsMatchs] )

    const checkPassowrdValidity = () => {
        const checkPwdLength = nouveauPwd.length > 8
        const checkPwdLowerCase = (new RegExp(/[a-z]/)).test(nouveauPwd)
        const checkPwdUpperCase = (new RegExp(/[A-Z]/)).test(nouveauPwd)
        const checkPwdHasNumber = (new RegExp(/[1-9]/)).test(nouveauPwd)
        const checkPwdHasSpecialChars = (new RegExp(/[-_#@$%*!?&]/)).test(nouveauPwd)
        const checkPwdMatch = nouveauPwd === confirmPwd && nouveauPwd !== '' && confirmPwd !== ''
        setPasswordLength( checkPwdLength )
        setPasswordLowerCase( checkPwdLowerCase )
        setPasswordUppercase( checkPwdUpperCase )
        setPasswordNumber( checkPwdHasNumber )
        setPasswordSpecialChar( checkPwdHasSpecialChars )
        setPasswordsMatchs( checkPwdMatch )
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = [];
        const userLogin = Session.getSessionUser();
        if( ancienPwd === '' || ancienPwd === null ) errors.push('ancien')
        if( nouveauPwd === '' || nouveauPwd === null ) errors.push('nouveau')
        if( confirmPwd === '' || confirmPwd === null ) errors.push('confirm')
        if( errors.length ){
            setErrors(errors)
            Helper.displayMessage(t('profil.validation.required'), 'warning')
            return false
        }
        if( !isPasswordValid ){
            errors.push('nouveau')
            errors.push('confirm')
            setErrors(errors)
            Helper.displayMessage(t('profil.validation.passowrd'), 'warning')
            return false
        }
        setErrors([])
        if(userLogin){
            setIsSaving(true);
            Api.user.changeUserPassword( userLogin.id, ancienPwd, nouveauPwd)
                .then( (response) => {
                    if( response.status === Constants.HTTP_OK ){
                        Helper.displayMessage(t('profil.messages.success_password'), 'success')
                    }else if( response.status === Constants.HTTP_BAD_REQUEST ){
                        Helper.displayMessage(t('profil.messages.error_passowrd'), 'error')
                    }else {
                        Helper.displayMessage(t('profil.messages.error_data'), 'error')
                    }
                })
                .finally( () => setIsSaving(false) )
                .catch( () => Helper.displayMessage(t('profil.messages.error_data'), 'error') )
        }
    }

    const renderPasswordRules = () => {
        const { t } = useTranslation()
        return (
            <div className="activate-security">
                <div>{t("profil.security.label")}</div>
                <div className="activate-security-requirements">
                    <span className={passwordLength ? "valid" : "not-valid"}>
                        { passwordLength ? <CheckCircleOutlinedIcon/> : <HighlightOffOutlinedIcon/> } {t("profil.security.length")}
                    </span>
                    <span className={passwordLowerCase ? "valid" : "not-valid"}>
                        { passwordLowerCase ? <CheckCircleOutlinedIcon/> : <HighlightOffOutlinedIcon/> } {t("profil.security.lowercase")}
                    </span>
                    <span className={passwordUppercase ? "valid" : "not-valid"}>
                        { passwordUppercase ? <CheckCircleOutlinedIcon/> : <HighlightOffOutlinedIcon/> } {t("profil.security.uppercase")}
                    </span>
                    <span className={passwordNumber ? "valid" : "not-valid"}>
                        { passwordNumber ? <CheckCircleOutlinedIcon/> : <HighlightOffOutlinedIcon/> } {t("profil.security.number")}
                    </span>
                    <span className={passwordSpecialChar ? "valid" : "not-valid"}>
                        { passwordSpecialChar ? <CheckCircleOutlinedIcon/> : <HighlightOffOutlinedIcon/> } {t("profil.security.specialchar")}
                    </span>
                    <span className={passwordsMatchs ? "valid" : "not-valid"}>
                        { passwordsMatchs ? <CheckCircleOutlinedIcon/> : <HighlightOffOutlinedIcon/> } {t("profil.security.match")}
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className='card bloc-password'>
            <h3 className="form-title">{t('profil.forms.pwd_label')} :</h3>
            <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                    <FormControl focused fullWidth>
                        <TextField 
                            name='ancien' 
                            type='password' 
                            label={t('profil.labels.pwd_ancien')} 
                            value={ancienPwd} 
                            error={errors.includes('ancien')} 
                            onChange={ evt => setAncienPwd(evt.target.value) } 
                        />
                    </FormControl>
                </div>
                <div className='form-group'>
                    <FormControl focused fullWidth>
                        <TextField 
                            name='noveau' 
                            type='password' 
                            label={t('profil.labels.pwd_nouveau')}
                            value={nouveauPwd} 
                            error={errors.includes('nouveau')} 
                            onChange={ evt => setNouveauPwd(evt.target.value) } 
                        />
                    </FormControl>
                </div>
                <div className='form-group'>
                    <FormControl focused fullWidth>
                        <TextField 
                            name='confirm' 
                            type='password' 
                            label={t('profil.labels.pwd_confirm')} 
                            value={confirmPwd} 
                            error={errors.includes('confirm')} 
                            onChange={ evt => setConfirmPwd(evt.target.value)}
                        />
                    </FormControl>
                </div>
                { renderPasswordRules() }
                <button 
                    title={t('profil.forms.save_pwd')}
                    disabled={isPasswordValid ? false : true} 
                    className={`btn default ${isPasswordValid ? 'btn-enable' : 'disabled'}`} 
                >
                        { isSaving ? <CircularProgress size={18} /> : t('profil.forms.save_pwd') }
                </button>
            </form>
        </div>
    )
}

export const Profil = () => {

    const { t } = useTranslation()
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true)

    useEffect( () => {
        const userLogin = Session.getSessionUser();
        if(userLogin){
            Api.user.getUser( userLogin.id )
                .then( response => {
                    const userResponse = Helper.isValidResponse(response)
                    if( userResponse ){
                        setUser(userResponse.user)
                    }
                })
                .finally( () => setIsLoading(false) )
                .catch( () => Helper.displayMessage(t('profil.messages.error_fetch'), 'error') )
        }
    }, [] )

    return (
        <article className='profil'>
            { isLoading && <Loading /> }
            <section className='bo-data-title'>
                <h3>{t('profil.title')}</h3>
            </section>
            <section className='bo-data-table'>
                <InformationForm user={user} />
                <PasswordForm />
            </section>
        </article>
    )
}