import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import './modal.css';

const Modal = ({ isShowing, hide, title, ...props }) => {
  const { t } = useTranslation();

  return isShowing
    ? createPortal(
        <>
          <section className="modal-wrapper">
            <div className="modal-overlay" />
            <div className="modal">
              <div className="modal-header">
                <h4>{title}</h4>
                <button
                  type="button"
                  className="btn alert modal-close-button"
                  title={t('general.form.modal.closeButtonTitle')}
                  onClick={hide}
                >
                  <FontAwesomeIcon icon="fas fa-circle-xmark" />
                </button>
              </div>
              <div className="modal-body">{props.children}</div>
            </div>
          </section>
        </>,
        document.body
      )
    : null;
};

export default Modal;
