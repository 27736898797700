import { useEffect } from 'react';
import HtmlStructure from '../general/HtmlStructure';
import { Api, Constants, Session } from 'scg.common-library';
import { useObjectState } from '../../hooks/customHooks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function SocietyDetails() {
  const { t } = useTranslation();

  document.title = `${t('societyDetails.documentTitle')} - ${
    Constants.DOCUMENT_TITLE_BACKOFFICE
  }`;

  const user = Session.getSessionUser();
  console.log(user);

  return (
    <HtmlStructure
      menuName="society_details"
      sectionClassName="society_details"
    >
      <Details id={user?.society?.id} />
    </HtmlStructure>
  );
}

function Details({ id }) {
  const { t } = useTranslation();

  const [state, setState] = useObjectState({
    loading: true,
    modalOpen: false,
    society: null
  });

  useEffect(() => {
    Api.society.getSociety(id).then((r) => {
      let society = r?.data;
      console.log(society);
      setState({
        loading: false,
        society: society
      });
    });
    // eslint-disable-next-line
  }, []);

  const _handleModalOpen = () => {
    setState({ modalOpen: true });
  };

  const _handleModalClose = () => {
    setState({ modalOpen: false });
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="space-around">
        <Grid item xs={7} className="help card">
          <span
            dangerouslySetInnerHTML={{
              __html: t('societyDetails.info.firstRow')
            }}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: t('societyDetails.info.secondRow')
            }}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: t('societyDetails.info.thirdRow')
            }}
          />
          <Button variant="contained" onClick={_handleModalOpen}>
            {t('societyDetails.subscriptionButton')}
          </Button>
          <Dialog open={state.modalOpen} onClose={_handleModalClose}>
            <DialogTitle>{t('societyDetails.modal.title')}</DialogTitle>
            <DialogContent>
              {t('societyDetails.modal.text')}:{' '}
              <a
                href="mailto:support@nextaura.com?subject=Habaka - Renouvellement d%27abonnement"
                children="support@nextaura.com"
              />
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid item xs={4} className="subscription card">
          <Typography variant="overline" gutterBottom>
            {t('societyDetails.subscription.title')}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {state.society?.subscribed && (
              <>
                <CheckCircleIcon className="subscription-active" />{' '}
                {t('societyDetails.subscription.status.active')}{' '}
              </>
            )}
            {state.society?.subscribed && (
              <>
                <WatchLaterIcon className="subscription-near" />{' '}
                {t('societyDetails.subscription.status.expireSoon')}{' '}
              </>
            )}
            {!state.society?.subscribed && (
              <>
                <CircleIcon className="subscription-expired" />{' '}
                {t('societyDetails.subscription.status.expire')}
              </>
            )}
            {state.society?.subscribed === null && (
              <>
                <CircleIcon className="subscription-unknown" />{' '}
                {t('societyDetails.subscription.status.unknown')}
              </>
            )}
          </Typography>
          <Typography variant="overline" gutterBottom>
            {t('societyDetails.endDateTitle')}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {moment(state.society?.subscriptionExpirationDate).format(
              'DD/MM/YYYY HH:mm'
            ) ?? '?'}
          </Typography>
          <Typography variant="overline" gutterBottom>
            {t('societyDetails.usersTitle')}
          </Typography>
          <Typography variant="h6" gutterBottom>
            ? / {state.society?.maxAccounts}
          </Typography>
        </Grid>
        <Grid item xs={12} className="users-state card"></Grid>
      </Grid>
    </>
  );
}
