import React, {useState} from "react"
import {Api, Constants, Session} from "scg.common-library"
import {Loading} from "../general/form/Loading"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Helper from "../../services/Helper"
import Grid from '@mui/material/Grid'
import moment from "moment"
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import {styled} from "@mui/material/styles"
import {
    Chip,
    Button,
    Divider,
    List,
    ListItemButton,
    ListItemText,
    TablePagination,
    IconButton,
    Menu, MenuItem, Tooltip, Badge, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from "@mui/material"
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CancelIcon from '@mui/icons-material/Cancel'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {withTranslation, useTranslation} from 'react-i18next'
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { QUESTION_TYPES } from "../../services/Constants"
import { NavLink } from "react-router-dom"
import {
    routes as Routing,
    setRouteParameters
} from '../../services/RoutesHelper'
import "./reportsContent.css"
import Checkbox from "@mui/material/Checkbox";
import {toast} from "react-toastify";
import CommentIcon from '@mui/icons-material/Comment';
import Modal from "../general/form/Modal";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E0E3E7'
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#61dafb'
        }
    }
})

export const ReportsContent = withTranslation()(
    class extends React.Component {
        static defaultProps = {}

        constructor(props) {
            super(props)

            this.state = {
                loading: true,
                questionReports: [],
                totalRows: 0,
                perPage: Helper.getTablePerPageNumber(),
                idSelected: null,
                selectedRow: false,
                paginationPage: 0,
                statusReport: null
            }

            this.handlePerRowsChange = this.handlePerRowsChange.bind(this)
            this.handlePageChange = this.handlePageChange.bind(this)
            this.handleFilter = this.handleFilter.bind(this)
            this.handleSelectedRow = this.handleSelectedRow.bind(this)
            this.handleChangeStatus = this.handleChangeStatus.bind(this)
            this.callbackChangeStatus = this.callbackChangeStatus.bind(this)
        }

        componentDidMount() {
            Api.questionReport.getQuestionReportCount().then(response => {
                const resultObject = Helper.isValidResponse(response)
                if (resultObject) {
                    this.setState({totalRows: resultObject.count})
                }
            })

            getQuestionReportLines.bind(this)(1)
        }

        handleChangeStatus(id) {
            this.setState({
                statusReport: id
            })
        }

        callbackChangeStatus() {
            getQuestionReportLines.bind(this)(1, this.getFilters())
        }

        handleSelectedRow(id) {
            this.setState({
                idSelected: id,
                selectedRow: true,
                statusReport: null
            })
        }

        getFilters() {
            console.debug("getFilters not initialized !")
            return []
        }

        handlePerRowsChange(event) {
            this.setState({perPage: event.target.value, loading: true, paginationPage: 0}, () => {
                Helper.setTablePerPageNumber(event.target.value)
                getQuestionReportLines.bind(this)(1, this.getFilters())
            })
        }

        handlePageChange(event, page) {
            this.setState({loading: true, paginationPage: page}, () => {
                getQuestionReportLines.bind(this)((page + 1), this.getFilters())
            })
        }

        handleFilter(filterFunctionFromChild, updateButtonStateFunction) {
            this.setState({loading: true, paginationPage: 0})
            updateButtonStateFunction(true)

            this.getFilters = filterFunctionFromChild
            const filters = this.getFilters()

            Api.questionReport.getQuestionReportCount(Helper.getFiltersUrlParams(filters)).
                then(response => {
                    const resultObject = Helper.isValidResponse(response)
                    if (resultObject) {
                        this.setState({totalRows: resultObject.count})
                    }
                })

            getQuestionReportLines.bind(this)(1, filters, updateButtonStateFunction)
        }

        render() {
            const {t} = this.props
            return <article className='question-reports'>
                <section className='question-reports-title'>
                    <h3>{t('reports.title')}</h3>
                </section>
                <section className='bo-data-table'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Paper>
                                <Grid container style={{alignItems: "center"}}>
                                    <Grid item xs={12} md={4}>
                                        <QuestionReportFilters count={this.state.totalRows} onFilter={this.handleFilter} />
                                    </Grid>
                                    <Grid item xs={12} md={8} style={{display: "flex", alignItems: "center"}}>
                                    {this.state.selectedRow &&
                                        <Grid container style={{padding: 10, alignItems: "center"}}>
                                            <Grid item xs={7}>
                                                <label>{t('reports.currently')} : <b>{t('reports.label')} #{this.state.idSelected}</b></label>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Divider sx={{height: 28, m: 0.5}} orientation='vertical' />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container style={{alignItems: "center"}}>
                                                    <Grid item xs={3} style={{textAlign: "center"}}>
                                                        {t('reports.status.markAs')}
                                                    </Grid>
                                                    <Grid item xs={3} style={{textAlign: "center"}}>
                                                        <Button onClick={() => this.handleChangeStatus(0)} variant="contained" color="success">{t('reports.status.unread')}</Button>
                                                    </Grid>
                                                    <Grid item xs={3} style={{textAlign: "center"}}>
                                                        <Button onClick={() => this.handleChangeStatus(1)} variant="contained" color="warning">{t('reports.status.inProgress')}</Button>
                                                    </Grid>
                                                    <Grid item xs={3} style={{textAlign: "center"}}>
                                                        <Button onClick={() => this.handleChangeStatus(2)} variant="contained" color="error">{t('reports.status.closed')}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Grid container   spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <Paper className='paper-container'>
                                        <Grid item xs={12} md={12} style={{height: '81vh'}}>
                                            <div className='bo-data-table-content' style={{height: '100%'}}>
                                                {this.state.loading && <Loading />}
                                                <div style={{overflowY: 'scroll', height: '92%'}}>
                                                    <InitList
                                                        questionReportsFormRow={this.state.questionReports}
                                                        idSelected={this.state.idSelected}
                                                        onClick={this.handleSelectedRow}
                                                    />
                                                </div>
                                                <div>
                                                    <TablePagination
                                                        component='div'
                                                        rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                                        count={this.state.totalRows}
                                                        page={this.state.paginationPage}
                                                        rowsPerPage={this.state.perPage}
                                                        labelRowsPerPage={t('general.utils.rows_per_page')}
                                                        showFirstButton
                                                        showLastButton
                                                        onPageChange={this.handlePageChange}
                                                        onRowsPerPageChange={this.handlePerRowsChange}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Paper className='paper-form-container'>
                                        {this.state.selectedRow &&
                                            <QuestionReportForm
                                                selectedRow={this.state.selectedRow}
                                                statusReport={this.state.statusReport}
                                                callbackChangeStatus={this.callbackChangeStatus}
                                                idSelected={this.state.idSelected} />}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
            </article>
        }
    }
)

function InitList({questionReportsFormRow, onClick, idSelected}) {
    return <List>
        {
            questionReportsFormRow.map(questionReportData => {
                return <ListItemButton
                    selected={questionReportData.id === idSelected}
                    onClick={() => onClick(questionReportData.id)}
                    id={questionReportData.id}
                    key={questionReportData.id}
                    className="list-item-report"
                >
                    <ListItemText
                        primary={<PrimaryListItemText
                            questionReport={questionReportData.questionReport} />}
                        secondary={<SecondaryListItemText
                            questionReport={questionReportData.questionReport} />}
                        secondaryTypographyProps={{component: "div"}}
                    />
                </ListItemButton>
            })
        }
    </List>
}

function PrimaryListItemText({questionReport}) {
    const {t} = useTranslation()
    const allHistory = questionReport.reportHistories.filter((history) => history.user !== "System")
    const etat = (questionReport.status === 2) ? "error": (questionReport.status === 1) ? "warning" : "success"
    return <>
        <div style={{display: 'flex'}}>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: "5%"
            }}>
                <Brightness1Icon sx={{ fontSize: 22 }} color={etat} />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: "90%"
            }}>
                <div><label className="list-report-label"><b>{t('reports.label')} #{questionReport.id}</b></label></div>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                <Tooltip title={t('reports.numberComment',{number: allHistory.length})} placement="bottom"><Chip size='small' label={allHistory.length}/></Tooltip>
            </div>
        </div>
    </>
}

function SecondaryListItemText({questionReport}) {
    const {t} = useTranslation()
    const creationDate = (typeof questionReport.createdAt !== 'undefined') ? moment(
        questionReport.createdAt).format("DD/MM/YYYY") : "-"
    return <div style={{display: 'flex'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    width: "5%"
                }}></div>
                <div style={{
                    display: 'flex',
                    width: "93%"
                }}>
                    <div style={{width: '50%', display: 'inline-block'}}>
                        <div>{t('quiz.pages.informationSheet.listItem.createAt')} <span>{creationDate}</span></div>
                        <div>{t('quiz.pages.informationSheet.listItem.by')} <span>{questionReport.user !== null
                            ? questionReport.user.name + " " + questionReport.user.surname
                            : "-"}</span></div>
                    </div>
                    <div style={{width: '50%', display: 'inline-block', textAlign: 'right'}}>
                        <div>{questionReport.reportReason !== null
                            ? t(questionReport.reportReason.label)
                            : "-"}</div>
                        <div><span>
                            {questionReport.history !== null
                            ? questionReport.history.question !== null ?
                            questionReport.history.question.title : "-"
                            : "-"}
                        </span></div>
                    </div>
                </div>
            </div>
        
}

const QuestionReportForm = withTranslation()(
    class extends React.Component {
        constructor(props) {
            super(props)

            this.state = {
                loading: props.selectedRow,
                questionReport: getEmptyQuestionObject(),
                questionReportId: props.idSelected,
                questionReportAt: null,
                questionReportReason: "",
                questionReportUserId: null,
                questionReportUser: "",
                questionReportUserSurname: "",
                questionReportUserPseudo: "",
                questionReportStatus: null,
                questionReportExplanation: "",
                questionReportQuestionId: null,
                questionReportQuestion: "",
                questionReportQuestionTypeShortName: "",
                questionReportQuestionTypeId: null,
                questionReportAnswersDisplayedId: [],
                questionReportAnswersDisplayed: [],
                questionReportAnswersDisplayedLoading: false,
                questionReportAnswersSelectedId: [],
                questionReportHistoty: [],
                modalShow: false,
                commentAdd: "",
                loadingAddcomment: false,
                user: Session.getSessionUser()
            }
            this.initStateObjects = this.initStateObjects.bind(this)
            this.getAnswersQuestion = this.getAnswersQuestion.bind(this)
            this.handleCommentModal = this.handleCommentModal.bind(this)
            this.handleCommentHideModal = this.handleCommentHideModal.bind(this)
            this.handleCommentChange = this.handleCommentChange.bind(this)
            this.handleCommentAdd = this.handleCommentAdd.bind(this)
        }

        componentDidMount() {
            (async function() {
                if (this.props.idSelected !== null) {
                    this.setState({loading: true})
                    const response = await Api.questionReport.getQuestionReport(this.props.idSelected)
                    const resultObject = Helper.isValidResponse(response)
                    if (resultObject) {
                        this.initStateObjects(resultObject)
                        this.getAnswersQuestion(resultObject)
                    }
                } else {
                    this.setState({questionReport: getEmptyQuestionObject(), loading: false})
                }
            }).bind(this)()
        }

        componentDidUpdate(prevProps) {
            if (this.props.idSelected !== prevProps.idSelected) {
                (async function() {
                    if (this.props.idSelected !== null) {
                        this.setState({loading: true})
                        const response = await Api.questionReport.getQuestionReport(this.props.idSelected)
                        const resultObject = Helper.isValidResponse(response)
                        if (resultObject) {
                            this.initStateObjects(resultObject)
                            this.getAnswersQuestion(resultObject)
                        }
                    }
                }).bind(this)()
            }

            if (this.props.statusReport !== prevProps.statusReport && this.props.statusReport !== null) {
                (async function() {
                    this.setState({loading: true})
                    const response = await Api.questionReport.updateQuestionReport(this.state.questionReportId, {status: this.props.statusReport})
                    const resultObject = Helper.isValidResponse(response)
                    if (resultObject) {
                        const completUser = this.state.user.name + " " + this.state.user.surname + " ("+this.state.user.pseudo+")"
                        const statusIndic = (resultObject.status === 2) ? "closed" : (resultObject.status === 1) ? "in progress" : "unread"
                        const comment = 'Set status to "'+statusIndic+'"'
                        const responseHistory = await Api.reportHistory.createReportHistory(this.state.questionReportId, completUser, "Update Status", comment)
                        if (responseHistory.status === Constants.HTTP_CREATED) {
                            this.setState(prev => {
                                const reportHistories = prev.questionReportHistoty
                                reportHistories.push(responseHistory.data)
                                return {loading: false, questionReportStatus: resultObject.status, questionReportHistoty: reportHistories}
                            })
                            toast.success(
                                this.props.t('reports.statusChange'),
                                Helper.getToastOptions()
                            );
                            this.props.callbackChangeStatus()
                        }
                    }
                }).bind(this)()
            }
        }

        handleCommentChange(e) {
            this.setState({commentAdd: e.target.value})
        }

        async handleCommentAdd() {
            if (this.state.commentAdd !== "") {
                this.setState({loadingAddcomment: true})
                const completUser = this.state.user.name + " " + this.state.user.surname + " ("+this.state.user.pseudo+")"
                const response = await Api.reportHistory.createReportHistory(this.state.questionReportId, completUser, "Comment", this.state.commentAdd)
                if (response.status === Constants.HTTP_CREATED) {
                    this.setState(prev => {
                        const reportHistories = prev.questionReportHistoty
                        reportHistories.push(response.data)
                        return {loadingAddcomment: false, questionReportHistoty: reportHistories, commentAdd: ""}
                    })
                    this.handleCommentHideModal()
                    this.props.callbackChangeStatus()
                }
            } else {
                toast.error(
                    this.props.t('reports.modalHistory.emptyComment'),
                    Helper.getToastOptions()
                );
            }
        }

        handleCommentHideModal() {
            this.setState({modalShow: false})
        }

        handleCommentModal() {
            this.setState({modalShow: true})
        }

        initStateObjects(resultObject) {
            const creationDate = (typeof resultObject.createdAt !== 'undefined')
                ? resultObject.createdAt
                : null
            const idAnswersSelected = (resultObject.history.answers !== null && resultObject.history.answers !== "")
                ? resultObject.history.answers.split(",")
                : []
            const idAnswersDisplayed = (resultObject.history.answersDisplayed !== null && resultObject.history.answersDisplayed !== "")
                ? resultObject.history.answersDisplayed.split(",")
                : []
            const message = (resultObject.explanation === null) ? "":resultObject.explanation
            this.setState({
                questionReport: cleanupQuestionObject(resultObject),
                questionReportId: resultObject.id,
                questionReportAt: creationDate,
                questionReportReason: resultObject.reportReason.label,
                questionReportUserId: resultObject.user.id,
                questionReportUser: resultObject.user.name,
                questionReportUserSurname: resultObject.user.surname,
                questionReportUserPseudo: resultObject.user.pseudo,
                questionReportStatus: resultObject.status,
                questionReportExplanation: message,
                questionReportQuestionId: resultObject.history.question.id,
                questionReportQuestion: resultObject.history.question.question,
                questionReportQuestionTypeShortName: resultObject.history.question.questionType.shortName,
                questionReportQuestionTypeId: resultObject.history.question.questionType.id,
                questionReportAnswersDisplayedId: idAnswersDisplayed,
                questionReportAnswersSelectedId: idAnswersSelected,
                questionReportHistoty: resultObject.reportHistories,
                loading: false
            })
        }

        async getAnswersQuestion(resultObject) {
            if (QUESTION_TYPES.MCQ === resultObject.history.question.questionType.shortName) {
                this.setState({questionReportAnswersDisplayedLoading: true})
                const response = await Api.mcqAnswer.getMcqAnswersForQuestion(resultObject.history.question.id)
                if (response?.status === Constants.HTTP_OK) {
                    const list = []
                    for (let mcqanswer of response.data) {
                        list.push({
                            id: mcqanswer.id,
                            text: mcqanswer.text,
                            imageLink: mcqanswer.imageLink,
                            correct: mcqanswer.correct,
                            imageOnly: mcqanswer.imageOnly,
                            position: null
                        })
                    }
                    this.setState({questionReportAnswersDisplayedLoading: false, questionReportAnswersDisplayed: list})
                } else {
                    this.setState({questionReportAnswersDisplayedLoading: false})
                }
            }

            if (QUESTION_TYPES.Gaps === resultObject.history.question.questionType.shortName) {
                this.setState({questionReportAnswersDisplayedLoading: true})
                const response = await Api.gapsAnswer.getGapsAnswersForQuestion(resultObject.history.question.id)
                if (response?.status === Constants.HTTP_OK) {
                    const list = []
                    for (let gapsanswer of response.data) {
                        list.push({
                            id: gapsanswer.id,
                            text: gapsanswer.text,
                            imageLink: null,
                            correct: false,
                            imageOnly: false,
                            position: gapsanswer.position
                        })
                    }
                    this.setState({questionReportAnswersDisplayedLoading: false, questionReportAnswersDisplayed: list})
                } else {
                    this.setState({questionReportAnswersDisplayedLoading: false})
                }
            }
        }

        render() {
            const {t} = this.props
            let dateDiff = null;
            let dateDiffLabel = ""
            if (this.state.questionReportAt !== null) {
                dateDiff = moment().diff(moment(this.state.questionReportAt),"minutes")
                dateDiffLabel = "reports.form.minutes"
                if (dateDiff > 60) {
                    dateDiff = moment().diff(moment(this.state.questionReportAt),"hours")
                    dateDiffLabel = "reports.form.hours"
                    if (dateDiff > 24) {
                        dateDiff = moment().diff(moment(this.state.questionReportAt),"days")
                        dateDiffLabel = "reports.form.days"
                        if (dateDiff > 30) {
                            dateDiff = moment().diff(moment(this.state.questionReportAt),"months")
                            dateDiffLabel = "reports.form.months"
                            if (dateDiff > 12) {
                                dateDiff = moment().diff(moment(this.state.questionReportAt),"years")
                                dateDiffLabel = "reports.form.years"
                            }
                        }
                    }
                }
            }
            const allHistory = this.state.questionReportHistoty.filter((history) => history.user !== "System")

            return <article className='question-report-form'>
                <div className='question-report-form-content' style={{height: "100%"}}>
                    {this.state.loading && <Loading />}

                    <Modal
                        title={t('reports.modalHistory.title')}
                        hide={this.handleCommentHideModal}
                        isShowing={this.state.modalShow}
                    >
                        {this.state.loadingAddcomment && <Loading />}
                        <Grid container paddingBottom={2}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('reports.modalHistory.date')}</TableCell>
                                                <TableCell>{t('reports.modalHistory.user')}</TableCell>
                                                <TableCell>{t('reports.modalHistory.action')}</TableCell>
                                                <TableCell>{t('reports.modalHistory.comment')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.questionReportHistoty.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell> {moment(row.creationDate).format("DD/MM/YYYY HH:mm")}</TableCell>
                                                    <TableCell>{row.user}</TableCell>
                                                    <TableCell>{row.action}</TableCell>
                                                    <TableCell>{row.comment}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container paddingBottom={2} paddingTop={2} spacing={2}>
                            <Grid item xs={9}>
                                <TextField value={this.state.commentAdd} onChange={this.handleCommentChange} size="small" fullWidth id="outlined-basic" label={t('reports.modalHistory.comment')} variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <Button onClick={this.handleCommentAdd} fullWidth style={{height: "100%"}} size="small" variant="outlined">{t('reports.modalHistory.add')}</Button>
                            </Grid>
                        </Grid>
                    </Modal>

                    <div style={{padding: "15px 35px 35px 35px"}}>
                        <div style={{paddingBottom: "22px"}}>
                            <Grid container paddingBottom={2}>
                                <Grid item xs={11}>
                                    <b className="title-question-form">{t('reports.label')} </b>
                                </Grid>
                                <Grid item xs={1}>
                                    <div style={{position: "absolute", right: "25px", cursor: "pointer"}}>
                                        <Tooltip title={t('reports.showComment')} placement="bottom-start">
                                            <Badge badgeContent={allHistory.length} color="primary" onClick={() => this.handleCommentModal()}>
                                                <CommentIcon color="primary" />
                                            </Badge>
                                        </Tooltip>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{padding: "0 15px"}}>
                                <Grid item xs={12} md={6}>
                                    <div style={{display: "flex"}}>
                                        <div style={{paddingRight: "20px"}}>
                                            <div style={{paddingBottom: "10px"}}><b>{t('reports.form.createDate')} :</b></div>
                                            <div><b>{t('reports.form.user')} :</b></div>
                                        </div>
                                        <div style={{textAlign: "right", minWidth: "220px"}}>
                                            <div style={{paddingBottom: "10px"}}>{this.state.questionReportAt !== null ? moment(
                                                this.state.questionReportAt).
                                                format("DD/MM/YYYY HH:mm") + " ("+t('reports.form.dayPast',{delay: (dateDiff+" "+t(dateDiffLabel))})+")" : "-"}</div>
                                            <div>
                                                {this.state.questionReportUserId !== null ?
                                                <NavLink
                                                    target="_blank"
                                                    to={Routing.bo_users+"?id="+this.state.questionReportUserId}
                                                    className="bo-data-table_clickable">
                                                    {this.state.questionReportUser !== ""
                                                    ? this.state.questionReportUser
                                                    : "-"}
                                                    {this.state.questionReportUserSurname !== ""
                                                    ? " " + this.state.questionReportUserSurname
                                                    : ""}
                                                    {this.state.questionReportUserPseudo !== ""
                                                    ? " ("+this.state.questionReportUserPseudo + ")"
                                                    : ""}
                                                </NavLink>
                                                :
                                                "-"}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div style={{display: "flex"}}>
                                        <div style={{paddingRight: "20px"}}>
                                            <div style={{paddingBottom: "10px"}}><b>{t('reports.form.reason')} :</b></div>
                                            <div><b>{t('reports.form.status')} :</b></div>
                                        </div>
                                        <div style={{textAlign: "right", minWidth: "220px"}}>
                                            <div style={{paddingBottom: "10px"}}>{this.state.questionReportReason !== "" ?
                                                t(this.state.questionReportReason) : "-"}</div>
                                            <div><b>{(this.state.questionReportStatus !== null)
                                                ? (this.state.questionReportStatus === 2)
                                                    ? <span style={{color: "#e74c3c"}}>{t('reports.status.closed')}</span>
                                                    : (this.state.questionReportStatus === 1)
                                                        ? <span style={{color: "#fba161"}}>{t('reports.status.inProgress')}</span>
                                                        : <span style={{color: "#86c965"}}>{t('reports.status.unread')}</span>
                                                : "-"}</b></div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div style={{padding: "25px 0"}}>
                                        <CssTextField fullWidth
                                                    type='text' value={this.state.questionReportExplanation}
                                                    name='explanation'
                                                    title={t('reports.form.message')}
                                                    variant='outlined'
                                                    label={t('reports.form.message')}
                                                    multiline
                                                    disabled
                                                    rows={8}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <Divider variant='middle' />
                        <div style={{padding: "25px 0"}} className="question-report-answers-content">
                            {this.state.questionReportAnswersDisplayedLoading && <Loading />}
                            <Grid container>
                                <Grid item xs={12}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <div><b className="title-question-form">{t('reports.form.reportedQuestion')}</b></div>
                                        <div style={{paddingLeft: 10}}><NavLink
                                            style={{display: "flex"}}
                                            target="_blank"
                                            to={setRouteParameters(Routing.bo_question_edit, {
                                                id: this.state.questionReportQuestionId,
                                                questionType: this.state.questionReportQuestionTypeId
                                            })}
                                            title={t('reports.form.titleLinkQuestion')}
                                            className="bo-data-table_clickable">
                                            <OpenInNewIcon />
                                        </NavLink></div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} textAlign={"center"} padding={2} paddingBottom={3}>
                                    <span style={{fontWeight: 500}} className="title-question-form">{this.state.questionReportQuestion}</span>
                                </Grid>
                                <Grid item xs={12} textAlign={"center"}>
                                    <Grid container>
                                    {
                                        this.state.questionReportAnswersDisplayed
                                        .filter(element => this.state.questionReportAnswersDisplayedId.includes(element.id.toString()))
                                        .map(el => {
                                            const bgColor = this.state.questionReportAnswersSelectedId.includes(el.id.toString()) ? "#8dd6ff":"#b8b8b8"
                                            return <Grid item xs={6} padding={2} key={el.id}>
                                                <div className="response-single" style={{
                                                    borderColor: bgColor, color: bgColor}}>
                                                    {
                                                        (el.imageOnly) ?
                                                            <div style={{height: 50}}>
                                                                <img style={{height: "100%", width: "auto", objectFit: "contain"}}
                                                                src={el.imageLink} alt={el.text} title={el.text}loading='lazy' />
                                                            </div>
                                                        :   <span className="respone-question-form">{el.text}</span>
                                                    }
                                                    <span style={{position: "absolute", top: 0, left: 0}}>
                                                        {
                                                            (el.correct) ?
                                                            <CheckBoxIcon color="success"/>
                                                            : <CancelIcon color="error" />
                                                        }
                                                    </span>
                                                </div>
                                            </Grid>
                                        })
                                    }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </article>
        }
    }
)

const QuestionReportFilters = withTranslation()(
    class extends React.Component {
        static defaultProps = {}

        constructor(props) {
            super(props)
            this.state = {
                questionReportFilterStatus: "",
                hasFilters: false,
                loading: false,
                unreadStatus: false,
                inProgressStatus: false,
                closedStatus: false
            }

            this.handleFilterSubmit = this.handleFilterSubmit.bind(this)
            this.updateButtonState = this.updateButtonState.bind(this)
            this.getFilters = this.getFilters.bind(this)
            this.handleUnreadStatus = this.handleUnreadStatus.bind(this)
            this.handleInProgressStatus = this.handleInProgressStatus.bind(this)
            this.handleClosedStatus = this.handleClosedStatus.bind(this)
        }

        handleUnreadStatus() {
            this.setState(prev => {
                return {unreadStatus: !prev.unreadStatus}
            })
        }

        handleInProgressStatus() {
            this.setState(prev => {
                return {inProgressStatus: !prev.inProgressStatus}
            })
        }

        handleClosedStatus() {
            this.setState(prev => {
                return {closedStatus: !prev.closedStatus}
            })
        }

        getFilters() {
            let filters = {}

            // retrieves typed text
            if (this.state.unreadStatus || this.state.inProgressStatus || this.state.closedStatus) {
                const arrayStatus = []
                if (this.state.unreadStatus) {
                    arrayStatus.push(0)
                }
                if (this.state.inProgressStatus) {
                    arrayStatus.push(1)
                }
                if (this.state.closedStatus) {
                    arrayStatus.push(2)
                }
                filters.questionReportFilterStatus = arrayStatus
            }

            this.setState(() => {
                return {hasFilters: Object.keys(filters).length !== 0}
            })

            return filters
        }

        handleFilterSubmit() {
            this.props.onFilter(this.getFilters, this.updateButtonState)
        }

        updateButtonState(isLoading = false) {
            this.setState({loading: isLoading})
        }

        render() {
            const {t} = this.props
            return <div className='bo-data-filters input-text-filters' style={{alignItems: "center"}}>
                <div style={{display: 'flex'}}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        width: "4%",
                        paddingLeft: "15px"
                    }}>
                        <Brightness1Icon color="disabled" />
                    </div>
                    <div>
                        <div><label><b>{t('reports.all')} ({this.props.count})</b></label></div>
                    </div>
                </div>
                <div style={{textAlign: "right"}}>
                    <ActionsMenu
                        unreadStatus={this.state.unreadStatus}
                        inProgressStatus={this.state.inProgressStatus}
                        closedStatus={this.state.closedStatus}
                        handleUnreadStatus={this.handleUnreadStatus}
                        handleInProgressStatus={this.handleInProgressStatus}
                        handleClosedStatus={this.handleClosedStatus}
                        handleFilterSubmit={this.handleFilterSubmit}
                    />
                </div>
                <Divider sx={{height: 28, m: 0.5}} orientation='vertical' />
            </div>
        }
    }
)

function ActionsMenu({...props}) {
    const {t} = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <FontAwesomeIcon icon='fas fa-filter' />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem style={{padding: 0, paddingRight: 10}} onClick={() => props.handleUnreadStatus()}>
                    <Checkbox checked={props.unreadStatus} /> {t('reports.status.unread')}
                </MenuItem>
                <MenuItem style={{padding: 0, paddingRight: 10}}  onClick={() => props.handleInProgressStatus()}>
                    <Checkbox checked={props.inProgressStatus} /> {t('reports.status.inProgress')}
                </MenuItem>
                <MenuItem style={{padding: 0, paddingRight: 10}}  onClick={() => props.handleClosedStatus()}>
                    <Checkbox checked={props.closedStatus} /> {t('reports.status.closed')}
                </MenuItem>
                <Divider />
                <MenuItem style={{padding: "0px 10px"}}  onClick={() => {}}>
                    <Button onClick={() => props.handleFilterSubmit()} variant="contained" color="success">{t('reports.filter')}</Button>
                </MenuItem>
            </Menu>
        </div>
    );
}

/* ================================== GLOBAL FUNCTIONS ================================== */

/**
 * Call information sheet Api and update table state with the result of the api call
 *
 * @param page {int}
 * @param filters {object|null}
 * @param callback {Function|null}
 * @return {Promise<void>}
 */
async function getQuestionReportLines(page = 1, filters = null, callback = null) {
    Helper.getDataTableLines.bind(this)(
        Api.questionReport.getQuestionsReport,
        {"order[id]": "desc", "per_page": this.state.perPage},
        "questionReports",
        FormatRow,
        page,
        filters,
        callback
    )
}

/**
 * Create a jsx object that will be interpreted by the DataTable
 *
 * @param questionReport {object}
 * @return {Promise<{id, title: *}>}
 */
async function FormatRow(questionReport) {
    return {
        id: questionReport.id,
        questionReport: questionReport
    }
}

function getEmptyQuestionObject() {
    return {
        id: null,
        user: [],
        history: [],
        reportReason: [],
        explanation: "",
        createdAt: null,
        updatedAt: null,
        status: 0
    }
}

function cleanupQuestionObject(object) {
    const convertObject = {...object}
    delete convertObject["@id"]
    delete convertObject["@type"]
    delete convertObject["@context"]

    return convertObject
}