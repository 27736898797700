/* ------------------------ QUESTIONS TYPES -------------------------- */

export const QUESTION_TYPES = {
    MCQ: 'mcq',
    Gaps: 'gaps',
    TrueFalse: 'trueFalse'
}

/* ------------------------------ MISCELLANEOUS -------------------------------- */

export const MAX_GAPS_ANSWERS = 8
export const DEFAULT_PER_PAGE_NUMBER = 15
export const COOKIE_PER_PAGE_NUMBER = '_itemsPerpage'
export const COOKIE_NOTIFICATION = '_lsn'
export const COOKIE_NOTIFICATION_TOOLTIP = '_lsn_info'
export const SESSION_EXPIRATION_DAYS = 30;

/* ============================ EXPORTS ============================ */
