import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './quickLinks.css';
import { Link } from 'react-router-dom';
import { routes as Routing } from '../../../services/RoutesHelper';
import { Constants, Roles, Session } from 'scg.common-library';
import { useTranslation } from 'react-i18next';

export default function QuickLinks() {
  const { t } = useTranslation();

  const isSuperAdmin = Roles.isGranted(
    Session.getSessionUser()?.roles[0],
    Constants.ROLE_SUPER_ADMIN
  );

  return (
    <article className="quickLinks">
      <section className="quickLinks-user">
        <div className="links-container">
          <span>
            {t('dashboard.quickLinks.usersTitle')}
            <FontAwesomeIcon icon="fas fa-circle-chevron-down" />
          </span>
        </div>
        <div className="user-links">
          <Link
            to={Routing.bo_users}
            title={t('dashboard.quickLinks.addLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-plus" />
            <span>{t('dashboard.quickLinks.addLink.name')}</span>
          </Link>
          <Link
            to={Routing.bo_users}
            title={t('dashboard.quickLinks.listLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-list" />
            <span>{t('dashboard.quickLinks.listLink.name')}</span>
          </Link>
        </div>
      </section>
      <section
        className="quickLinks-quiz"
        style={{ display: isSuperAdmin === true ? 'bloc' : 'none' }}
      >
        <div className="links-container">
          <span>
            {t('dashboard.quickLinks.quizTitle')}
            <FontAwesomeIcon icon="fas fa-circle-chevron-down" />
          </span>
        </div>
        <div className="quiz-links">
          <Link
            to={Routing.bo_question}
            title={t('dashboard.quickLinks.questionsLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-clipboard-question" />
            <span>{t('dashboard.quickLinks.questionsLink.name')}</span>
          </Link>
          <Link
              to={Routing.bo_informationSheets}
              title={t('dashboard.quickLinks.informationSheetLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-sheet-plastic" />
            <span>{t('dashboard.quickLinks.informationSheetLink.name')}</span>
          </Link>
          <Link
            to={Routing.bo_category}
            title={t('dashboard.quickLinks.categoriesLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-folder" />
            <span>{t('dashboard.quickLinks.categoriesLink.name')}</span>
          </Link>
          <Link
              to={Routing.bo_questionType}
              title={t('dashboard.quickLinks.typesLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-layer-group" />
            <span>{t('dashboard.quickLinks.typesLink.name')}</span>
          </Link>
        </div>
      </section>

      <section
        className="quickLinks-society-stats"
        style={{ display: isSuperAdmin === true ? 'none' : 'bloc' }}
      >
        <div className="links-container">
          <span>
            {t('dashboard.quickLinks.statesLinkTitle')}
            <FontAwesomeIcon icon="fas fa-circle-chevron-down" />
          </span>
        </div>
        <div className="society-stats-links">
          <Link
            to={Routing.bo_society_stats}
            title={t('dashboard.quickLinks.statsLink.title')}
          >
            <FontAwesomeIcon icon="fa-solid fa-eye" />
            <span>{t('dashboard.quickLinks.statsLink.name')}</span>
          </Link>
          <Link
            to={Routing.bo_society_details}
            title={t('dashboard.quickLinks.subscriptionLink.title')}
          >
            <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
            <span>{t('dashboard.quickLinks.subscriptionLink.name')}</span>
          </Link>
        </div>
      </section>
      <section
        className="quickLinks-challenge"
        style={{ display: isSuperAdmin === true ? 'bloc' : 'none' }}
      >
        <div className="links-container">
          <span>
            {t('dashboard.quickLinks.challengesTitle')}
            <FontAwesomeIcon icon="fas fa-circle-chevron-down" />
          </span>
        </div>
        <div className="challenge-links">
          <Link
            to={Routing.bo_challenge}
            title={t('dashboard.quickLinks.manageLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-list-check" />
            <span>{t('dashboard.quickLinks.manageLink.name')}</span>
          </Link>
        </div>
      </section>
    </article>
  );
}
