import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import Dashboard from '../components/home/Dashboard'
import {LoginPage, LogoutPage} from '../components/login/LoginPage'
import {Session, Roles, Constants} from 'scg.common-library'
import {routes as Routing} from './RoutesHelper'
import ErrorPage from '../components/general/Error/ErrorPage'
import Users from '../components/users/Users'
import Societies from '../components/societies/Societies'
import SocietyDetails from '../components/societies/SocietyDetails'
import ProfilPage from '../components/profil/ProfilPage'
import ChangelogPage from '../components/changelog/ChangelogPage'
import {
    QuestionPage,
    CategoryPage,
    QuestionEditPage,
    QuestionTypePage,
    InformationSheetsPage
} from '../components/quiz/Quiz'
import SocietyStats from '../components/stats/SocietyStats'
import {useTranslation} from 'react-i18next'
import Reports from '../components/reports/Reports'

function getUserIdFromLocation(location) {
    const locationSplit = location.search.split('?')
    if (locationSplit.length > 1) {
        const locationSplitAnd = locationSplit[1].split('&')
        for (let variable of locationSplitAnd) {
            const variableSplit = variable.split('=')
            if (variableSplit.length > 1) {
                if(variableSplit[0] === "id") {
                    if(variableSplit[1] !== "" && variableSplit[1] !== null) {
                        return parseInt(variableSplit[1])
                    }
                }
            }
        }
    }
    return null
}

function getQuestionIdFromLocation(location) {
    const locationSplit = location.pathname.split('/')
    if (locationSplit[locationSplit.length - 2] === 'new') {
        return 'new'
    }
    return locationSplit[locationSplit.length - 2]
}

function getQuestionTypeFromLocation(location) {
    const locationSplit = location.pathname.split('/')
    if (locationSplit[locationSplit.length - 2] === 'new') {
        return locationSplit[locationSplit.length - 1]
    }
    return locationSplit[locationSplit.length - 1]
}

function AppRoutes() {
    const {t} = useTranslation()
    const location = useLocation()

    return (
        <Routes>
            <Route path={Routing.app_home} element={<Navigate to={Routing.bo_dashboard} replace />}/>
            <Route path={Routing.app_login} element={<LoginPage />} />
            <Route path={Routing.app_logout} element={<LogoutPage />} />
            <Route path={Routing.bo_dashboard} element={<SecureRoute minRole={Constants.ROLE_ADMIN} component={Dashboard} />} />
            <Route path={Routing.bo_users} element={<SecureRoute minRole={Constants.ROLE_ADMIN} component={Users} selectedUser={getUserIdFromLocation(location)} />} />
            <Route path={Routing.bo_society} element={<SecureRoute minRole={Constants.ROLE_SUPER_ADMIN} component={Societies} />} />
            <Route path={Routing.bo_society_details} element={<SecureRoute minRole={Constants.ROLE_ADMIN} component={SocietyDetails} />} />
            <Route path={Routing.bo_society_stats} element={<SecureRoute minRole={Constants.ROLE_ADMIN} component={SocietyStats} />} />
            <Route path={Routing.bo_question} element={<SecureRoute minRole={Constants.ROLE_SUPER_ADMIN} component={QuestionPage} />} exact />
            <Route path={Routing.bo_question_edit} element={<SecureRoute minRole={Constants.ROLE_SUPER_ADMIN} component={QuestionEditPage}
                                         questionId={getQuestionIdFromLocation(location)}
                                         questionType={getQuestionTypeFromLocation(location)} />} />
            <Route path={Routing.bo_category} element={<SecureRoute minRole={Constants.ROLE_SUPER_ADMIN} component={CategoryPage} />} exact />
            <Route path={Routing.bo_questionType} element={<SecureRoute minRole={Constants.ROLE_SUPER_ADMIN} component={QuestionTypePage} />} exact />
            <Route path={Routing.bo_informationSheets} element={<SecureRoute minRole={Constants.ROLE_SUPER_ADMIN} component={InformationSheetsPage} />} exact />
            <Route path={Routing.bo_report} element={<SecureRoute minRole={Constants.ROLE_SUPER_ADMIN} component={Reports} />} />
            <Route path={Routing.bo_profil} element={<SecureRoute minRole={Constants.ROLE_ADMIN} />} Component={ProfilPage} />
            <Route path={Routing.bo_changelog} element={<SecureRoute minRole={Constants.ROLE_ADMIN} />} Component={ChangelogPage} />
            <Route path='*' element={<ErrorPage code='404' title={t('services.appRoutes.404.title')} message={t('services.appRoutes.404.message')} />} />
        </Routes>
    )
}

function SecureRoute({component: Component, minRole, ...props}) {
    const {t} = useTranslation()
    const user = Session.getUser()

    if (!Session.isLoggedIn() || user === null) {
        return <Navigate to={Routing.app_login} replace />
    }

    if (minRole != null && !Roles.isGranted(user?.roles[0], minRole)) {
        return (
            <ErrorPage
                code='403'
                title={t('services.appRoutes.403.title')}
                message={t('services.appRoutes.403.message')}
            />
        )
    }

    return <Component {...props} />
}

export default AppRoutes
