import React, {Component, useState} from 'react'
import {Api, Constants} from 'scg.common-library'
import {TableWithFiltersSkeleton} from '../../../services/LoadingHelper'
import DataTable from 'react-data-table-component'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {InputField} from '../../general/form/Input'
import Helper from '../../../services/Helper'
import {
    routes as Routing,
    setRouteParameters
} from '../../../services/RoutesHelper'
import _ from 'lodash'
import {Navigate, NavLink} from 'react-router-dom'
import SelectWithData from '../../general/form/SelectWithData'
import ActiveSwitch from '../../general/form/ActiveSwitch'
import Modal from '../../general/form/Modal'
import {useTranslation, withTranslation} from 'react-i18next'
import {Alert, IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import {Loading} from "../../general/form/Loading";

import './questionList.css'

const QuestionList = withTranslation()(
    class extends Component {
        static defaultProps = {}

        constructor(props) {
            super(props)

            this.state = {
                loading: true,
                filtersLoading: true,
                questions: [],
                totalRows: 0,
                perPage: Helper.getTablePerPageNumber(),
                questionTypes: [],
                questionModalDisplay: false,
                questionToCopy: null,
                questionToCopyModal: false,
                questionToCopyLanguage: null,
                loadingModal: false,
                redirect: false,
                dataCopyIdQuestion: null,
                dataCopyIdQuestionType: null,
                changeTypeModal: false,
                questionInToAction: null,
                loadingModalType: false,
                deleteModal: false,
                loadingDeleteModal: false
            }

            this.handlePerRowsChange = this.handlePerRowsChange.bind(this)
            this.handlePageChange = this.handlePageChange.bind(this)
            this.handleFilter = this.handleFilter.bind(this)
            this.handleModalClose = this.handleModalClose.bind(this)
            this.getQuestionTypesButtons = this.getQuestionTypesButtons.bind(this)
            this.displayModal = this.displayModal.bind(this)
            this.handleActionQuestion = this.handleActionQuestion.bind(this)
            this.handleCopyModalClose = this.handleCopyModalClose.bind(this)
            this.handleCopyQuestion = this.handleCopyQuestion.bind(this)
            this.handleCopyLanguageSelect = this.handleCopyLanguageSelect.bind(this)
            this.handleTypeModalClose = this.handleTypeModalClose.bind(this)
            this.handleChangeTypeQuestion = this.handleChangeTypeQuestion.bind(this)
            this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this)
            this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this)

            this.languageOptions = [{label: this.props.t('tableColumnNames.actions.modal.language'), icon: "fa-language", options: []}]
        }

        componentDidMount() {
            Api.question.getQuestionCount().then((response) => {
                const resultObject = Helper.isValidResponse(response)
                if (resultObject) {
                    this.setState({totalRows: resultObject.count})
                }
            })

            Api.questionType.getQuestionTypes().then((response) => {
                const resultObject = Helper.isValidResponse(response)
                if (resultObject) {
                    this.setState({questionTypes: resultObject})
                }
            })
            getQuestionLines.bind(this)(1)
        }

        handleActionQuestion(type, question = null) {
            switch (type) {
                case 1:
                    this.setState({
                        questionToCopy: question,
                        questionToCopyModal: true,
                        questionToCopyLanguage: null
                    })
                    break
                case 2:
                    this.setState({
                        questionInToAction: question,
                        changeTypeModal: true,
                        questionToCopyType: null
                    })
                    break
                case 3:
                    this.setState({
                        questionInToAction: question,
                        deleteModal: true
                    })
                    break
                default:
                    break
            }
        }

        getFilters() {
            console.debug('getFilters not initialized !')
            return []
        }

        handlePerRowsChange(newPerPage, page) {
            this.setState({perPage: newPerPage, loading: true}, () => {
                Helper.setTablePerPageNumber(newPerPage)
                getQuestionLines.bind(this)(page, this.getFilters())
            })
        }

        handlePageChange(page) {
            this.setState({loading: true}, () => {
                getQuestionLines.bind(this)(page, this.getFilters())
            })
        }

        handleFilter(filterFunctionFromChild, updateButtonStateFunction) {
            this.setState({loading: true})
            updateButtonStateFunction(true)

            this.getFilters = filterFunctionFromChild
            const filters = this.getFilters()

            Api.question.getQuestionCount(Helper.getFiltersUrlParams(filters)).then((response) => {
                const resultObject = Helper.isValidResponse(response)
                if (resultObject) {
                    this.setState({totalRows: resultObject.count})
                }
            })

            getQuestionLines.bind(this)(1, filters, updateButtonStateFunction)
        }

        displayModal() {
            this.setState({questionModalDisplay: true})
        }

        handleDeleteModalClose() {
            this.setState({deleteModal: false})
        }

        async handleDeleteQuestion() {
            this.setState({
                loadingDeleteModal: true
            })
            const responseCopy = await Api.question.deleteQuestion(this.state.questionInToAction.id)
            if (responseCopy.status === Constants.HTTP_NO_CONTENT) {
                this.setState({
                    deleteModal: false,
                    loadingDeleteModal: false
                })
                getQuestionLines.bind(this)(1, this.getFilters())
            } else {
                toast.error(
                    this.props.t('tableColumnNames.actions.modal.errorSave'),
                    Helper.getToastOptions()
                );
                this.setState({
                    loadingDeleteModal: false
                })
            }
        }

        handleTypeModalClose() {
            this.setState({changeTypeModal: false})
        }

        async handleChangeTypeQuestion(idQuestionType) {
            this.setState({
                loadingModalType: true
            })
            const responseCopy = await Api.question.changeTypeQuestion(this.state.questionInToAction.id, idQuestionType)
            if (responseCopy.status === Constants.HTTP_OK) {
                this.setState({
                    redirect: true,
                    dataCopyIdQuestion: responseCopy.data.question.id,
                    dataCopyIdQuestionType: responseCopy.data.questionType.id
                })
            } else {
                toast.error(
                    this.props.t('tableColumnNames.actions.modal.errorSave'),
                    Helper.getToastOptions()
                );
                this.setState({
                    loadingModalType: false
                })
            }
        }

        handleCopyModalClose() {
            this.setState({questionToCopyModal: false})
        }

        handleCopyLanguageSelect(selectedOptions) {
            const id = selectedOptions?.value ?? null
            this.setState({
                questionToCopyLanguage: id
            })
        }

        async handleCopyQuestion() {
            if (this.state.questionToCopyLanguage === null) {
                toast.error(
                    this.props.t('tableColumnNames.actions.modal.languagePlaceholder'),
                    Helper.getToastOptions()
                );
            } else {
                if (this.state.questionToCopyLanguage === this.state.questionToCopy.language.id) {
                    toast.error(
                        this.props.t('tableColumnNames.actions.modal.otherLanguage'),
                        Helper.getToastOptions()
                    );
                } else {
                    this.setState({
                        loadingModal: true
                    })
                    const responseCopy = await Api.question.translateQuestion(this.state.questionToCopy.id, this.state.questionToCopyLanguage)
                    if (responseCopy.status === Constants.HTTP_OK) {
                        this.setState({
                            redirect: true,
                            dataCopyIdQuestion: responseCopy.data.question.id,
                            dataCopyIdQuestionType: responseCopy.data.questionType.id
                        })
                    } else {
                        toast.error(
                            this.props.t('tableColumnNames.actions.modal.errorSave'),
                            Helper.getToastOptions()
                        );
                        this.setState({
                            loadingModal: false
                        })
                    }
                }
            }
        }

        handleModalClose(status) {
            console.log(status)
            this.setState({questionModalDisplay: false})
        }

        getQuestionTypesButtons() {
            let component = []
            this.state.questionTypes.forEach((qType) => {
                const disabledClass = !qType.active ? 'disabled' : ''
                component.push(
                    <NavLink
                        to={setRouteParameters(Routing.bo_question_edit, {
                            id: 'new',
                            questionType: qType.id
                        })}
                        className={`questiontype-button ${disabledClass}`}
                        key={`questionType-${qType.id}`}
                        title={`${this.props.t(
                            'quiz.pages.questions.addQuestionTypeButton.title'
                        )} ${qType.name}`}
                    >
                        <img
                            src={`/img/questionTypes/${qType.shortName}-white.png`}
                            alt={`${qType.shortName}`}
                            height='50px'
                            title={qType.name}
                        />{' '}
                        {qType.name}
                    </NavLink>
                )
            })
            return component
        }

        render() {
            const {t} = this.props

            const columns = [
                {
                    name: t('tableColumnNames.title'),
                    selector: (row) => row.title,
                    sortable: true,
                    width: '14vw'
                },
                {
                    name: t('tableColumnNames.question'),
                    selector: (row) => row.question,
                    sortable: true,
                    width: '30vw'
                },
                {
                    name: t('tableColumnNames.type'),
                    selector: (row) => row.questionType,
                    width: '7vw'
                },
                {
                    name: t('tableColumnNames.category'),
                    selector: (row) => row.category,
                    width: '15vw'
                },
                {
                    name: t('tableColumnNames.level'),
                    selector: (row) => row.level,
                    width: '10vw'
                },
                {
                    name: t('tableColumnNames.language'),
                    selector: (row) => row.language,
                    width: '4vw'
                },
                {
                    name: t('tableColumnNames.active'),
                    selector: (row) => row.active,
                    width: '5vw'
                },
                {
                    name: t('tableColumnNames.actions.column'),
                    selector: (row) => row.actions,
                    width: '5vw'
                }
            ]

            if (this.state.redirect && this.state.dataCopyIdQuestion !== null && this.state.dataCopyIdQuestionType !== null){
                return <Navigate to={setRouteParameters(Routing.bo_question_edit, {
                    id: this.state.dataCopyIdQuestion,
                    questionType: this.state.dataCopyIdQuestionType
                })}
                 />
            }

            return (
                <article className='questions'>
                    <section className='questions-title'>
                        <h3>{t('quiz.pages.questions.title')}</h3>
                        <button
                            className={`btn default addButton ${
                                this.state.loading ? 'disabled' : ''
                            }`}
                            title={t('quiz.pages.questions.addQuestionButton.title')}
                            onClick={this.displayModal}
                        >
                            <FontAwesomeIcon icon='fas fa-add' />{' '}
                            {t('quiz.pages.questions.addQuestionButton.name')}
                        </button>

                        <Modal
                            title={t('tableColumnNames.actions.modal.titleDelete')}
                            hide={this.handleDeleteModalClose}
                            isShowing={this.state.deleteModal}
                        >
                            <div>
                                {this.state.loadingDeleteModal && <Loading/>}
                                <div style={{textAlign: "center", paddingBottom: 10}}>
                                    {(this.state.questionInToAction !== null) ? this.state.questionInToAction.title : ""}
                                </div>
                                <div style={{textAlign: "center", paddingBottom: 10}}>
                                    <Alert severity="error" icon={false} style={{maxWidth: 380}}>
                                        <div>
                                            <b>{t('tableColumnNames.actions.modal.deleteTitle')}</b>
                                        </div>
                                        <div>
                                            {t('tableColumnNames.actions.modal.deleteMessage')}
                                        </div>
                                    </Alert>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <input
                                        type='button'
                                        className='btn alert'
                                        value={t('tableColumnNames.actions.modal.yesDelete')}
                                        onClick={this.handleDeleteQuestion}
                                    />
                                    <input
                                        type='button'
                                        className='btn error'
                                        onClick={this.handleDeleteModalClose}
                                        value={t('tableColumnNames.actions.modal.close')}
                                    />
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            title={t('tableColumnNames.actions.modal.titleType')}
                            hide={this.handleTypeModalClose}
                            isShowing={this.state.changeTypeModal}
                        >
                            <div>
                                {this.state.loadingModalType && <Loading/>}
                                <div style={{textAlign: "center", paddingBottom: 10}}>
                                    {(this.state.questionInToAction !== null) ? this.state.questionInToAction.title : ""}
                                </div>
                                <div style={{textAlign: "center", paddingBottom: 10}}>
                                    <Alert severity="warning" icon={false} style={{maxWidth: 340}}>
                                        <div>
                                            {t('tableColumnNames.actions.modal.warningType')}
                                        </div>
                                    </Alert>
                                </div>
                                <div className='questiontype-list'>
                                    {
                                        this.state.questionTypes.map(qType => {
                                            const disabledClass = !qType.active ? 'disabled' : ''
                                            const otherDisabled = (this.state.questionInToAction === null) ?
                                                "" :
                                                (this.state.questionInToAction.questionType.id !== qType.id) ?
                                                    "" : "forceHidden"
                                            return <a
                                                key={qType.id}
                                                className={`questiontype-button ${disabledClass} ${otherDisabled}`}
                                                onClick={() => this.handleChangeTypeQuestion(qType.id)}
                                            >
                                                <img
                                                    src={`/img/questionTypes/${qType.shortName}-white.png`}
                                                    alt={`${qType.shortName}`}
                                                    height='50px'
                                                    title={qType.name}
                                                />{' '}
                                                {qType.name}
                                            </a>
                                        })
                                    }
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            title={t('tableColumnNames.actions.modal.title')}
                            hide={this.handleCopyModalClose}
                            isShowing={this.state.questionToCopyModal}
                        >
                            <div>
                                {this.state.loadingModal && <Loading />}
                                <div style={{textAlign: "center", paddingBottom: 10}}>
                                    { (this.state.questionToCopy !== null) ? this.state.questionToCopy.title : "" }
                                </div>
                                <SelectWithData
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    placeholder={t('tableColumnNames.actions.modal.languagePlaceholder')}
                                    options={this.languageOptions}
                                    onChange={this.handleCopyLanguageSelect}
                                    apiFunction={Api.language.getLanguages}
                                    selectType='Language'
                                    context={this}
                                    selected={this.state.questionToCopyLanguage}
                                />
                                <div style={{textAlign: "center"}}>
                                    <input
                                        type='button'
                                        className='btn default'
                                        value={t('tableColumnNames.actions.modal.next')}
                                        onClick={this.handleCopyQuestion}
                                    />
                                    <input
                                        type='button'
                                        className='btn error'
                                        onClick={this.handleCopyModalClose}
                                        value={t('tableColumnNames.actions.modal.close')}
                                    />
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            title={t('quiz.pages.questions.modal.title')}
                            hide={this.handleModalClose}
                            isShowing={this.state.questionModalDisplay}
                        >
                            <div className='questiontype-list'>
                                {this.getQuestionTypesButtons()}
                            </div>
                        </Modal>
                    </section>
                    <section className='bo-data-table card'>
                        {(this.state.loading || this.state.filtersLoading) &&
                            <TableWithFiltersSkeleton linesCount={18} filtersCount={5} linesSize='large'
                                                      position="absolute"/>}
                        <>
                            <QuestionFilters onFilter={this.handleFilter}
                                             onLoadingEnd={() => this.setState({filtersLoading: false})} />
                                <div className='bo-data-table-content'>
                                    <DataTable
                                        dense={true}
                                        columns={columns}
                                        data={this.state.questions}
                                        fixedHeader
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={this.state.totalRows}
                                        paginationPerPage={this.state.perPage}
                                        onChangeRowsPerPage={this.handlePerRowsChange}
                                        onChangePage={this.handlePageChange}
                                        paginationComponentOptions={{
                                            rowsPerPageText: t('general.utils.rows_per_page')
                                        }}
                                        responsive
                                        className='questionsTable'
                                        subHeaderAlign='center'
                                        subHeaderWrap
                                    />
                                </div>
                            </>
                    </section>
                </article>
            )
        }
    }
)

const QuestionFilters = withTranslation()(
    class extends Component {
        static defaultProps = {
            onLoadingEnd: () => {}
        }

        constructor(props) {
            super(props)
            this.state = {
                questionFilterText: '',
                optionsTypes: [],
                optionsCategories: [],
                optionsLevels: [],
                optionsLanguages: [],
                hasFilters: false,
                loading: false,
                resetOptions: false,
            }

            this.loadingComponents = {
                questionType: false,
                category: false,
                level: false,
                language: false
            }

            this.typeOptions = [
                {
                    label: props.t('tableColumnNames.type'),
                    icon: 'fa-list-check',
                    options: []
                }
            ]
            this.categoryOptions = [
                {
                    label: props.t('tableColumnNames.category'),
                    icon: 'fa-table-list',
                    options: []
                }
            ]
            this.levelOptions = [
                {
                    label: props.t('tableColumnNames.level'),
                    icon: 'fa-stairs',
                    options: []
                }
            ]
            this.languageOptions = [
                {
                    label: props.t('tableColumnNames.language'),
                    icon: 'fa-language',
                    options: []
                }
            ]

            this.handleFilterSubmit = this.handleFilterSubmit.bind(this)
            this.handleClearFilter = this.handleClearFilter.bind(this)
            this.handleTypeSelectChange = this.handleTypeSelectChange.bind(this)
            this.handleCategorySelectChange = this.handleCategorySelectChange.bind(this)
            this.handleLevelSelectChange = this.handleLevelSelectChange.bind(this)
            this.handleLanguageSelectChange = this.handleLanguageSelectChange.bind(this)
            this.updateButtonState = this.updateButtonState.bind(this)
            this.getFilters = this.getFilters.bind(this)
            this._updateLoadingState = this._updateLoadingState.bind(this)

            // this.typeSelector = createRef()
            // this.categorySelector = createRef()
            // this.levelSelector = createRef()
            // this.languageSelector = createRef()
        }

        getFilters() {
            let filters = {}

            // retrieves typed text
            if (this.state.questionFilterText !== '') {
                filters.questionFilterText = this.state.questionFilterText
            }

            // retrieves selected types
            if (this.state.optionsTypes.length !== 0) {
                let questionTypeArray = this.state.optionsTypes
                questionTypeArray = _.map(questionTypeArray, (questionType) => {
                    return questionType.value
                })
                filters.questionTypes = questionTypeArray
            }

            // retrieves selected categories
            if (this.state.optionsCategories.length !== 0) {
                let categoryArray = this.state.optionsCategories
                categoryArray = _.map(categoryArray, (category) => {
                    return category.value
                })
                filters.categories = categoryArray
            }

            // retrieves selected levels
            if (this.state.optionsLevels.length !== 0) {
                let levelArray = this.state.optionsLevels
                levelArray = _.map(levelArray, (level) => {
                    return level.value
                })
                filters.levels = levelArray
            }

            // retrieves selected languages
            if (this.state.optionsLanguages.length !== 0) {
                let languageArray = this.state.optionsLanguages
                languageArray = _.map(languageArray, (language) => {
                    return language.value
                })
                filters.languages = languageArray
            }

            this.setState(() => {
                return {hasFilters: Object.keys(filters).length !== 0}
            })

            return filters
        }

        handleFilterSubmit() {
            this.props.onFilter(this.getFilters, this.updateButtonState)
            this.setState({resetOptions: false})
        }

        handleClearFilter() {
            this.setState(
                {
                    questionFilterText: '',
                    optionsTypes: [],
                    optionsCategories: [],
                    optionsLevels: [],
                    optionsLanguages: [],
                    hasFilters: false,
                    resetOptions: true,
                },
                () => {
                    // if (this.typeSelector.current) this.typeSelector.current.clearOptions();
                    // if (this.categorySelector.current) this.categorySelector.current.clearOptions();
                    // if (this.levelSelector.current) this.levelSelector.current.clearOptions();
                    // if (this.languageSelector.current) this.languageSelector.current.clearOptions();
                    this.handleFilterSubmit()
                }
            )
        }

        updateButtonState(isLoading = false) {
            this.setState({loading: isLoading})
        }

        handleTypeSelectChange(selectedOptions) {
            this.setState({
                optionsTypes: selectedOptions
            })
        }

        handleCategorySelectChange(selectedOptions) {
            this.setState({
                optionsCategories: selectedOptions
            })
        }

        handleLevelSelectChange(selectedOptions) {
            this.setState({
                optionsLevels: selectedOptions
            })
        }

        handleLanguageSelectChange(selectedOptions) {
            this.setState({
                optionsLanguages: selectedOptions
            })
        }

        _updateLoadingState(entry) {
            this.loadingComponents[entry] = true
            if (_.find(this.loadingComponents, o => o === false) === undefined) {
                this.props.onLoadingEnd()
                this.setState({loading: false})
            }
        }

        render() {
            const {t} = this.props

            return <div className='bo-data-filters'>
                <InputField
                    className='questions-text'
                    type='text'
                    name='questionFilterText'
                    value={this.state.questionFilterText}
                    title={t('searchInput.title')}
                    context={this}
                >
                    {t('searchInput.label')}
                </InputField>
                <SelectWithData
                    // ref={this.typeSelector}
                    resetOptions={this.state.resetOptions}
                    className='questions-type-selector'
                    placeholder={t('quiz.pages.questions.filters.questionTypes.placeholder')}
                    options={this.typeOptions}
                    onChange={this.handleTypeSelectChange}
                    apiFunction={Api.questionType.getQuestionTypes}
                    onLoadingEnd={() => this._updateLoadingState('questionType')}
                    context={this}
                />
                <SelectWithData
                    // ref={this.categorySelector}
                    resetOptions={this.state.resetOptions}
                    className='questions-category-selector'
                    placeholder={t('quiz.pages.questions.filters.categories.placeholder')}
                    options={this.categoryOptions}
                    onChange={this.handleCategorySelectChange}
                    apiFunction={Api.category.getCategories}
                    onLoadingEnd={() => this._updateLoadingState('category')}
                    context={this}
                />
                <SelectWithData
                    // ref={this.levelSelector}
                    resetOptions={this.state.resetOptions}
                    className='questions-level-selector'
                    placeholder={t('quiz.pages.questions.filters.levels.placeholder')}
                    options={this.levelOptions}
                    onChange={this.handleLevelSelectChange}
                    apiFunction={Api.level.getLevels}
                    onLoadingEnd={() => this._updateLoadingState('level')}
                    context={this}
                />
                <SelectWithData
                    // ref={this.languageSelector}
                    resetOptions={this.state.resetOptions}
                    className='questions-language-selector'
                    placeholder={t('quiz.pages.questions.filters.languages.placeholder')}
                    options={this.languageOptions}
                    onChange={this.handleLanguageSelectChange}
                    apiFunction={Api.language.getLanguages}
                    onLoadingEnd={() => this._updateLoadingState('language')}
                    selectType='Language' // do not change !
                    context={this}
                />
                <div>
                    <button
                        type='button'
                        className='btn default filterButton'
                        title={t('filterButtonTitles.results')}
                        disabled={this.state.loading}
                        onClick={this.handleFilterSubmit}
                    >
                        <FontAwesomeIcon icon='fas fa-filter' />
                    </button>
                    {this.state.hasFilters && (
                        <button
                            type='button'
                            className='btn warning resetButton'
                            title={t('filterButtonTitles.clear')}
                            onClick={this.handleClearFilter}
                        >
                            <FontAwesomeIcon icon='fas fa-filter-circle-xmark' />
                        </button>
                    )}
                </div>
            </div>
        }
    }
)

/* ================================== EXPORTED COMPONENTS ================================== */
const exported = {
    QuestionList,
    QuestionFilters
}
export default exported

/* ================================== GLOBAL FUNCTIONS ================================== */

/**
 * Call questions Api and update table state with the result of the api call
 *
 * @param page {int}
 * @param filters {object|null}
 * @param callback {Function|null}
 * @return {Promise<void>}
 */
async function getQuestionLines(page = 1, filters = null, callback = null) {
    Helper.getDataTableLines.bind(this)(
        Api.question.getQuestions,
        {'order[id]': 'desc', per_page: this.state.perPage},
        'questions',
        FormatRow,
        page,
        filters,
        callback
    )
}

/**
 * Create a jsx object that will be interpreted by the DataTable
 *
 * @param question {object}
 * @return {Promise<{question: *, level: *, active: JSX.Element, language: *, id, title: *, category: *, questionType: *}>}
 */
async function FormatRow(question) {
    const actions = <ActionsMenu
        question={question}
        handleActionQuestion={this.handleActionQuestion}
    />
    return {
        id: question.id,
        title: Helper.FormatLink(
            question.title,
            'bo-data-table_clickable',
            setRouteParameters(Routing.bo_question_edit, {
                id: question.id,
                questionType: question.questionType.id
            })
        ),
        question: Helper.FormatText(question.question),
        questionType: Helper.FormatType(question.questionType),
        category: Helper.FormatText(question.category.name),
        level: Helper.FormatLevel(question.level),
        language: Helper.FormatFlag(question.language),
        active: (
        <ActiveSwitch
            objectActive={question.active}
            objectId={question.id}
            apiFunction={Api.question.updateQuestion}
            idPrefix="question"
            className="questions-active-switch"
        />
        ),
        actions: actions
    }
}

function ActionsMenu({question, ...props}) {
    const {t} = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose()
                    return props.handleActionQuestion(1, question)
                }
                }>
                    <ContentCopyIcon /> {t('tableColumnNames.actions.clone')}
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    return props.handleActionQuestion(2, question)
                }
                }>
                    <EditIcon /> {t('tableColumnNames.actions.changeType')}
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    return props.handleActionQuestion(3, question)
                }
                }>
                    <DeleteIcon />{t('tableColumnNames.actions.delete')}
                </MenuItem>
            </Menu>
        </div>
    );
}
