export const routes = {
    app_home: '/',
    app_login: '/login',
    app_logout: '/logout',
    bo_dashboard: '/dashboard',
    bo_users: '/users',
    bo_user_edit: '/user/edit/:id',
    bo_society: '/society',
    bo_society_details: '/society/details',
    bo_society_stats: '/society/stats',
    bo_question: '/quiz/question',
    bo_question_edit: '/quiz/question/edit/:id/:questionType',
    bo_category: '/quiz/category',
    bo_questionType: '/quiz/questionType',
    bo_informationSheets: '/quiz/informationSheets',
    bo_challenge: '/challenge',
    bo_report: '/report',
    bo_profil : '/profil',
    bo_changelog : '/changelog'
}

/**
 * Return a route string url with parameters
 *
 * @param route {string} - the original route with param
 * @param params {object}
 * @return {string} - the formatted route
 */
export function setRouteParameters(route, params) {
    for (const [key, value] of Object.entries(params)) {
        route = route.replace(`:${key}`, value)
    }
    return route
}

const exported = {routes, setRouteParameters}
export default exported
