import React from "react"
import {Constants} from "scg.common-library"
import HtmlStructure from "../HtmlStructure"
import PropTypes from 'prop-types'
import "./errorPage.css"

export default function ErrorPage(props) {

    const {code, title, message} = props
    document.title = `${title} - ${Constants.DOCUMENT_TITLE_BACKOFFICE}`

    return <HtmlStructure menuName='quiz' sectionClassName='error-content'>
        <div className='error-code'>{code}</div>
        <div className='error-title'>{title}</div>
        <div className='error-message'>{message}</div>
    </HtmlStructure>
}

ErrorPage.propTypes = {
    code: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string
}