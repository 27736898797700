import {useState} from 'react'
import {InputField} from './Input'
import Helper from '../../../services/Helper'
import {useTranslation} from 'react-i18next'

import './action_reason.css'

function ActionReason({
    objectId = null,
    apiFunction = null,
    onClose,
    apiSuccessStatus = 200,
    apiParametersObject = {},
    callbackSuccess = null,
    callbackFail = null,
    apiMethod = 'POST',
    withComments = true,
    specificContent = false,
    specificMessage = null,
    specificValidation = "",
    specificCancel = ""
}) {
    const {t} = useTranslation()

    const [reason, setReason] = useState('')
    const handleClose = () => {
        onClose()
    }

    return (
        <div className='layout'>
            {
                (specificContent && specificContent !== null) ? 
                specificMessage : ""
            }
            <InputField
                type='text'
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                visible={withComments}
                placeHolder={t('general.form.actionReason.input.placeholder')}
            >
                {t('general.form.actionReason.input.value')}
            </InputField>
            <div className='layout-buttons'>
                <input
                    type='button'
                    className='btn default closeButton'
                    onClick={handleClose}
                    value={
                        (specificContent && specificCancel !== "") ? specificCancel :
                        withComments
                            ? t('general.form.actionReason.buttons.close.close')
                            : t('general.form.actionReason.buttons.close.no')
                    }
                />

                <input
                    type='button'
                    className='btn default addButton'
                    value={
                        (specificContent && specificValidation !== "") ? specificValidation :
                        withComments
                            ? t('general.form.actionReason.buttons.add.add')
                            : t('general.form.actionReason.buttons.add.yes')
                    }
                    onClick={() => handleApi(objectId, apiSuccessStatus)}
                />
            </div>
        </div>
    )

    async function handleApi(id, successStatus) {
        if (withComments && reason === '') {
            Helper.displayMessage(t('general.form.actionReason.errors.reason'), 'error')
            return
        }
        let response, parameters = null
        switch (apiMethod) {
            case 'GET':
                response = await apiFunction()
                break
            default:
                parameters = Object.assign(apiParametersObject, {reason: reason})
                response = await apiFunction(id, parameters)
                break
        }

        if (response?.status !== successStatus) {
            Helper.displayMessage(t('general.form.actionReason.errors.response'), 'error')
            if (callbackFail !== null) callbackFail()
        } else {
            const msg = response.data.message ?? ''
            Helper.displayMessage(`${t('general.form.actionReason.success')}. ${msg}`)
            //when done successfully, close the modal
            if (callbackSuccess !== null) callbackSuccess()
            handleClose()
        }
    }
}

export default ActionReason
