import { NavLink } from 'react-router-dom';
import { routes as Routing } from '../../services/RoutesHelper';
import { useTranslation } from 'react-i18next';

import './quizTabs.css';

export default function QuizTabs() {
  const { t } = useTranslation();

  return (
    <article className="quizTabs">
      <div className="quizTabs-item">
        <NavLink
          to={Routing.bo_question}
          title={t('quiz.tabs.questions.title')}
        >
          <span>{t('quiz.tabs.questions.name')}</span>
        </NavLink>
      </div>
      <div className="quizTabs-item">
        <NavLink
          to={Routing.bo_informationSheets}
          title={t('quiz.tabs.informationSheets.title')}
        >
          <span>{t('quiz.tabs.informationSheets.name')}</span>
        </NavLink>
      </div>
      <div className="quizTabs-item">
        <NavLink
          to={Routing.bo_category}
          title={t('quiz.tabs.categories.title')}
        >
          <span>{t('quiz.tabs.categories.name')}</span>
        </NavLink>
      </div>
      <div className="quizTabs-item">
        <NavLink
          to={Routing.bo_questionType}
          title={t('quiz.tabs.questionTypes.title')}
        >
          <span>{t('quiz.tabs.questionTypes.name')}</span>
        </NavLink>
      </div>
    </article>
  );
}
