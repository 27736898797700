import {Component} from 'react'
import {Api, Constants, Session} from 'scg.common-library'
import {TableSkeleton} from '../../../services/LoadingHelper'
import moment from 'moment'
import {withTranslation} from 'react-i18next'
import _ from 'lodash'
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded'
import {Chip} from '@mui/material'

import './activities.css'

const Activities = withTranslation()(
    class extends Component {
        static defaultProps = {}

        constructor(props) {
            super(props)
            this.state = {
                isLoading: true,
                authorizedTypes: getTypes("all"),
                currentFilter: "all",
                counts: {"all": 0, "info": 0, "warning": 0, "alert": 0},
                isScrollLoading: false,
                logs: [],
                logsPage: 1
            }
            this.perPage = 100
            this.scrollFlag = true
        }

        componentDidMount() {
            this.getLogs()
        }

        componentDidUpdate(prevProps, prevState) {
            if (this.state.logsPage !== prevState.logsPage) {
                this.getLogs()
            }
        }

        getLogs = async () => {
            let response = await Api.log.getLogs(
                'order[id]=desc',
                this.state.logsPage,
                Session.getSessionUser().roles[0] === Constants.ROLE_ADMIN
                    ? Session.getSessionUser().society.id
                    : null,
                this.perPage
            )

            let logs = []
            if (response?.status !== 200) {
                //todo: display an error message
            } else if (response.hasOwnProperty('data')) {
                logs = response.data
            }

            if (logs.length !== this.perPage) {
                this.scrollFlag = false
            }

            const fullLogs = [...this.state.logs, ...logs]
            this.setState(state => ({
                ...state,
                logs: fullLogs,
                isLoading: false,
                isScrollLoading: false,
                counts: this._countByType(fullLogs)
            }))
        }

        _countByType = list => {
            return {
                "all": _.filter(list, l => _.includes(getTypes("all"), l.type)).length,
                "info": _.filter(list, l => _.includes(getTypes("info"), l.type)).length,
                "warning": _.filter(list, l => _.includes(getTypes("warning"), l.type)).length,
                "alert": _.filter(list, l => _.includes(getTypes("alert"), l.type)).length
            }
        }

        generateTableLines = () => {
            if (this.state.logs)
                return this.state.logs.map(l => {
                    if (this.state.authorizedTypes.includes(l.type)) {
                        const userInfo = (l.user !== null && l.user !== "")
                            ? `${l.user.name} ${l.user.surname} (${l.user.pseudo})`
                            : '/'
                        const errorClass = getTypes("alert").includes(l.type) ? 'log-error' :
                            getTypes("warning").includes(l.type) ? 'log-warning' : ''
                        return <tr key={l.id} data-id={l.id} className={errorClass}>
                            <td>{moment(l.timeStamp).format('DD/MM/YYYY HH:mm')}</td>
                            <td>{l.type}</td>
                            <td>{userInfo}</td>
                            <td>{l.info}</td>
                        </tr>
                    }
                    return null
                })
        }

        tableScroll = (e) => {
            if (
                e.target.scrollHeight - e.target.offsetHeight === e.target.scrollTop &&
                this.scrollFlag
            ) {
                this.setState((state) => ({
                    ...state,
                    logsPage: state.logsPage + 1,
                    isScrollLoading: true
                }))
            }
        }

        _filterList = (filter) => {
            this.setState({authorizedTypes: getTypes(filter), currentFilter: filter})
        }

        render() {
            const {t} = this.props
            const tableLines = this.generateTableLines()

            return (
                <article className='activity'>
                    <h3>{t('dashboard.activities.title')}</h3>
                    <section className='activity-table card'>
                        <div className='activity-filters'>
                            <Chip variant={`${this.state.currentFilter === "all" ? "filled" : "outlined"}`}
                                  size='small'
                                  color='success' onClick={() => this._filterList("all")}
                                  label={`Toutes (${this.state.counts.all})`} />
                            <Chip variant={`${this.state.currentFilter === "info" ? "filled" : "outlined"}`}
                                  size='small'
                                  color='info' onClick={() => this._filterList("info")}
                                  label={`Informations (${this.state.counts.info})`} />
                            <Chip
                                variant={`${this.state.currentFilter === "warning" ? "filled" : "outlined"}`}
                                size='small'
                                color='warning' onClick={() => this._filterList("warning")}
                                label={`Avertissements (${this.state.counts.warning})`} />
                            <Chip variant={`${this.state.currentFilter === "alert" ? "filled" : "outlined"}`}
                                  size='small'
                                  color='error' onClick={() => this._filterList("alert")}
                                  label={`Erreurs (${this.state.counts.alert})`} />
                        </div>

                        <div className='activity-table-content'>
                            {this.state.isLoading ? <TableSkeleton linesCount={20} size='small' /> :
                                <table>
                                    <thead>
                                    <tr>
                                        <th>{t('tableColumnNames.date')}</th>
                                        <th>{t('tableColumnNames.type')}</th>
                                        <th>{t('tableColumnNames.user')}</th>
                                        <th>{t('tableColumnNames.message')}</th>
                                    </tr>
                                    </thead>
                                    <tbody onScroll={this.tableScroll}>{tableLines}</tbody>
                                </table>}
                        </div>
                        {this.state.isScrollLoading &&
                            <HourglassTopRoundedIcon color='action'
                                                     className='scrollLoading spin animation-fast' />}
                    </section>
                </article>
            )
        }
    }
)

function getTypes(filter = "all") {
    let types = []
    switch (filter) {
        case "all":
            types = [
                Constants.LOG_USER_ERROR,
                Constants.LOG_USER_WARNING,
                Constants.LOG_USER_ACTION,
                Constants.LOG_API_INFO,
                Constants.LOG_API_ERROR,
                Constants.LOG_GENERAL_INFO,
                Constants.LOG_GENERAL_ERROR,
                Constants.LOG_WARNING,
                Constants.QUIZ_FINISHED,
                Constants.QUIZ_LEFT
            ]
            break
        case "info":
            types = [
                Constants.LOG_USER_ACTION,
                Constants.LOG_API_INFO,
                Constants.LOG_GENERAL_INFO,
                Constants.QUIZ_FINISHED,
                Constants.QUIZ_LEFT
            ]
            break
        case "warning":
            types = [
                Constants.LOG_USER_WARNING,
                Constants.LOG_WARNING
            ]
            break
        case "alert":
            types = [
                Constants.LOG_USER_ERROR,
                Constants.LOG_API_ERROR,
                Constants.LOG_GENERAL_ERROR
            ]
            break
        default:
            break
    }
    return types
}

export default Activities
