import {Constants} from 'scg.common-library'
import HtmlStructure from '../general/HtmlStructure'
import {useTranslation} from 'react-i18next'
import { ReportsContent } from './ReportsContent'

export default function Reports() {
    const {t} = useTranslation()

    document.title = `${t('reports.documentTitle')} - ${
        Constants.DOCUMENT_TITLE_BACKOFFICE
    }`

    return (
        <HtmlStructure menuName='report' sectionClassName='reports'>
            <ReportsContent />
        </HtmlStructure>
    )
}
