import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Session } from 'scg.common-library';

function userLanguage() {
  if (Session.getBOLanguageFromTheLocalStorage()) {
    return Session.getBOLanguageFromTheLocalStorage();
  }

  if (Session.getCookie()) {
    return Session.getSessionUser().language.code;
  }

  return 'fr-FR';
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: userLanguage(),
    fallbackLng: 'fr-FR',
    debug: process.env.NODE_ENV !== 'production'
  });

export default i18n;
