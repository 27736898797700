import React from 'react'
import {Api, Constants} from 'scg.common-library'
import Helper from '../../../services/Helper'
import {routes as Routing, setRouteParameters} from '../../../services/RoutesHelper'
import {
    InputField, Requiredstar, TextareaField
} from '../../general/form/Input'
import {Loading} from '../../general/form/Loading'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Navigate, NavLink} from 'react-router-dom'
import SelectWithData from '../../general/form/SelectWithData'
import ActiveSwitch from '../../general/form/ActiveSwitch'
import {List, ListItem, ListItemIcon, ListItemText} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import HistoryIcon from '@mui/icons-material/History'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'
import EventIcon from '@mui/icons-material/Event'
import moment from 'moment'
import {withTranslation} from 'react-i18next'
import Mcqanswer from '../mcqanswer/Mcqanswer'
import TruefalseAnswer from '../truefalseAnswer/TruefalseAnswer'
import {QUESTION_TYPES} from '../../../services/Constants'
import GapsAnswer from "../gaspAnswer/GapsAnswer";

import './questionForm.css'

const QuestionForm = withTranslation()(class extends React.Component {
    static defaultProps = {
        questionType: null, questionId: null
    }

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            question: getEmptyQuestionObject(),
            questionTitle: '',
            questionText: '',
            questionImage: null,
            questionAnswer: '',
            questionCategory: null,
            questionLevel: null,
            questionLanguage: null,
            questionHasData: false,
            questionId: null,
            forceLocation: false,
            reloadAfterCreation: false,
            questionMcqanswer: [],
            questionTypeShortName: null,
            questionMcqanswerCountRefresh: false,
            questionInitMcqanswer: false,
            questionTruefalseAnswer: [],
            questionTruefalseAnswerCountRefresh: false,
            questionInitTruefalseAnswer: false,
            questionAllTruefalseLabel: [],
            questionGapsAnswer: [],
            questionGapsAnswerCountRefresh: false,
            questionInitGapsAnswer: false
        }
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleQuestionInputChange = this.handleQuestionInputChange.bind(this)
        this.handleQuestionExplanatoryChange = this.handleQuestionExplanatoryChange.bind(this)
        this.handleImageInputChange = this.handleImageInputChange.bind(this)
        this.handleCategorySelectChange = this.handleCategorySelectChange.bind(this)
        this.handleLevelSelectChange = this.handleLevelSelectChange.bind(this)
        this.handleLanguageSelectChange = this.handleLanguageSelectChange.bind(this)
        this.handleActiveChange = this.handleActiveChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.initStateObjects = this.initStateObjects.bind(this)
        this.handleChangeQuestionMcqanswer = this.handleChangeQuestionMcqanswer.bind(this)
        this.handleDesactiveQuestionMcqanswerRefresh = this.handleDesactiveQuestionMcqanswerRefresh.bind(this)
        this.handleChangeQuestionTruefalseAnswer = this.handleChangeQuestionTruefalseAnswer.bind(this)
        this.handleDesactiveQuestionTruefalseAnswerRefresh = this.handleDesactiveQuestionTruefalseAnswerRefresh.bind(this)
        this.handleChangeQuestionGapsAnswer = this.handleChangeQuestionGapsAnswer.bind(this)
        this.handleDesactiveQuestionGapsAnswerRefresh = this.handleDesactiveQuestionGapsAnswerRefresh.bind(this)
        this.handleInjectPositionGapsAnswer = this.handleInjectPositionGapsAnswer.bind(this)

        this.categoryOptions = [
            {
                label: props.t('tableColumnNames.category'),
                icon: 'fa-table-list',
                options: []
            }]
        this.levelOptions = [{label: props.t('tableColumnNames.level'), icon: 'fa-stairs', options: []}]
        this.languageOptions = [
            {
                label: props.t('tableColumnNames.language'),
                icon: 'fa-language',
                options: []
            }]
    }

    componentDidMount() {
        (async function() {
            this.setState({reloadAfterCreation: false})
            const responseType = await Api.questionType.getQuestionType(this.props.questionType)
            if (responseType?.status === Constants.HTTP_OK) {
                this.setState({
                    questionTypeShortName: responseType?.data?.shortName
                })
            }
            const responseAllTruefalseLabel = await Api.truefalseLabel.getTruefasleLabels()
            if (responseAllTruefalseLabel?.status === Constants.HTTP_OK) {
                this.setState({
                    questionAllTruefalseLabel: responseAllTruefalseLabel?.data
                })
            }
            if (this.props.questionId !== null && this.props.questionId !== 'new') {
                const response = await Api.question.getQuestion(this.props.questionId)
                const resultObject = Helper.isValidResponse(response)
                if (resultObject) {
                    this.initStateObjects(resultObject)
                    this.setState({questionInitMcqanswer: true, questionInitTruefalseAnswer: true, questionInitGapsAnswer: true})
                }
            } else {
                this.setState({
                    question: getEmptyQuestionObject(), loading: false,
                    questionHasData: false, questionInitTruefalseAnswer: true
                })
            }
        }).bind(this)()
    }

    initStateObjects(resultObject) {
        this.setState({
            question: cleanupQuestionObject(resultObject),
            questionTitle: resultObject.title,
            questionText: resultObject.question,
            questionImage: resultObject.featuredImage,
            questionAnswer: resultObject.answerExplanatoryText,
            questionCategory: resultObject.category?.id,
            questionLevel: resultObject.level?.id,
            questionLanguage: resultObject.language?.id,
            loading: false,
            questionHasData: true,
            questionId: resultObject.id
        })
    }

    handleTitleChange(e) {
        const title = e.target.value
        this.setState(prevState => {
            let question = prevState.question
            question.title = title
            return {question: question, questionTitle: title}
        })
    }

    handleQuestionInputChange(e) {
        const text = e.target.value
        this.setState(prevState => {
            let question = prevState.question
            question.question = text
            return {question: question, questionText: text}
        })
    }

    handleInjectPositionGapsAnswer(position) {
        this.setState(prevState => {
            const add = prevState.questionText + " [[" + position + "]]"
            let question = prevState.question
            question.question = add
            return {question: question, questionText: add}
        })
    }

    handleQuestionExplanatoryChange(e) {
        const answer = e.target.value
        this.setState(prevState => {
            let question = prevState.question
            question.answerExplanatoryText = answer
            return {question: question, questionAnswer: answer}
        })
    }

    handleCategorySelectChange(e) {
        const id = e?.value ?? null
        this.setState(prevState => {
            let question = prevState.question
            question.category = id === null ? null : `/api/category/${id}`
            return {question: question, questionCategory: id}
        })
    }

    handleLevelSelectChange(e) {
        const id = e?.value ?? null
        this.setState(prevState => {
            let question = prevState.question
            question.level = id === null ? null : `/api/level/${e.value}`
            return {question: question, questionLevel: id}
        })
    }

    handleLanguageSelectChange(e) {
        const id = e?.value ?? null
        this.setState(prevState => {
            let question = prevState.question
            question.language = id === null ? null : `/api/language/${e.value}`
            return {question: question, questionLanguage: id}
        })
    }

    handleActiveChange(state) {
        this.setState((prevState) => {
            let question = prevState.question
            question.active = state
            return {question: question}
        })
    }

    handleImageInputChange(e) {
        console.log(e)
        //TODO: code here
    }

    handleSave() {
        (async function() {
            if (!this.checkForm()) {
                Helper.displayMessage(this.props.t('quiz.pages.questions.form.messages.errors.save'), 'error')
                return
            }
            if (this.state.questionTypeShortName === QUESTION_TYPES.MCQ) {
                const [check, theMessage] = this.checkMcqanswer()
                if (!check) {
                    Helper.displayMessage(theMessage, 'error')
                    return
                }
            }
            if (this.state.questionTypeShortName === QUESTION_TYPES.Gaps) {
                const [check, theMessage] = this.checkGapsAnswer()
                if (!check) {
                    Helper.displayMessage(theMessage, 'error')
                    return
                }
            }
            this.setLoading()
            let response
            let message
            if (this.props.questionId === null || this.props.questionId === 'new') {
                response = await Api.question.createQuestion(
                    this.state.questionText,
                    this.state.questionTitle,
                    this.state.questionCategory,
                    this.props.questionType,
                    this.state.questionLanguage,
                    this.state.questionLevel,
                    null,
                    this.state.questionAnswer,
                    this.state.question.active,
                    this.state.questionImage
                )
                message = this.props.t('quiz.pages.questions.form.messages.create')
                if (response?.status === Constants.HTTP_CREATED) {
                    this.initStateObjects(response.data)
                    if (this.state.questionTypeShortName === QUESTION_TYPES.MCQ) {
                        this.saveAllMcqAnswer(response.data.id)
                    }
                    if (this.state.questionTypeShortName === QUESTION_TYPES.TrueFalse) {
                        this.saveAllTruefalseAnswer(response.data.id)
                    }
                    if (this.state.questionTypeShortName === QUESTION_TYPES.Gaps) {
                        this.saveAllGapsAnswer(response.data.id)
                    }
                }
            } else {
                response = await Api.question.updateQuestion(this.state.questionId, this.state.question)
                if (response?.status === Constants.HTTP_OK) {
                    if (this.state.questionTypeShortName === QUESTION_TYPES.MCQ) {
                        this.saveAllMcqAnswer(response.data.id)
                    }
                    if (this.state.questionTypeShortName === QUESTION_TYPES.TrueFalse) {
                        this.saveAllTruefalseAnswer(response.data.id)
                    }
                    if (this.state.questionTypeShortName === QUESTION_TYPES.Gaps) {
                        this.saveAllGapsAnswer(response.data.id)
                    }
                }
                message = this.props.t('quiz.pages.questions.form.messages.update')
                this.setState((prevState) => {
                    let q = prevState.question
                    q.version = response.data.version ?? q.version
                    q.modificationDate = response.data.modificationDate ?? q.modificationDate
                    return {question: q}
                })
            }
            if (response?.status !== Constants.HTTP_OK && response?.status !== Constants.HTTP_CREATED) {
                message = this.props.t('quiz.pages.questions.form.messages.errors.response')
                if (response['@type'] === 'ConstraintViolationList') {
                    message = response.violations[0].message
                }
                Helper.displayMessage(message, 'error')
            } else {
                Helper.displayMessage(message)
                if (this.props.questionId === null || this.props.questionId === 'new') {
                    this.setState({reloadAfterCreation: true})
                }
            }
            this.setLoading(false)
        }).bind(this)()
    }

    handleDelete() {
        (async function() {
            this.setLoading()
            let response
            let message = this.props.t('quiz.pages.questions.form.messages.delete')

            response = await Api.question.deleteQuestion(this.state.questionId)

            if (response?.status !== Constants.HTTP_NO_CONTENT) {
                message = this.props.t('quiz.pages.questions.form.messages.errors.response')
                Helper.displayMessage(message, 'error')
            } else {
                Helper.displayMessage(message)
                setTimeout(() => {
                    this.setState({forceLocation: true})
                }, 1500)
            }
        }).bind(this)()
    }

    setLoading(status = true) {
        this.setState({loading: status})
    }

    checkForm() {
        return (this.state.questionTitle !== '' && this.state.questionText !== '' &&
            this.state.questionCategory !== null && this.state.questionLevel !== null &&
            this.state.questionLanguage !== null)
    }

    handleChangeQuestionMcqanswer(listMcqAnswer) {
        this.setState({questionMcqanswer: listMcqAnswer})
    }

    handleDesactiveQuestionMcqanswerRefresh() {
        this.setState({questionMcqanswerCountRefresh: 0})
    }

    handleChangeQuestionGapsAnswer(listGapsAnswer) {
        this.setState({questionGapsAnswer: listGapsAnswer})
    }

    handleDesactiveQuestionGapsAnswerRefresh() {
        this.setState({questionGapsAnswerCountRefresh: 0})
    }

    handleChangeQuestionTruefalseAnswer(listTruefalseAnswer) {
        this.setState({questionTruefalseAnswer: listTruefalseAnswer})
    }

    handleDesactiveQuestionTruefalseAnswerRefresh() {
        this.setState({questionTruefalseAnswerCountRefresh: 0})
    }

    checkMcqanswer() {
        const emptyResponse = this.state.questionMcqanswer.filter(
            (element) => (element.text === "" && element.active))
        if (emptyResponse.length > 0) {
            return [false, this.props.t('quiz.pages.questions.mcqanswer.errors.emptyAnswer')]
        }
        const activeResponse = this.state.questionMcqanswer.filter((element) => (element.active))
        const activerCorrectResponse = this.state.questionMcqanswer.filter(
            (element) => (element.correct && element.active))
        if (activeResponse.length < 4 || activerCorrectResponse.length < 1) {
            return [false, this.props.t('quiz.pages.questions.mcqanswer.errors.numberAnswer')]
        }

        return [true, ""]
    }

    checkGapsAnswer() {
        const allPositionText = []
        const regex = /\[\[\d+\]\]/g
        const allTag = this.state.questionText.match(regex)
        if (allTag !== null) {
            allTag.forEach(value => {
                allPositionText.push(parseInt(value.replace("[[", "").replace("]]", "")))
            })
        }
        const allResponse = this.state.questionGapsAnswer.filter(element => element.text !== "")
        if (allResponse.length < 3) {
            return [false, this.props.t('quiz.pages.questions.gapsAnswer.errors.numberAnswer')]
        }
        const allPositionAnswer = []
        allResponse.forEach(value => {
            allPositionAnswer.push(parseInt(value.position))
        })
        const notInclude = allPositionAnswer.filter(x => !allPositionText.includes(x))
        if (notInclude.length > 0) {
            return [false, this.props.t('quiz.pages.questions.gapsAnswer.errors.unusedAnswer')]
        }
        const allEmptyResponse = this.state.questionGapsAnswer.filter(element => element.text === "")
        const allPositionEmptyAnswer = []
        allEmptyResponse.forEach(value => {
            allPositionEmptyAnswer.push(parseInt(value.position))
        })
        const emptyInclude = allPositionText.filter(x => allPositionEmptyAnswer.includes(x))
        if (emptyInclude.length > 0) {
            const allEmpty = "[["+emptyInclude.join(']], [[')+"]]"
            return [false, this.props.t('quiz.pages.questions.gapsAnswer.errors.emptyAnswer',{position: allEmpty})]
        }
        const allPosition = []
        this.state.questionGapsAnswer.forEach(value => {
            allPosition.push(parseInt(value.position))
        })
        const notExclude = allPositionText.filter(x => !allPosition.includes(x))
        if (notExclude.length > 0) {
            const allExclude = "[["+notExclude.join(']], [[')+"]]"
            return [false, this.props.t('quiz.pages.questions.gapsAnswer.errors.notFoundAnswer',{position: allExclude})]
        }
        return [true, ""]
    }

    async saveFileMcqAnswer(formData, questionId) {
        await Api.mcqAnswer.saveFileMcqAnswer(questionId, formData)
    }

    saveAllMcqAnswer(questionId) {
        this.setLoading()
        let formData = new FormData()
        let needUploadFile = false
        for (let item of this.state.questionMcqanswer) {
            if (item.file !== null && item.active) {
                needUploadFile = true
                formData.append("file[]", item.file)
            }
        }
        if (needUploadFile) {
            this.saveFileMcqAnswer(formData, questionId)
        }
        this.state.questionMcqanswer.forEach(async element => {
            let mcqAnswerResponse = null
            if (element.new) {
                if (element.active) {
                    mcqAnswerResponse = await Api.mcqAnswer.createMcqAnswer(questionId, element.text,
                        element.correct, element.imageLink, element.imageOnly)
                    if (mcqAnswerResponse?.status === Constants.HTTP_OK || mcqAnswerResponse?.status ===
                        Constants.HTTP_CREATED) {
                        this.setState((prevState) => {
                            return {
                                questionMcqanswerCountRefresh: (prevState.questionMcqanswerCountRefresh + 1)
                            }
                        })
                    }
                }
            } else {
                if (element.active) {
                    mcqAnswerResponse = await Api.mcqAnswer.updateMcqAnswer(element.id, {
                        text: element.text,
                        correct: element.correct,
                        imageLink: element.imageLink,
                        imageOnly: element.imageOnly
                    })
                    if (mcqAnswerResponse?.status === Constants.HTTP_OK || mcqAnswerResponse?.status ===
                        Constants.HTTP_CREATED) {
                        this.setState((prevState) => {
                            return {
                                questionMcqanswerCountRefresh: (prevState.questionMcqanswerCountRefresh + 1)
                            }
                        })
                    }
                } else {
                    mcqAnswerResponse = await Api.mcqAnswer.deleteMcqAnswer(element.id)
                    if (mcqAnswerResponse?.status === Constants.HTTP_NO_CONTENT) {
                        this.setState((prevState) => {
                            return {
                                questionMcqanswerCountRefresh: (prevState.questionMcqanswerCountRefresh + 1)
                            }
                        })
                    }
                }
            }
        })
    }

    saveAllTruefalseAnswer(questionId) {
        this.setLoading()
        this.state.questionTruefalseAnswer.forEach(async element => {
            let truefalseAnswerResponse = element.new
               ? await Api.truefalseAnswer.createTruefalseAnswer(questionId, element.truefalseLabelId,element.correct)
               : await Api.truefalseAnswer.updateTruefalseAnswer(element.id, {correct: element.correct})

            if ([Constants.HTTP_OK, Constants.HTTP_CREATED].includes(truefalseAnswerResponse?.status)) {
                this.setState((prevState) => {
                    return {
                        questionTruefalseAnswerCountRefresh: (prevState.questionTruefalseAnswerCountRefresh + 1)
                    }
                })
            }
        })
    }

    saveAllGapsAnswer(questionId) {
        this.setLoading()
        this.state.questionGapsAnswer.forEach(async element => {
            let gapsAnswerResponse
            if(element.new) {
                gapsAnswerResponse = element.text === ""
                    ? {status: 200}
                    : await Api.gapsAnswer.createGapsAnswer(questionId, element.text, element.position)
            } else {
                gapsAnswerResponse = element.text === ""
                    ? await Api.gapsAnswer.deleteGapsAnswer(element.id)
                    : await Api.gapsAnswer.updateGapsAnswer(element.id, {text: element.text})
            }

            if ([Constants.HTTP_OK, Constants.HTTP_CREATED, Constants.HTTP_NO_CONTENT].includes(gapsAnswerResponse?.status)) {
                this.setState((prevState) => {
                    return {
                        questionGapsAnswerCountRefresh: (prevState.questionGapsAnswerCountRefresh + 1)
                    }
                })
            }
        })
    }

    render() {
        const {t} = this.props

        // Used to redirect to question list after a delete request
        if (this.state.forceLocation) {
            return <Navigate to={Routing.bo_question} replace />
        }

        // Used to reload edit form after creation
        if (this.state.reloadAfterCreation) {
            this.setState({reloadAfterCreation: false})
            return <Navigate to={setRouteParameters(Routing.bo_question_edit, {
                id: this.state.questionId,
                questionType: this.props.questionType
            })} replace />
        }

        return <article className='question-form'>
            {this.state.loading && <Loading />}
            <div className='question-form-menu'>
                <NavLink
                    to={Routing.bo_question}
                    className='btn backButton'
                    title={t('quiz.pages.questions.form.buttons.back')}
                >
                    <FontAwesomeIcon icon='fas fa-left-long' />
                </NavLink>
                <span>
              {t('quiz.pages.questions.form.breadCrumbs.question')} /{' '}
                    {this.state.questionHasData ? t('quiz.pages.questions.form.breadCrumbs.edit') : t(
                        'quiz.pages.questions.form.breadCrumbs.creation')}
            </span>
            </div>
            <div className='question-form-content'>
                <div className='question-form-title card'>
                    <InputField
                        className='question-title'
                        type='text'
                        name='questionTitle'
                        inputStyle='classic'
                        value={this.state.questionTitle ?? ''}
                        context={this}
                        required={true}
                        isFocused={this.state.questionTitle !== ''}
                        onChange={this.handleTitleChange}
                        title={t('quiz.pages.questions.form.inputs.title.title')}
                    >
                        {t('quiz.pages.questions.form.inputs.title.label')}
                    </InputField>
                </div>

                <div className='question-form-infos'>
                    <div className='questions-leftForm card'>
                        <div className='text-input-container'>
                            <TextareaField
                                className='bo-box-container'
                                name='question-input'
                                inputStyle='classic'
                                value={this.state.questionText ?? ''}
                                context={this}
                                required={true}
                                isFocused={this.state.questionText !== ''}
                                onChange={this.handleQuestionInputChange}
                                rows={5}
                                title={t('quiz.pages.questions.form.inputs.question.title')}
                            >
                                {t('quiz.pages.questions.form.inputs.question.label')}
                            </TextareaField>
                        </div>
                        <div className='image-input-container helper-not-available-container'>
                            <InputField
                                type='file'
                                name='image'
                                accept='image/png, image/jpeg'
                                value={this.state.questionImage ?? ''}
                                context={this}
                                disabled={true}
                                isFocused={this.state.questionImage !== null}
                                onChange={this.handleImageInputChange}
                                title={t('quiz.pages.questions.form.inputs.image.title')}
                            >
                                {t('quiz.pages.questions.form.inputs.image.label')}
                            </InputField>
                        </div>

                        <div className='text-input-container'>
                            <TextareaField
                                name='question-explanatory'
                                className='bo-box-container'
                                inputStyle='classic'
                                value={this.state.questionAnswer ?? ''}
                                context={this}
                                required={false}
                                isFocused={this.state.questionAnswer !== null && this.state.questionAnswer !==
                                    ''}
                                onChange={this.handleQuestionExplanatoryChange}
                                rows={5}
                                title={t('quiz.pages.questions.form.inputs.answer.title')}
                            >
                                {t('quiz.pages.questions.form.inputs.answer.label')}
                            </TextareaField>
                        </div>

                        <div className='response-container'>
                            <div className='response-label'><b>{t('quiz.pages.questions.mcqanswer.label')}</b>
                            </div>
                            {this.state.questionTypeShortName === QUESTION_TYPES.MCQ && <Mcqanswer
                                questionId={this.state.questionId}
                                initMcsanswer={this.state.questionInitMcqanswer}
                                callbackUpdateListe={this.handleChangeQuestionMcqanswer}
                                refreshCountListe={this.state.questionMcqanswerCountRefresh}
                                callbackOtherRefresh={this.handleDesactiveQuestionMcqanswerRefresh} />}
                            {this.state.questionTypeShortName === QUESTION_TYPES.TrueFalse && <TruefalseAnswer
                                questionId={this.state.questionId}
                                initTruefalseAnswer={this.state.questionInitTruefalseAnswer}
                                refreshCountListe={this.state.questionTruefalseAnswerCountRefresh}
                                callbackOtherRefresh={this.handleDesactiveQuestionTruefalseAnswerRefresh}
                                allTruefalseLabel={this.state.questionAllTruefalseLabel} 
                                handleChangeQuestionTruefalseAnswer={this.handleChangeQuestionTruefalseAnswer} />}
                            {this.state.questionTypeShortName === QUESTION_TYPES.Gaps && <GapsAnswer
                                questionId={this.state.questionId}
                                initGapsAnswer={this.state.questionInitGapsAnswer}
                                callbackUpdateListe={this.handleChangeQuestionGapsAnswer}
                                refreshCountListe={this.state.questionGapsAnswerCountRefresh}
                                callbackOtherRefresh={this.handleDesactiveQuestionGapsAnswerRefresh}
                                injectPositionGapsAnswer={this.handleInjectPositionGapsAnswer}
                            />}
                        </div>
                    </div>
                    <div className='questions-rightForm'>
                        <div className='question-info card'>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <VisibilityIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t('quiz.pages.questions.form.info.status')}
                                        secondary={<ActiveSwitch
                                            objectActive={this.state.question?.active}
                                            objectId={this.state.question?.id}
                                            onChange={this.handleActiveChange}
                                            idPrefix='question'
                                            className='question-active-switch'
                                        />}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <HistoryIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t('quiz.pages.questions.form.info.version')}
                                        secondary={this.state.question?.version}
                                    />
                                    <span></span>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <EventIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t('quiz.pages.questions.form.info.creationDate')}
                                        secondary={moment(this.state.question?.creationDate).
                                            format('DD-MM-YYYY HH:mm')}
                                    />
                                    <span></span>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <EditCalendarIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t('quiz.pages.questions.form.info.editDate')}
                                        secondary={moment(this.state.question?.modificationDate).
                                            format('DD-MM-YYYY HH:mm')}
                                    />
                                    <span></span>
                                </ListItem>
                            </List>

                            <div className='flex-sb'>
                                <button
                                    type='button'
                                    className='btn alert delete-button'
                                    title={t('quiz.pages.questions.form.buttons.delete')}
                                    onClick={this.handleDelete}
                                >
                                    <FontAwesomeIcon icon='fas fa-trash' />
                                </button>
                                <button
                                    type='button'
                                    className='btn success save-button'
                                    title={t('quiz.pages.questions.form.buttons.save.title')}
                                    onClick={this.handleSave}
                                >
                                    {t('quiz.pages.questions.form.buttons.save.name')}
                                </button>
                            </div>
                        </div>

                        <div className='card'>
                                <span className='card-title'>
                                    {t('quiz.pages.questions.form.selects.category.label')}{' '}
                                    <Requiredstar />
                                </span>
                            <SelectWithData
                                ref={this.categorySelector}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                className='question-category-selector'
                                noPadding={true}
                                placeholder={t('quiz.pages.questions.form.selects.category.placeholder')}
                                options={this.categoryOptions}
                                onChange={this.handleCategorySelectChange}
                                apiFunction={Api.category.getCategories}
                                context={this}
                                selected={this.state.questionCategory}
                            />
                        </div>
                        <div className='card'>
                            <span className='card-title'>
                                {t('quiz.pages.questions.form.selects.level.label')}{' '}
                                <Requiredstar />
                            </span>
                            <SelectWithData
                                ref={this.levelSelector}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                className='question-level-selector'
                                noPadding={true}
                                placeholder={t('quiz.pages.questions.form.selects.level.placeholder')}
                                options={this.levelOptions}
                                onChange={this.handleLevelSelectChange}
                                apiFunction={Api.level.getLevels}
                                context={this}
                                selected={this.state.questionLevel}
                            />
                        </div>
                        <div className='card'>
                            <span className='card-title'>
                                {t('quiz.pages.questions.form.selects.language.label')}{' '}
                                <Requiredstar />
                            </span>
                            <SelectWithData
                                ref={this.languageSelector}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                className='question-language-selector'
                                noPadding={true}
                                placeholder={t('quiz.pages.questions.form.selects.language.placeholder')}
                                options={this.languageOptions}
                                onChange={this.handleLanguageSelectChange}
                                apiFunction={Api.language.getLanguages}
                                selectType='Language' // do not change !
                                context={this}
                                selected={this.state.questionLanguage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </article>
    }
})

/* ================================== EXPORTED COMPONENTS ================================== */
const exported = {QuestionForm}
export default exported

/* ================================== GLOBAL FUNCTIONS ================================== */

function getEmptyQuestionObject() {
    return {
        question: '',
        answerExplanatoryText: '',
        featuredImage: null,
        title: '',
        active: false,
        language: null,
        category: null,
        level: null,
        questionType: null
    }
}

function cleanupQuestionObject(object) {
    const convertObject = {...object}
    convertObject.category = object.category['@id'] ?? getApiLink('category', object.category['id'])
    convertObject.level = object.level['@id'] ?? getApiLink('level', object.level['id'])
    convertObject.language = object.language['@id'] ?? getApiLink('language', object.language['id'])
    convertObject.questionType = object.questionType['@id'] ?? getApiLink('type', object.questionType['id'])
    delete convertObject['@id']
    delete convertObject['@type']
    delete convertObject['@context']
    delete convertObject['mcqAnswers']
    delete convertObject['truefalseAnswers']
    delete convertObject['gapsAnswers']

    return convertObject
}

function getApiLink(type, id) {
    switch (type) {
        case 'category':
            return `/api/category/${id}`
        case 'level':
            return `/api/level/${id}`
        case 'language':
            return `/api/language/${id}`
        case 'type':
            return `/api/question_type/${id}`
        default:
            return id
    }
}
