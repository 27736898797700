import { useState } from 'react';
import Helper from '../../services/Helper';
import ActionReason from '../general/form/ActionReason';
import { Api } from 'scg.common-library';
import Modal from '../general/form/Modal';
import { Session } from 'scg.common-library';
import { useTranslation } from 'react-i18next';

import './users.css';

function PrepareColBanned({ user, callbackSuccess }) {
  const { t } = useTranslation();

  const [content, setContent] = useState(
    Helper.FormatBadge(t('tableColumnNames.banned.no'), !user.banned)
  );
  /*modal1 pour debannissement*/
  const [showModal1, setShowModal1] = useState(false);
  /*modal2 pour bannissement*/
  const [showModal2, setShowModal2] = useState(false);
  if (user.banned) {
    return (
      <div>
        <div
          onMouseOver={() => changeContent()}
          onClick={() => showConfirmationDebannish()}
          onMouseOut={() => changeContentToInitial(true)}
        >
          {content}
        </div>
        <Modal
          isShowing={showModal1}
          title={t('tableColumnNames.banned.modal.title.unBan')}
          children={
            <ActionReason
              onClose={() => setShowModal1(false)}
              objectId={user.id}
              apiFunction={Api.user.updateUser}
              apiSuccessStatus={200}
              apiParametersObject={{ banned: false }}
              callbackSuccess={setColToNotBanned}
            />
          }
          hide={() => setShowModal1(false)}
        />
      </div>
    );
  } else if (Session.getUser().username !== user.email) {
    //on affiche la possibilité de bannir au travers un boutton
    return (
      <div>
        <div
          onMouseOver={() => changeContent()}
          onClick={() => showConfirmationBannish()}
          onMouseOut={() => changeContentToInitial()}
        >
          {content}
        </div>
        <Modal
          isShowing={showModal2}
          title={t('tableColumnNames.banned.modal.title.ban')}
          children={
            <ActionReason
              onClose={() => setShowModal2(false)}
              objectId={user.id}
              apiFunction={Api.user.updateUser}
              apiSuccessStatus={200}
              apiParametersObject={{ banned: true }}
              callbackSuccess={setColToBanned}
            />
          }
          hide={() => setShowModal2(false)}
        />
      </div>
    );
  } else {
    return Helper.FormatBadge(t('tableColumnNames.banned.no'), true);
  }

  function showConfirmationDebannish() {
    setShowModal1(true);
  }

  function showConfirmationBannish() {
    setShowModal2(true);
  }

  function setColToBanned() {
    setContent(Helper.FormatBadge(t('tableColumnNames.banned.yes')));
    callbackSuccess();
  }

  function setColToNotBanned() {
    setContent(Helper.FormatBadge(t('tableColumnNames.banned.no'), true));
    callbackSuccess();
  }

  function changeContent() {
    if (!user.banned) {
      setContent(
        <input
          title={t('tableColumnNames.banned.buttons.ban.title')}
          type="button"
          className="btn default"
          onClick={null}
          value={t('tableColumnNames.banned.buttons.ban.name')}
        />
      );
    } else {
      setContent(
        <input
          title={t('tableColumnNames.banned.buttons.unBan.title')}
          type="button"
          className="btn default"
          onClick={null}
          value={t('tableColumnNames.banned.buttons.unBan.name')}
        />
      );
    }
  }

  function changeContentToInitial(isBanned = false) {
    if (!isBanned) {
      setContent(
        Helper.FormatBadge(t('tableColumnNames.banned.no'), !user.banned)
      );
    } else {
      setContent(Helper.FormatBadge(t('tableColumnNames.banned.yes')));
    }
  }
}

export default PrepareColBanned;
