import React from "react"
import {Constants} from "scg.common-library"
import HtmlStructure from "../general/HtmlStructure"
import Questions from "./question/QuestionList"
import QuizTabs from "./QuizTabs"
import TypeList from "./type/TypeList"
import CategoryList from "./category/CategoryList"
import QuestionForm from "./question/QuestionForm"
import InformationSheets from "./informationSheet/InformationSheetList"
import i18next from 'i18next'

export function QuestionPage() {
  return createQuizSubPage(
    `${i18next.t('quiz.documentTitles.questions')} - `,
    'questions',
    Questions.QuestionList
  );
}

export function QuestionEditPage(props) {
  return createQuizSubPage(
    `${i18next.t('quiz.documentTitles.editQuestions')} - `,
    'questionsForm',
    QuestionForm.QuestionForm,
    false,
    props
  );
}

export function CategoryPage() {
  return createQuizSubPage(
    `${i18next.t('quiz.documentTitles.categories')} - `,
    'category',
    CategoryList
  );
}

export function QuestionTypePage() {
  return createQuizSubPage(
    `${i18next.t('quiz.documentTitles.questionTypes')} - `,
    'questionTypes',
    TypeList
  );
}

export function InformationSheetsPage() {
  return createQuizSubPage(
    `${i18next.t('quiz.documentTitles.informationSheets')} - `,
    'informationSheets',
    InformationSheets.InformationSheetList
  );
}

function createQuizSubPage(
  titleString,
  sectionClassName,
  Component,
  withTabs = true,
  props
) {
  document.title = titleString + Constants.DOCUMENT_TITLE_BACKOFFICE;

  return (
    <HtmlStructure menuName="quiz" sectionClassName={sectionClassName}>
      {withTabs && <QuizTabs />}
      <Component {...props} />
    </HtmlStructure>
  );
}
