import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import './services/i18n';

import './web/style/index.css';
import './web/style/colors.css';

/* ============================== Main Code ============================== */

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

/* ============================== Other Functions ============================== */
