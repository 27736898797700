import { useEffect, useState } from 'react';
import { InputField } from '../general/form/Input';
import { Api, Session } from 'scg.common-library';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Helper from '../../services/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '../general/form/Loading';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import './societyForm.css';

function SocietyForm({
  showAdvancedOption = false,
  validationButtonLabel = 'Créer la société',
  currentSociety = null,
  abortingCallback = null,
  successCallback = null
}) {
  const { t } = useTranslation();

  const [loadingData, setLoadingData] = useState(false);
  const [vars, setVarsElement] = useState({
    sSocietyName: '',
    nMaxAccounts: '',
    dSubscriptionExpirationDate: '', //new Date().toISOString().substring(0,10),
    bSubscribed: false,
    dSubscriptionDate: '',
    bAdvancedSetting: false,
    nTimeBetweenQuiz: 1440, //temps d'attente entre chaque quiz
    nTimeBetweenChallenge: 1440, //temps d'attente entre chaque challenge
    nTimeLimitQuestion: 60, //temps limite pour les réponses
    nOrthographicIndulgency: 0.9, //Indulgence orthographe
    nMinLevelNegativePoints: 500, //seuil points négatifs
    bShowAdvanced: showAdvancedOption,
    bRecordLoaded: false
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  /*load data when it is about modifying selected entry*/
  useEffect(() => {
    async function loadingSociety() {
      setLoadingData(true);
      const response = await Api.society.getSociety(
        currentSociety.id,
        Session.getJwtToken()
      );
      if (response.status === 200) {
        // field stats values
        setVars({
          sSocietyName: response.data.name,
          nMaxAccounts: response.data.maxAccounts,
          dSubscriptionDate: response.data.subscriptionDate?.substring(0, 10),
          dSubscriptionExpirationDate:
            response.data.subscriptionExpirationDate?.substring(0, 10),
          bSubscribed: response.data.subscribed,
          nTimeBetweenQuiz: response.data.parameter.timeBetweenQuiz, //temps d'attente entre chaque quiz
          nTimeBetweenChallenge: response.data.parameter.timeBetweenChallenge, //temps d'attente entre chaque challenge
          nTimeLimitQuestion: response.data.parameter.timeLimitQuestion, //temps limite pour les réponses
          nOrthographicIndulgency: response.data.parameter.spellingIndulgence, //Indulgence orthographe
          nMinLevelNegativePoints:
            response.data.parameter.minLevelNegativePoints, //seuil points négatifs
          bRecordLoaded: true
        });

        //registration values
        setValue('nom', response.data.name);
        setValue('number', response.data.maxAccounts);
        setValue(
          'registration',
          response.data.subscriptionDate?.substring(0, 10)
        );
        setValue(
          'expiry',
          response.data.subscriptionExpirationDate?.substring(0, 10)
        );
        setLoadingData(false);
      }
    }

    if (currentSociety?.id > 0 && !vars.bRecordLoaded) loadingSociety();
  });

  return (
    <form
      onSubmit={handleSubmit(() => {
        saveChange();
      })}
    >
      {loadingData && <Loading />}
      <div className="sf-layout">
        <div className="sf-one-row spaced">
          <InputField
            title={t('societies.addModal.labels.name')}
            isFocused={true}
            type="text"
            className="w60 input-tiny"
            inputStyle="classic"
            required={true}
            onChange={e => setVars({ sSocietyName: e.target.value })}
            register={register('nom', {
              required: true,
              maxLength: 200,
              defaultValues: vars.sSocietyName
            })}
            value={vars.sSocietyName}
          >
            {t('societies.addModal.labels.name')}
          </InputField>

          <div className="sf-checkbox">
            <InputField
              title={t('societies.addModal.labels.subscriber')}
              type="checkbox"
              disabled={loadingData}
              className="input-tiny"
              inputStyle="classic"
              onChange={(e) => {
                setVars({ bSubscribed: e.target.checked });
              }}
              checked={vars.bSubscribed}
            >
              {t('societies.addModal.labels.subscriber')}
            </InputField>
          </div>
        </div>
        {errors?.nom?.type === 'required' && (
          <p className="errorMessage sf-input-error">
            {t('societies.addModal.errors.name')}
          </p>
        )}

        <InputField
          name="number"
          title={t('societies.addModal.labels.accountsNumber')}
          type="number"
          inputStyle="classic"
          className="input-tiny"
          isFocused={true}
          value={vars.nMaxAccounts}
          required={true}
          onChange={(e) => {
            setVars({ nMaxAccounts: e.target.value });
          }}
          register={register('number', {
            required: true,
          })}
        >
          {t('societies.addModal.labels.accountsNumber')}
        </InputField>
        
        {errors?.number?.type === 'required' && (
          <p className="errorMessage sf-input-error">
            {t('societies.addModal.errors.accountsNumber')}
          </p>
        )}
        <div className="sf-grid-2">
          <div>
            <InputField
              title={t('societies.addModal.labels.subscriptionDate')}
              type="date"
              inputStyle="classic"
              className="input-tiny"
              isFocused={true}
              value={vars.dSubscriptionDate}
              required={vars.bSubscribed}
              onChange={e => setVars({ dSubscriptionDate: e.target.value })}
              register={register('registration', {
                required: vars.bSubscribed,
                maxLength: 200
              })}
            >
              {t('societies.addModal.labels.subscriptionDate')}
            </InputField>
            {errors?.registration?.type === 'required' && vars.bSubscribed && (
              <p className="errorMessage sf-input-error">
                {t('societies.addModal.errors.subscriptionDate')}
              </p>
            )}
          </div>
          <div>
            <InputField
              title={t('societies.addModal.labels.subscriptionExpirationDate')}
              type="date"
              value={vars.dSubscriptionExpirationDate}
              required={vars.bSubscribed}
              inputStyle="classic"
              className="input-tiny"
              isFocused={true}
              onChange={(e) => {
                setVars({ dSubscriptionExpirationDate: e.target.value });
              }}
              register={register('expiry', {
                required: vars.bSubscribed,
                maxLength: 200
              })}
            >
              {t('societies.addModal.labels.subscriptionExpirationDate')}
            </InputField>
            {errors?.expiry?.type === 'required' && vars.bSubscribed && (
              <p className="errorMessage sf-input-error">
                {t('societies.addModal.errors.subscriptionDate')}
              </p>
            )}
          </div>
        </div>
      </div>
      {errors?.expiry?.type === 'required' && vars.bSubscribed && (
        <p className="errorMessage sf-input-error">
          {t('societies.addModal.errors.subscriptionDate')}
        </p>
      )}

      <div
        className="sf-layout"
        style={{ display: vars.bShowAdvanced ? 'flex' : 'none' }}
      >
        <span className="sf-title">
          <FontAwesomeIcon icon="fa-solid fa-gear" /> Paramètre avancés -{' '}
          {vars.sSocietyName}
        </span>
        <InputField
          title={t('societies.addModal.labels.waitingTimeQuiz')}
          type="number"
          inputStyle="classic"
          className="input-tiny"
          isFocused={true}
          onChange={(e) => {
            setVars({ nTimeBetweenQuiz: e.target.value });
          }}
          value={vars.nTimeBetweenQuiz}
        >
          {t('societies.addModal.labels.waitingTimeQuiz')}
        </InputField>

        <InputField
          title={t('societies.addModal.labels.waitingTimeChallenge')}
          type="number"
          inputStyle="classic"
          className="input-tiny"
          isFocused={true}
          onChange={(e) => {
            setVars({ nTimeBetweenChallenge: e.target.value });
          }}
          value={vars.nTimeBetweenChallenge}
        >
          {t('societies.addModal.labels.waitingTimeChallenge')}
        </InputField>
        <div className="sf-grid-2">
          <InputField
            title={t('societies.addModal.labels.responsesTimeLimit')}
            type="number"
            inputStyle="classic"
            className="input-tiny"
            isFocused={true}
            onChange={(e) => {
              setVars({ nTimeLimitQuestion: e.target.value });
            }}
            value={vars.nTimeLimitQuestion}
          >
            {t('societies.addModal.labels.responsesTimeLimit')}
          </InputField>
          <InputField
            title={t('societies.addModal.labels.spellingIndulgence')}
            min="0"
            max="1"
            step="0.01"
            type="number"
            inputStyle="classic"
            className="input-tiny"
            isFocused={true}
            onChange={(e) => {
              setVars({ nOrthographicIndulgency: e.target.value });
            }}
            value={vars.nOrthographicIndulgency}
          >
            {t('societies.addModal.labels.spellingIndulgence')}
          </InputField>
        </div>
        <InputField
          title={t('societies.addModal.labels.thresholdNegativePoints')}
          type="number"
          inputStyle="classic"
          className="input-tiny"
          isFocused={true}
          onChange={(e) => {
            setVars({ nMinLevelNegativePoints: e.target.value });
          }}
          value={vars.nMinLevelNegativePoints}
        >
          {t('societies.addModal.labels.thresholdNegativePoints')}
        </InputField>

      </div>

      <div className="sf-one-row spaced">
        <input
          type="button"
          className="btn default closeButton"
          onClick={() => setDiplayedToAdvanced(!vars.bShowAdvanced)}
          value={t('societies.addModal.buttons.showHideSettings')}
          disabled={loadingData}
        />
        <div className="">
          <input
            type="button"
            className="btn default closeButton"
            value={t('societies.addModal.buttons.cancel')}
            onClick={() => abort()}
          />
          <input
            type="submit"
            className="btn default closeButton"
            value={validationButtonLabel}
            onSubmit={(e) => {
              e.preventDefault();
            }}
            disabled={loadingData}
          />
        </div>
      </div>
    </form>
  );

  function setDiplayedToAdvanced(value) {
    var newVars = Object.assign({}, vars);
    newVars.bShowAdvanced = value;
    setVarsElement(newVars);
  }

  function setVars(changedObjectElement) {
    let save = Object.assign({}, vars);
    let newVars = Object.assign(save, changedObjectElement);
    setVarsElement(newVars);
  }

  function saveChange() {
    let id = currentSociety?.id ?? 0;
    if (id > 0) {
      //save modif
      const doUpdate = async () => {
        setLoadingData(true);
        const response = await Api.society.updateSociety(
          id ?? 0,
          {
            id: id,
            name: vars.sSocietyName,
            maxAccounts: parseInt(vars.nMaxAccounts),
            subscriptionExpirationDate: vars.dSubscriptionExpirationDate,
            subscriptionDate: vars.dSubscriptionDate,
            subscribed: vars.bSubscribed,
            parameter: {
              minLevelNegativePoints: parseInt(vars.nMinLevelNegativePoints),
              spellingIndulgence: parseFloat(vars.nOrthographicIndulgency),
              timeBetweenQuiz: parseInt(vars.nTimeBetweenQuiz),
              timeBetweenChallenge: parseInt(vars.nTimeBetweenChallenge),
              timeLimitQuestion: parseInt(vars.nTimeLimitQuestion)
            }
          },
          Session.getJwtToken()
        );

        setLoadingData(false);

        if (response.status === 200) {
          successCallback();
          abort();
        } else if (response?.violations[0]?.message === 'doublon') {
          toast.error(
            `${i18next.t('societies.addModal.errors.duplicateName.before')} ${vars.sSocietyName
            } ${i18next.t('societies.addModal.errors.duplicateName.after')}`,
            Helper.getToastOptions()
          );
        } else {
          toast.error(
            i18next.t('societies.addModal.errors.update'),
            Helper.getToastOptions()
          );
        }
      };
      doUpdate();
    } else {
      //save new entry
      const doInsert = async () => {
        setLoadingData(true);
        const response = await Api.society.createSociety(
          vars.sSocietyName ?? 'Uknown',
          parseInt(vars.nMaxAccounts) ?? 0,
          Session.getJwtToken() ?? 'Uknown',
          vars.bSubscribed ?? false,
          vars.dSubscriptionDate || null,
          vars.dSubscriptionExpirationDate || null,
          true,
          {
            minLevelNegativePoints: parseInt(vars.nMinLevelNegativePoints),
            spellingIndulgence: parseFloat(vars.nOrthographicIndulgency),
            timeBetweenQuiz: parseInt(vars.nTimeBetweenQuiz),
            timeBetweenChallenge: parseInt(vars.nTimeBetweenChallenge),
            timeLimitQuestion: parseInt(vars.nTimeLimitQuestion)
          }
        );
        setLoadingData(false);

        if (response.status === 201) {
          successCallback();
          abort();
        } else if (response?.violations[0]?.message === 'doublon') {
          toast.error(
            `${i18next.t('societies.addModal.errors.duplicateName.before')} ${vars.sSocietyName
            } ${i18next.t('societies.addModal.errors.duplicateName.after')}`,
            Helper.getToastOptions()
          );
        } else {
          toast.error(
            i18next.t('societies.addModal.errors.create'),
            Helper.getToastOptions()
          );
        }
      };
      doInsert();
    }
  }

  function abort() {
    abortingCallback();
  }
}

export default SocietyForm;
