import {Component} from 'react'
import {Api, Session} from 'scg.common-library'
import {InputField} from '../../general/form/Input'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Helper from '../../../services/Helper'
import {TableSkeleton} from '../../../services/LoadingHelper'
import _ from 'lodash'
import {withTranslation} from 'react-i18next'

import './generalParameters.css'

const GeneralParameters = withTranslation()(
    class extends Component {
        static defaultProps = {}

        constructor(props) {
            super(props)

            this.state = {
                isLoading: true,
                hasChanged: false,
                parameters: null,
                timeBetweenQuiz: 0,
                timeBetweenChallenge: 0,
                timeLimitQuestion: 0,
                spellingIndulgence: 0.0,
                minLevelNegativePoints: 0,
                parameterId: 0,
                displayTooltip: false
            }
            this.levels = []
            this.handleSave = this.handleSave.bind(this)
            this.handleChange = this.handleChange.bind(this)
            this.getLevelsTooltip = this.getLevelsTooltip.bind(this)
        }

        componentDidMount() {
            (async function() {
                let response,
                    parameterId = 0 // 0 = default parameters

                // get all levels details for tooltip
                response = await Api.level.getLevels()
                const levels = Helper.isValidResponse(response)
                if (levels) {
                    this.levels = levels
                }

                // get parameters values
                if (Helper.isUserSuperAdmin()) {
                    response = await Api.parameter.getGeneralParameter()
                } else {
                    // Get parameters Id from society object attached to the current user
                    parameterId = parseInt(
                        _.split(Session.getSessionUser()?.society?.parameter, '/').pop()
                    )
                    response = await Api.parameter.getParameter(parameterId)
                }
                if (response?.status !== 200) {
                    Helper.displayMessage(`${this.props.t('dashboard.generalSettings.errorLoading')}: ${response['hydra:description']}`,'error')
                    this.setState({isLoading: false})
                } else {
                    const parameters = response.data
                    this.setState({
                        parameters: parameters,
                        parameterId: parameterId,
                        timeBetweenQuiz: parameters?.timeBetweenQuiz,
                        timeBetweenChallenge: parameters?.timeBetweenChallenge,
                        timeLimitQuestion: parameters?.timeLimitQuestion,
                        spellingIndulgence:
                            parameters?.spellingIndulgence !== undefined
                                ? (parameters?.spellingIndulgence * 100).toFixed(2)
                                : undefined,
                        minLevelNegativePoints: parameters?.minLevelNegativePoints,
                        isLoading: false
                    })
                }
            }).bind(this)()
        }

        handleSave() {
            (async function() {
                const params = {
                    timeBetweenQuiz: parseInt(this.state.timeBetweenQuiz),
                    timeBetweenChallenge: parseInt(this.state.timeBetweenChallenge),
                    timeLimitQuestion: parseInt(this.state.timeLimitQuestion),
                    spellingIndulgence: parseFloat(
                        (this.state.spellingIndulgence / 100).toFixed(4)
                    ),
                    minLevelNegativePoints: parseInt(this.state.minLevelNegativePoints)
                }
                if (
                    params.timeBetweenQuiz < 0 ||
                    params.timeBetweenChallenge < 0 ||
                    params.timeLimitQuestion < 0 ||
                    params.spellingIndulgence < 0 ||
                    params.spellingIndulgence > 1 ||
                    params.minLevelNegativePoints < 0
                ) {
                    Helper.displayMessage(this.props.t('dashboard.generalSettings.warning'), 'warning')
                    return
                }

                const response = await Api.parameter.updateParameter(
                    this.state.parameterId,
                    params
                )
                if (response?.status !== 200) {
                    Helper.displayMessage(this.props.t('dashboard.generalSettings.errorValues'), 'error')
                } else {
                    Helper.displayMessage(this.props.t('dashboard.generalSettings.success'))
                    this.setState({hasChanged: false})
                }
            }).bind(this)()
        }

        handleChange(e) {
            this.setState({
                [e.target.name]: e.target.value,
                hasChanged: true
            })
        }

        getLevelsTooltip() {
            let levelTooltip = []
            _.map(this.levels, (level, i) => {
                levelTooltip.push(
                    <span key={i}>
            {level.minExperience} {'>'} {level.name} {'>'} {level.maxExperience}
          </span>
                )
            })
            return levelTooltip
        }

        render() {
            const {t} = this.props

            return (
                <article className='parameters'>
                    <h3>{t('dashboard.generalSettings.title')}</h3>
                    <section className='parameters-list card'>
                        {this.state.isLoading ? <TableSkeleton linesCount={7} size="large" />
                            : <div className='parameters-item'>
                                <InputField
                                    type='number'
                                    name='timeBetweenQuiz'
                                    value={this.state.timeBetweenQuiz}
                                    title={t('dashboard.generalSettings.deadlineQuizItem.title')}
                                    context={this}
                                    isFocused={true}
                                    className='parameter-minuts input-tiny'
                                    inputStyle='classic'
                                    onChange={this.handleChange}
                                >
                                    {t('dashboard.generalSettings.deadlineQuizItem.name')}
                                </InputField>
                                <InputField
                                    type='number'
                                    name='timeBetweenChallenge'
                                    value={this.state.timeBetweenChallenge}
                                    title={t(
                                        'dashboard.generalSettings.deadlineChallengeItem.title'
                                    )}
                                    context={this}
                                    isFocused={true}
                                    className='parameter-minuts input-tiny'
                                    inputStyle='classic'
                                    onChange={this.handleChange}
                                >
                                    {t('dashboard.generalSettings.deadlineChallengeItem.name')}
                                </InputField>
                                <InputField
                                    type='number'
                                    name='timeLimitQuestion'
                                    value={this.state.timeLimitQuestion}
                                    title={t('dashboard.generalSettings.limitQuestionItem.title')}
                                    context={this}
                                    isFocused={true}
                                    className='parameter-seconds input-tiny'
                                    inputStyle='classic'
                                    onChange={this.handleChange}
                                >
                                    {t('dashboard.generalSettings.limitQuestionItem.name')}
                                </InputField>
                                <InputField
                                    type='number'
                                    name='spellingIndulgence'
                                    value={this.state.spellingIndulgence}
                                    title={t('dashboard.generalSettings.indulgenceItem.title')}
                                    context={this}
                                    isFocused={true}
                                    className='parameter-percent input-tiny'
                                    inputStyle='classic'
                                    onChange={this.handleChange}
                                >
                                    {t('dashboard.generalSettings.indulgenceItem.name')}
                                </InputField>
                                <InputField
                                    type='number'
                                    name='minLevelNegativePoints'
                                    value={this.state.minLevelNegativePoints}
                                    title={t('dashboard.generalSettings.thresholdItem.title')}
                                    context={this}
                                    isFocused={true}
                                    className='parameter-points input-tiny'
                                    inputStyle='classic'
                                    onChange={this.handleChange}
                                >
                                    {t('dashboard.generalSettings.thresholdItem.name')}
                                </InputField>
                                <div className='level-tooltip'>
                                    <div
                                        className='tooltip-icon'
                                        title={t('dashboard.generalSettings.detailsTitle')}
                                        onMouseEnter={() => {
                                            this.setState({displayTooltip: true})
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({displayTooltip: false})
                                        }}
                                    >
                                        <FontAwesomeIcon icon='fas fa-circle-info' />
                                    </div>
                                    <div
                                        className={`tooltip-content${
                                            this.state.displayTooltip ? '__visible' : ''
                                        }`}
                                    >
                                        {this.getLevelsTooltip()}
                                    </div>
                                </div>
                                {this.state.hasChanged && (
                                    <button
                                        type='button'
                                        className='btn success save-button'
                                        title={t('dashboard.generalSettings.saveButton')}
                                        onClick={this.handleSave}
                                    >
                                        <FontAwesomeIcon icon='fas fa-save' />
                                    </button>
                                )}
                            </div>}
                    </section>
                </article>
            )
        }
    }
)

export default GeneralParameters
